import React, { Component } from 'react'
//import './Home.scss';
import './MyProfile.scss';
import styles from './ContentListPage.scss';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'

import axios from './myAxios';

//import EducationalContent from './EducationalContent.js'
import Banner from './Banner';
import user_activity from './UserAction.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope, faPhone, faCalendarAlt } from '@fortawesome/free-solid-svg-icons'

import { UncontrolledDropdown, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Container, Row, Col, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import CountryInput from './RegistrationInput/CountryInput';

import Toggle from 'react-toggle';
import "react-toggle/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class ContentSubmission extends Component {
  state = {
    countries: {},
    countriesOptions: {
        required: "1",
        min: "1",
        max: "100",
        size: "12"
    },
    has_error_first_name: false,
    has_error_last_name: false,
    has_error_email: false,
    has_error_institution: false,
    has_error_material: false,
    has_error_message: false,
    hasError: false,
    first_name: {
        value: this.props.user.first_name,
        visible: this.props.user.first_name_visible
    },
    last_name: {
        value: this.props.user.last_name,
        visible: this.props.user.last_name_visible
    },
    email: {
        value: this.props.user.email,
        visible: this.props.user.email_visible
    },
    institution: {
        value: this.props.user.institution,
        visible: this.props.user.institution_visible
    },
    message: {
        value: this.props.user.message,
        visible: this.props.user.message_visible
    },
    material: {
        value: this.props.user.material,
        visible: this.props.user.material_visible
    },
    country: {
        value: {value: this.props.user.country_id, label: this.props.user.country_name},
        visible: this.props.user.country_visible
    },
    photo: {
        value: this.props.user.photo,
        visible: this.props.user.photo_visible
    },
    start_date: null,
    end_date: null,
    formControls: {
        first_name: {
            rules: {
                required: true,
            },
            id: "title",
            name: "Title",
            value: '',
            type: "text",
            placeholder: '',
            feedback: '',
            validity: true
        }
    },
    successVisible: false,
    pleaseWait: false
  }

  formatDate(date){
      if(!date){
          return "";
      }
      return [date.getFullYear(), date.getMonth()+1, date.getDate()].join("-");
  }

  Submit(){
      let hasError = false;
      this.setState({
            successVisible: false, 
            pleaseWait: true,
            hasError: false,
            has_error_first_name: false,
            has_error_last_name: false,
            has_error_email: false,
            has_error_institution: false,
            has_error_material: false,
            has_error_message: false,
        });

        if(!this.state.first_name.value || this.state.first_name.value == ""){
            hasError = true;
            this.setState({has_error_first_name: true});
        }
        if(!this.state.last_name.value || this.state.last_name.value == ""){
            hasError = true;
            this.setState({has_error_last_name: true});
        }
        if(!this.state.email.value || this.state.email.value == ""){
            hasError = true;
            this.setState({has_error_email: true});
        }
        if(!this.state.institution.value || this.state.institution.value == ""){
            hasError = true;
            this.setState({has_error_institution: true});
        }
        if(!this.state.material.value || !this.state.material.value.name || this.state.material.value.name == ""){
            hasError = true;
            this.setState({has_error_material: true});
        }
        if(!this.state.message.value || this.state.message.value == ""){
            hasError = true;
            this.setState({has_error_message: true});
        }

        if(hasError){
            this.setState({successVisible: false, pleaseWait: false, hasError: true});
            return;
        }
      let formData = new FormData();
      let data = {};
      data = {};
      data["First Name"] = this.state.first_name.value;
      data["Last Name"] = this.state.last_name.value;
      data["Email Address"] = this.state.email.value;
      data["Institution/Organisation"] = this.state.institution.value;
      data["Message/Comments"] = this.state.message.value;
      data["Valid Start Date"] = this.formatDate(this.state.start_date);
      data["Valid End Date"] = this.formatDate(this.state.end_date);

      formData.append("info", JSON.stringify(data));
      formData.append("id", this.props.user.user_id);
      formData.append("material", this.state.material.value);

      user_activity.Save("Content Submission", "Submitted form");

      axios.post("/admin/api/submitContentSubmission.php", formData)
      .then((response)=> {
        this.setState({successVisible: true, pleaseWait: false, hasError: false});
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  componentDidMount() {
    user_activity.Save("Content Submission", "Visited");
    axios.get(`/admin/api/getRegistrationFields.php`)
      .then(res => {
        this.setState({
          countries: res.data["countries"]
        });
      });
  }

  render() {
    return (
        <div className="" style={{backgroundColor: "white", minHeight: "calc(100vh - 98px)"}}>
            <Banner/>
            <div className='title primary-color' style={{fontSize: "22px", fontWeight: "bold", marginLeft:"50px", marginTop: "20px"}}>
                Content Submission
            </div>
            <div className='tertiary-color' style={{margin:"20px 50px"}}>
                <div style={{marginBottom: "40px", lineHeight: "1rem"}}>
                    If you or your organisation would like to share content related to your anti-stigma initiatives kindly submit your request using the form provided below. Submissions will be reviewed by the Anti-Stigma Portal Secretariat and a member of the team will respond to you shortly.
                </div>
            </div>
            <div className='title primary-color' style={{textAlign: "center", fontSize: "20px", fontWeight: "bold", marginLeft:"50px", marginRight: "50px", marginTop: "20px", marginBottom: "30px"}}>
                Request Content Submission Form
            </div>
            <div className='tertiary-color' style={{margin:"20px 50px"}}>
                <Form>
                    <Container style={{maxWidth: "800px"}}>
                        <Row>
                            <Col sm="4">
                                <FormGroup>
                                    <Label for="first_name">First Name*</Label>
                                    <Input style={{borderColor: this.state.has_error_first_name?"red":""}} value={this.state.first_name.value} onChange={(e) => {this.setState({first_name: {value: e.target.value, visible: this.state.first_name.visible} })}} type="text" name="first_name" id="first_name" placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col sm="4">
                                <FormGroup>
                                    <Label for="last_name">Last Name*</Label>
                                    <Input style={{borderColor: this.state.has_error_last_name?"red":""}} value={this.state.last_name.value} onChange={(e) => {this.setState({last_name: {value: e.target.value, visible: this.state.last_name.visible} })}} type="text" name="last_name" id="last_name" placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col sm="4">
                                <FormGroup>
                                    <Label for="last_name">Email Address*</Label>
                                    <Input style={{borderColor: this.state.has_error_email?"red":""}} value={this.state.email.value} onChange={(e) => {this.setState({email: {value: e.target.value, visible: this.state.email.visible} })}} type="text" name="last_name" id="last_name" placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="4">
                                <FormGroup>
                                    <Label for="institution">Institution/Organisation*</Label>
                                    <Input style={{borderColor: this.state.has_error_institution?"red":""}} value={this.state.institution.value} onChange={(e) => {this.setState({institution: {value: e.target.value, visible: this.state.institution.visible} })}} type="text" name="institution" id="institution" placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col sm="8">
                                <FormGroup>
                                    <Label for="material">Material*</Label>
                                    <Input autoComplete={"off"} value={this.state.material?.value?.name ?? ""} style={{cursor: "pointer", borderColor: this.state.has_error_material?"red":""}} onClick={() => document.getElementById("materialInput").click()} type="text" name="material" id="material" placeholder="" />
                                    <div style={{fontWeight: "bold", pointerEvents: "none", position: "absolute", right: "15px", top: "32px", backgroundColor: "#616161", padding: "7px 14px", color: "white", borderRadius: "0px 0.25rem 0.25rem 0px"}}>
                                        Upload
                                    </div>
                                    <Input id="materialInput" style={{display: "none"}} type="file" onChange={(e) => {this.setState({material: {value: e.target.files[0], visible: this.state.material.visible} }); console.log(e.target.files[0]) }} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <FormGroup>
                                    <Label for="message">Message/Comments*</Label>
                                    <Input value={this.state.message.value} style={{resize: "none", borderColor: this.state.has_error_message?"red":""}} onChange={(e) => {this.setState({message: {value: e.target.value, visible: this.state.message.visible} })}} type="textarea" name="message" id="message" placeholder="" rows="7"/>
                                    <div style={{marginTop: "10px"}} className="primary-color">
                                        <small>
                                            Please <u>do not</u> include any of your own personal health data or the personal data of other individuals in the Message/Comments field.
                                        </small>
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6">
                                <FormGroup>
                                    <Label for="start_date">Valid Start Date</Label>
                                    <DatePicker
                                        id="start_date"
                                        selected={this.state.start_date}
                                        onChange={date => this.setState({start_date: date})}
                                        dateFormat="dd/MM/yyyy"
                                        // isClearable
                                        placeholderText={""}
                                        style={{width: "100%"}}
                                        className="form-control"
                                    />
                                    <div style={{pointerEvents: "none", position: "absolute", right: "15px", top: "32px", backgroundColor: "#616161", padding: "7px 14px", color: "white", borderRadius: "0px 0.25rem 0.25rem 0px"}}>
                                        <FontAwesomeIcon icon={faCalendarAlt}/>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col sm="6">
                                <FormGroup>
                                    <Label for="end_date">Valid End Date</Label>
                                    <DatePicker
                                        id="end_date"
                                        selected={this.state.end_date}
                                        onChange={date => this.setState({end_date: date})}
                                        dateFormat="dd/MM/yyyy"
                                        // isClearable
                                        placeholderText={""}
                                        style={{width: "100%"}}
                                        className="form-control"
                                    />
                                    <div style={{pointerEvents: "none", position: "absolute", right: "15px", top: "32px", backgroundColor: "#616161", padding: "7px 14px", color: "white", borderRadius: "0px 0.25rem 0.25rem 0px"}}>
                                        <FontAwesomeIcon icon={faCalendarAlt}/>
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12" style={{textAlign: "center", marginTop: "30px", marginBottom: "50px"}}>
                                <div onClick={() => this.Submit()} style={{fontSize: "24px", padding: "5px 60px", backgroundColor: "#e04f40", color: "white", borderRadius: "5px", display: this.state.pleaseWait?"none":"inline-block", fontWeight: "bold", cursor: "pointer", userSelect: "none"}}>
                                    Submit
                                </div>
                                <p style={{textAlign: 'center', fontSize: "24px", display: this.state.pleaseWait?"block":"none"}}>
                                    Please wait...
                                </p>
                                <p style={{textAlign: 'center', color: 'red', fontSize: "16px", display: this.state.hasError?"block":"none"}}>
                                    Please fill in all required fields
                                </p>
                                <p style={{textAlign: 'center', color: 'green', display: this.state.successVisible?"block":"none"}}>
                                    Content successfully submitted!
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </Form>
            </div>
            <div style={{display: "flex", flexFlow: "column"}}>
                
            </div>
        </div>
    )
  }
}

export default ContentSubmission