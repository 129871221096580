import React, { Component } from 'react'
import './Home.scss';
import styles from './PrivacyPolicy.scss';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'

import axios from 'axios';

//import EducationalContent from './EducationalContent.js'
import Banner from './Banner';
import user_activity from './UserAction.js';

class CookiesPolicy extends Component {
  state = {
    
  }

  componentDidMount() {
    
  }

  render() {
    return (
        <div className="list" style={{backgroundColor: "white"}}>
            <Banner/>
            <div className='title primary-color' style={{fontSize: "22px", fontWeight: "bold", marginLeft:"50px", marginTop: "20px"}}>
                Cookie Statement
            </div>
            <div className='tertiary-color' style={{padding: "10px 50px 20px 50px"}}>
              <p>
                When you visit any website, it may store or retrieve information on your
                browser, mostly in the form of cookies. Cookies are small text files that are
                transferred to your computer's hard disk by a website. This information
                might be about you, your preferences or your device and is typically used
                to make the site work as you expect it. The information does not usually
                directly identify you, but it can give you a more personalized web
                experience.
              </p>
              <p>
                Because we respect your right to privacy (<a href="https://www.gilead.com/privacy" target="_blank">www.gilead.com/privacy</a>), you
                can choose not to allow some types of cookies. Please click on the different
                category headings below to find out more about the categories and options
                to change your default settings. However, note that blocking some types of
                cookies may impact your experience with the site and the services we are
                able to offer.), you can choose not to allow some types of cookies. Please
                click on the different category headings below to find out more about the
                categories and options to change your default settings.
              </p>
            </div>

            <div className='title primary-color' style={{fontSize: "20px", fontWeight: "bold", marginLeft:"50px"}}>
              Strictly Necessary
            </div>
            <div className='tertiary-color' style={{padding: "10px 50px 20px 50px"}}>
              <p>
                These cookies are necessary for the website to function and cannot be
                switched off in our systems. They are usually only set in response to
                actions made by you which amount to a request for services, such as
                setting your privacy preferences, logging in or filling in forms. You can set
                your browser to block or alert you about these cookies, but if you do so,
                some parts of the site will not work. These cookies do not store any
                personal information.
              </p>
            </div>

            <div className='title primary-color' style={{fontSize: "20px", fontWeight: "bold", marginLeft:"50px"}}>
              Performance Cookies
            </div>
            <div className='tertiary-color' style={{padding: "10px 50px 20px 50px"}}>
              <p>
                These cookies allow us to count visits and traffic sources so we can
                measure and improve the performance of our site. They help us to know
                which pages are the most and least popular and see how visitors move
                around the site. All information these cookies collect is aggregated and
                therefore anonymous. If you do not allow these cookies we will not know
                when you have visited our site, and will not be able to monitor its
                performance.
              </p>
            </div>

            <div className='title primary-color' style={{fontSize: "20px", fontWeight: "bold", marginLeft:"50px"}}>
              Functional Cookies
            </div>
            <div className='tertiary-color' style={{padding: "10px 50px 20px 50px"}}>
              <p>
                These cookies enable the website to provide enhanced functionality and
                personalization. They may be set by us or by third party providers whose
                services we have added to our pages. If you do not allow these cookies,
                some or all of these services may not function properly.
              </p>
            </div>
            
            <div className='title primary-color' style={{fontSize: "20px", fontWeight: "bold", marginLeft:"50px"}}>
              Targeting Cookies
            </div>
            <div className='tertiary-color' style={{padding: "10px 50px 20px 50px"}}>
              <p>
                These cookies may be set through our site by our advertising partners.
                They may be used by those companies to build a profile of your interests
                and show you relevant ads on other sites. They do not store directly
                personal information, but are based on uniquely identifying your browser
                and internet device. If you do not allow these cookies, you will experience
                less targeted advertising.
              </p>
            </div>

            <div className='title primary-color' style={{fontSize: "20px", fontWeight: "bold", marginLeft:"50px"}}>
              Social Media Cookies
            </div>
            <div className='tertiary-color' style={{padding: "10px 50px 50px 50px"}}>
              <p>
                These cookies are set by a range of social media services that we may
                have added to the site to enable you to share our content with your friends
                and social or professional networks. They are capable of tracking your
                browser across other sites and building up a profile of your interests. This
                may impact the content and messages you see on other websites you visit.
                If you do not allow these cookies, you may not be able to use or see these
                sharing tools.
              </p>
            </div>
        </div>
    )
  }
}

export default CookiesPolicy