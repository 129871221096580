import React, { Component } from 'react'
import './HomeHeader.scss';

import logo from "./images/exceed.svg";

import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'

import LoginModal from './Modals/LoginModal.js';
import HamburgerMenu from 'react-hamburger-menu'
import axios from './myAxios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

class HomeHeader extends Component {
  state = {
    isLoggedIn: false,
    modalLoginVisible: false,
    hamburgerOpen: false,
    gotLoggedIn: false,
    menuVisible: false
  }

  componentDidMount() {
    axios.get(`/admin/api/isLoggedIn.php`)
      .then(async(res) => { 
        this.setState({isLoggedIn: res.data==1, gotLoggedIn: true});
        if(this.props.login == "login"){
          this.Login();
        }
      });
  }

  handleHamburgerClick() {
    if(!this.state.hamburgerOpen){
      this.setState({menuVisible: true});
    }
    else{
      setTimeout(() => {
        this.setState({menuVisible: false});
      },510);
    }
    setTimeout(() => {
      this.setState({
          hamburgerOpen: !this.state.hamburgerOpen
      });
    },10);
  }

  Login(){
    if(this.state.isLoggedIn){
      window.location.href = "/content";
    }
    else{
      this.setState({ modalLoginVisible: true });
    }
  }

  render() {
    return (
      <div>
        <LoginModal onClose={() => this.setState({ modalLoginVisible: false })} visible={this.state.modalLoginVisible}/>
        <div className="mobile-menu">
          <div style={{position: "absolute", right: "25px", top: "25px", zIndex: 2, cursor: "pointer"}}>
            <HamburgerMenu
              isOpen={this.state.hamburgerOpen}
              menuClicked={this.handleHamburgerClick.bind(this)}
              width={28}
              height={14}
              strokeWidth={3}
              rotate={0}
              color='#000000'
              borderRadius={0}
              animationDuration={0.5}
            />
          </div>
          <div style={{position: "absolute", right: "0px", top: "0px", zIndex: 1}}>
            <div className={"hamburgerMenu border-left border-bottom border-1 border-primary"} style={{display: (this.state.menuVisible?"block":"none"), right: this.state.hamburgerOpen?"0px":"-220px"}}>
              <div style={{fontWeight: "bold"}}>
                <a onClick={() => this.Login()} className={"primary-color"} style={{textDecoration: "none", cursor: 'pointer', fontWeight: 'bold'}}>EDUCATIONAL CONTENT</a>
              </div>
              <div style={{fontWeight: "bold", marginTop: "20px", display: (this.state.isLoggedIn || !this.state.gotLoggedIn)?"none":"block"}}>
                <a onClick={() => this.Login()} className={"primary-color"} style={{textDecoration: "none", cursor: 'pointer', fontWeight: 'bold'}}>LOGIN</a>
              </div>
            </div>
          </div>
        </div>
        <div className="navbar my-navbar title-margin">
          <div className="container-fluid" style={{display: 'flex', msFlexWrap: 'wrap', flexWrap: 'wrap', msFlexAlign: 'center', alignItems: 'center', msFlexPack: 'justify', justifyContent: 'space-between'}}>
            <div className="navbar-header">
              <Link to={"/home"}>
                <div className="navbar-brand" style={{height: '180px'}}>
                  <img src={logo} style={{height: '100%', width: 'auto', paddingLeft: '1px'}} />
                </div>
              </Link>
            </div>
            <ul className="nav navbar-nav navbar-height" style={{}}>
              <li>
                <a onClick={() => this.Login()} style={{cursor: "pointer", textDecoration: "none", fontWeight: 'bold'}}>EDUCATIONAL CONTENT</a>
              </li>
              {/*<li><Link to={"register"}>REGISTRATION</Link></li>*/}
              <li style={{position: "relative", display: (this.state.isLoggedIn || !this.state.gotLoggedIn)?"none":"block"}}>
                <div style={{position: "absolute", top: "62px", left: "0px", fontSize: "13px", whiteSpace: "nowrap"}}>Already Registered?</div>
                <a onClick={() => this.Login()} className={"background-primary border-all border-1 border-primary"} style={{textDecoration: "none", color: 'white', cursor: 'pointer', borderRadius: '5px', padding: '10px 30px', fontWeight: 'bold'}}>LOGIN</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default HomeHeader
