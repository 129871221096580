import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import UserAction from './UserAction.js';
import Popup from './Popup.js';
import './Login.scss';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  withRouter
} from "react-router-dom";

import axios from './myAxios';
import EmailInput from './LoginInput/EmailInput.js';
import Banner from './Banner.js';

import user_activity from './UserAction.js';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.handleEmailChange = this.handleEmailChange.bind(this);
  }

  state = {
    email: "",
    successVisible: false,
    errorVisible: false,
    isOK: false
  }

  componentDidMount() {
    this.setState({isOK: false});
    user_activity.Save("Reset Password", "Visited");
  }

  handleEmailChange(value) {
    this.setState({email: value});
  }

  handlePasswordChange(value) {
    this.setState({password: value});
  }

  Submit(){
    this.setState({successVisible: false, errorVisible: false});
    let values = {};
    values["email"] = this.state.email;
    axios.post(`/admin/api/forgotPassword.php`, values)
      .then(res => {
        console.log(res);
        if(res.data == "1"){
          this.setState({successVisible: true, isOK: true});
          UserAction.Save("Forgot Password", "Clicked");
        }
        else{
          this.setState({errorVisible: true});
        }
      });
  }

  handleOnErrorChange = (value) => {
    this.setState({hasError: value});
  }

  render() {
    return (
        <div className="background">
          <div className="background" style={{position: "fixed", zIndex: -1, left: "0px", top: "0px", right: "0px", bottom: "0px"}}></div>
        <Popup onClose={() => this.setState({ modalVisible: false })} visible={this.state.modalVisible} title={this.state.modalTitle} text={this.state.modalText}/>
        
        <Banner/>

        <div className="my-container" style={{marginTop: "50px"}}>
          <div className="box" style={{backgroundColor: 'white', width: '300px', padding: '25px 0px'}}>
            <div className="row tertiary-color">
              <div className="row col-md-10 col-xl-9">
                <div className="row" style={{display: 'inline-grid'}}>
                    <div style={{fontSize: '20px', marginBottom: '10px', textAlign: 'center', color: '#E86651', fontFamily: 'Planer', fontWeight: 'bold'}}>
                    Recover Password
                    </div>
                    <p style={{marginTop: '0px', width: '-webkit-fill-available'}}>
                    <EmailInput onChange={this.handleEmailChange} onErrorChange={this.handleOnErrorChange} />
                    </p>
                    <p style={{textAlign: 'center', color: 'red', display: this.state.errorVisible?"block":"none"}}>
                    The email you entered is not found. <br/>
                    Try again.
                    </p>
                    <p style={{textAlign: 'center', color: 'green', display: this.state.successVisible?"block":"none"}}>
                    Password sent to your email
                    </p>
                </div>
              </div>
            </div>

            <div className="row">
                <div style={{display: this.state.isOK?"none":"block", fontSize: '20px', textAlign: 'center', fontFamily: 'Planer', fontWeight: 'bold', margin: "10px 0px"}}>
                    <Link to={"/login"} style={{textDecoration: 'none', marginRight: "15px", cursor: 'pointer', display: 'inline-block', color: 'white', backgroundColor: '#E86651', borderRadius: '5px', width: '100px', padding: '4px 14px'}}>
                    Cancel
                    </Link>
                    <div className="primary-font" onClick={() => this.Submit()} style={{cursor: 'pointer', display: 'inline-block', color: 'white', backgroundColor: '#E86651', borderRadius: '5px', width: '100px', padding: '4px 14px'}}>
                    Submit
                    </div>
                </div>
                <div style={{display: this.state.isOK?"block":"none", fontSize: '20px', textAlign: 'center', fontFamily: 'Planer', fontWeight: 'bold', marginBottom: "10px"}}>
                    <Link to={"/login"} className="primary-font" style={{textDecoration: 'none', marginRight: "15px", cursor: 'pointer', display: 'inline-block', color: 'white', backgroundColor: '#E86651', borderRadius: '5px', width: '100px', padding: '7px 14px'}}>
                    OK
                    </Link>
                </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ForgotPassword