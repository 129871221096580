import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import UserAction from './UserAction.js';
import Popup from './Popup.js';
import './Login.scss';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  withRouter
} from "react-router-dom";

import axios from './myAxios';

import EmailInput from './LoginInput/EmailInput.js';
import PasswordInput from './LoginInput/PasswordInput.js';
import Banner from './Banner.js';

import user_activity from './UserAction.js';

class Login extends Component {
  constructor(props) {
    super(props);

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  }

  state = {
    email: "",
    password: "",
    errorVisible: false,
    verifyErrorVisible: false,
    successVisible: false,
    modalForgotVisible: false,
    modalForgotTitle: "",
    modalForgotText: "",
    hasError: false
  }

  componentDidMount() {
    user_activity.Save("Login", "Visited");
  }

  handleEmailChange(value) {
    this.setState({email: value});
  }

  handlePasswordChange(value) {
    this.setState({password: value});
  }

  Login() {
    this.setState({successVisible: false, errorVisible: false, verifyErrorVisible: false});
    let values = {};
    values["email"] = this.state.email;
    values["password"] = this.state.password;
    UserAction.Save("Login", "Clicked");
    axios.post(`/admin/api/login.php`, values)
      .then(res => {
        if(res.data && res.data.logged_in == "1"){  
          this.setState({successVisible: true});
          setTimeout(() => {
            this.props.SetUser(res.data);
          },500);
          //window.location.href = "/contentlist/all";
          //history.push("/contentlist/all");
          console.log("the user has logged in");
        }
        else{
          if(res.data == "-1")
            this.setState({verifyErrorVisible: true});
          else
            this.setState({errorVisible: true});
        }
        /*console.log(res);
        if(res.data == "1"){
          
        }
        else{
          
        }*/
      });
  }

  handleOnErrorChange = (value) => {
    this.setState({hasError: value});
  }

  render() {
    return (
        <div className="background">
          <div className="background" style={{position: "fixed", zIndex: -1, left: "0px", top: "0px", right: "0px", bottom: "0px"}}></div>
        <Popup onClose={() => this.setState({ modalVisible: false })} visible={this.state.modalVisible} title={this.state.modalTitle} text={this.state.modalText}/>
        
        <Banner/>

        <div className="my-container">
          <div className="box" style={{backgroundColor: 'white', width: 'fit-content', padding: '25px 0px'}}>
            <div className="row tertiary-color">
              <div className="row col-md-10 col-xl-9" style={{flex: "0 0 80%"}}>
                <div className="row" style={{display: 'inline-grid'}}>
                    <p style={{marginTop: '0px', display:'inline-block'}}>
                    <EmailInput onChange={this.handleEmailChange} onErrorChange={this.handleOnErrorChange} />
                    </p>
                    <p style={{marginTop: '0px', display:'inline-block', marginBottom: '0px'}}>
                    <PasswordInput onChange={this.handlePasswordChange}/>
                    <p style={{textAlign: 'center', color: 'red', display: this.state.errorVisible?"block":"none"}}>
                      Wrong Credentials
                    </p>
                    <p style={{textAlign: 'center', color: 'red', display: this.state.verifyErrorVisible?"block":"none"}}>
                      Please verify your email
                    </p>
                    <p style={{textAlign: 'center', color: 'green', display: this.state.successVisible?"block":"none"}}>
                      Logged in successfully
                    </p>
                    <div className="pswdFooter">
                        <label style={{ display:'inline-block'}}>
                            <input type="checkbox" />
                            <span style={{paddingLeft: '5px', fontSize: '14px'}}> Remember me </span>
                        </label>
                        <Link to={"/forgotPassword"} className={"primary-color"} style={{marginLeft: '55px', fontSize: '14px', textDecoration: "none", cursor: "pointer", display: 'inline-block'}}>Forgot Password?</Link>
                    </div>
                    </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="row col-sm-12">
                  <div onClick={() => this.Login()} className={"background-primary border-all border-1 border-primary"} style={{color: 'white', marginTop: '5px', cursor: 'pointer', width: '190px', borderRadius: '4px', padding: '4px 70px', fontFamily: 'Planer', fontWeight: 'bold', fontSize: '20px'}}>
                    Login
                  </div>
              </div>
              <div className="tertiary-color" style={{fontSize: '14px', textAlign: 'center', margin: '15px 0px', width: "300px", maxWidth: "90%"}}>
                By clicking this button, you submit your information to the Anti-Stigma Portal website
              </div>
              <Link to={"/register"} className="row col-sm-12" style={{textDecoration: 'none'}}>
                <div style={{color: '#243680', width: '116px', fontFamily: 'Planer', fontSize: '16px', display:'inline-block'}}>
                  Not Registered?
                </div>
                <div className="" style={{color: '#E86651', cursor: 'pointer', borderRadius: '10px', fontFamily: 'Planer', fontSize: '16px', display:'inline-block'}}>
                    Register Here
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Login)