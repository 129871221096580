
import React, { useState } from 'react';
import { Label, Input, FormGroup, Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import styles from './MyInput.module.scss';


const MyImg = ( props ) => {
    console.log(props)

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    return (

        <>
        {
            props.formElem.prefix ? 
                <Row className="m-0 mb-3 align-items-center">
                    <Col xs={{size:"auto", offset: 0}} sm={{size:"auto", offset: 4}}  md={{size:"auto", offset: 3}}  lg={{size:"auto", offset: 2}} className="small tertiary-color p-0">
                        {props.formElem.prefix}
                    </Col>
                </Row>
            : null
        }

        <FormGroup row className="m-0 mb-4 align-items-center">
            <Col xs="12" sm="4" md="3" lg="2" className={styles["label"] + " secondary-color"}>
                {props.formElem.name}
            </Col>
            
            <Col xs="12" sm="auto" className="p-0 pl-1 d-flex align-items-center">
                <Label className={styles["input-file"] + " m-0 pl-5 pr-5 d-flex align-items-center justify-content-center tertiary-color " + (props.formElem.validity ? "border-secondary-color" : styles["input-invalid"])}>
                    Upload file
                    <input type="file" hidden onChange={props.onChangeHandler}/>
                </Label>
            </Col>
            {/* { 
                props.validity === "is-invalid" ? 
                <Col sm={props.feedbackWidth} className="pl-4 font-weight-bold text-danger font-italic">
                    {props.feedback}
                </Col> :
                null
            } */}
            { 
                props.formElem.value ? 
                <>
                    <Col xs="12" sm="auto" className="pl-4">
                        <img src={URL.createObjectURL(props.formElem.value)} id={styles["img-preview"]} className="border-secondary-color" onClick={toggle}/>
                    </Col> 

                    <Modal isOpen={modal} toggle={toggle} size="xl" centered>
                        {/* <ModalHeader toggle={toggle}></ModalHeader> */}
                        <ModalBody>
                            <img src={URL.createObjectURL(props.formElem.value)} id={styles["img-modal-preview"]}/>
                        </ModalBody>
                    </Modal>
                </>
                : null
            }
        </FormGroup>
        </>
    );

};

// const arePropsEqual = (prevProps, nextProps) =>  prevProps.formElem.value === nextProps.formElem.value; 
// export default React.memo(MyImg, arePropsEqual);
export default React.memo(MyImg);
