import React, { Component } from 'react'
import './Popup.scss';
import { findDOMNode } from 'react-dom'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

class ConfirmPopup extends Component {
  state = {

  }

  componentDidMount() {

  }

  render() {
    return (
      <div id="my-modal" className="my-modal" style={{pointerEvents: this.props.visible?"all":"none", fontSize: "16px"}}>
        <div id="my-modal-background" style={{transition: 'all 0.3s', zIndex: 14, position: 'fixed', width: '100vw', height: '100vh', left: 0, top: 0, backgroundColor: this.props.visible?"#0000005e":"#00000000"}}>
        </div>
        <div id="my-modal-parent" style={{opacity: this.props.visible?1:0, transition: 'all 0.3s', zIndex: 15, position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%,-50%)', borderRadius: '15px', border: '1px solid '+this.props.color??'#243680', backgroundColor: "white", maxWidth: "90%", width: "400px", textAlign: "center"}}>
          <div style={{borderRadius: '15px 15px 0px 0px', fontSize: '26px', padding: '15px', textAlign: 'center', backgroundColor: this.props.color??'#243680', color: 'white', fontFamily: 'Planer', fontWeight: 'bold'}}>
            { this.props.title }
          </div>
          <div id="my-modal-body" className="my-modal-body" style={{padding: "25px 25px 10px 25px"}}>
            <div dangerouslySetInnerHTML={{__html: this.props.text }}className="tertiary-color" style={{marginBottom: "30px", marginTop: "5px"}}>

            </div>
            <div style={{fontSize: '20px', textAlign: 'center', fontFamily: 'Planer', fontWeight: 'bold'}}>
              <div className="primary-font" onClick={this.props.onClose} style={{cursor: 'pointer', display: 'inline-block', color: 'white', backgroundColor: '#243680', borderRadius: '5px', width: '90px', padding: '7px 14px'}}>
                Cancel
              </div>
              <div className="primary-font" onClick={() =>  {window.open(this.props.url, "_blank"); this.props.onClose()}} style={{marginLeft: "20px", cursor: 'pointer', display: 'inline-block', color: 'white', backgroundColor: '#243680', borderRadius: '5px', width: '90px', padding: '7px 14px'}}>
                OK
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ConfirmPopup
