import React, { Component } from 'react'
import './ContentInfo.scss';
import { findDOMNode } from 'react-dom'

import { Container, Row, Col, Collapse, CardBody, Card } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import SpeakerBio from './SpeakerBio.js'
import EvaluationForm from './EvaluationForm.js'

import axios from './myAxios';
import user_activity from './UserAction';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShare, faSave, faHeart, faHeartBroken, faHeartbeat } from '@fortawesome/free-solid-svg-icons'

import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Share from './Share';

class ContentInfo extends Component {
  state = {
    data: {},
    isOpen: false,
    isFavorite: false
  }

  toggleCollapse(){
    this.setState({ isOpen: !this.state.isOpen });
  }

  renderPresenters(){
    let result = [];
    (this.state.data["creator_full"] || []).forEach((item, i) => {
      result.push(<SpeakerBio creator={item} />);
    });
    return result;
  }

  formatDate(dateString){
    if(!dateString){
      return "";
    }
    let dateArr = dateString.split("-");
    let date = new Date(dateArr[0], dateArr[1]-1, dateArr[2]);
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear();
  }

  renderInteractiveFeatures(){
    let result = [];
    (this.state.data["interactive_features"] || []).forEach(feature => {
      if(feature.type_id == 1){ //Ask Question
        result.push(<a href={"mailto:"+feature.data} style={{textDecoration: "none"}}>
                      <div className={"background-secondary"} style={{marginTop: "10px", padding: "15px", color: "white", fontWeight: "bold", borderRadius: "5px", textAlign: "center", fontSize: "16px"}}>
                        {feature.button_text}
                      </div>
                    </a>);
      }
      else if(feature.type_id == 2){ //Download Content
        result.push(<a href={"/admin/api/downloadContentMaterial.php?id=" + feature.data} style={{textDecoration: "none"}}><div className={"background-secondary"} style={{marginTop: "10px", padding: "15px", color: "white", fontWeight: "bold", borderRadius: "5px", textAlign: "center", fontSize: "16px"}}>
                        {feature.button_text}
                      </div></a>);
      }
      else if(feature.type_id == 3){ //Evaluation Form
        // result.push(<a href={feature.data} target="_blank" style={{textDecoration: "none"}}>
        //               <div className={"background-secondary"} style={{marginTop: "10px", padding: "15px", color: "white", fontWeight: "bold", borderRadius: "5px", textAlign: "center", fontSize: "16px"}}>
        //                 {feature.button_text}
        //               </div>
        //             </a>);
        result.push(<EvaluationForm playedSeconds={this.props.playedSeconds} activate_at={feature.activate_at} buttonText={feature.button_text} contentId={this.props.id} data={feature.data} style={{textDecoration: "none"}}/>);
      }
    })
    return result;
  }

  componentDidMount() {
    axios.get(`/admin/api/getContentDetails.php`, { params: { id: this.props.id, user_id: this.props.user.user_id } })
      .then(res => {
        console.log(res.data);
        this.setState({data: res.data});
        if(res.data.isFavorite){
          this.setState({isFavorite: true});
        }
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id) {
      axios.get(`/admin/api/getContentDetails.php`, { params: { id: this.props.id, user_id: this.props.user.user_id } })
      .then(res => {
        console.log(res.data);
        this.setState({data: res.data});
        if(res.data.isFavorite){
          this.setState({isFavorite: true});
        }
      });
    }
  }

  renderDate(d){
    if(!d)
      return "";
    let ymd = d.split(" ")[0].split("-");
    let date = new Date(ymd[0], ymd[1]-1, ymd[2]);
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return months[date.getMonth()] + " " + date.getDate() + ", " +date.getFullYear();
  }

  Download(){
    user_activity.Save("Content Detail", "Download", this.props.id);
  
    axios.get(`/admin/api/downloadContentMaterial.php?id=${this.state.data["download_content"]}`, { responseType: 'blob' })
      .then(response => {
        //const u = this.state.data["material_name"].split("/");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        // link.setAttribute('download', u.slice(u.length-1)); //or any other extension
        link.setAttribute('download', response.headers["file-name"]); //or any other extension
        document.body.appendChild(link);
        link.click();
      });

    // axios.get(`/admin/api/getPDFDownload.php`, { params: { url: this.state.data["content_material_url"], name: this.state.data["content_material_original_name"] }, responseType: 'blob' })
    //   .then(response => {
    //     const u = this.state.data["content_material_url"].split("/");
    //     const url = window.URL.createObjectURL(new Blob([response.data]));
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download', u.slice(u.length-1)); //or any other extension
    //     document.body.appendChild(link);
    //     link.click();
    //   });
  }

  ToggleFavorites(){
    let isFavorite = this.state.isFavorite;
    this.setState({isFavorite: !isFavorite});

    let formData = new FormData();

    formData.append("type", isFavorite?"remove":"add");
    formData.append("user_id", this.props.user.user_id);
    formData.append("content_id", this.props.id);
    
    user_activity.Save("Content Detail", isFavorite?"Remove from Favorites":"Add to Favorites", this.props.id);

    axios.post("/admin/api/addRemoveFavorites.php", formData)
    .then((response)=> {

    })
    .catch(function (error) {

    });
  }

  render() {
    let creator = "";
    if(this.state.data["creator_full"] || [].length > 0){
      creator = this.state.data["creator_full"][0].first_name + " " + this.state.data["creator_full"][0].last_name; 
    }
    return (
      <div style={{width: "100%", fontSize: "14px"}}>
        <div className="contentinfo">
          <Container fluid={true}>
            <Row>
              <Col>
                <div className={"primary-color"} style={{fontSize: "26px", fontWeight: "bold", marginTop: "5px", marginBottom: "5px"}}>
                  { this.state.data["content_title"] }
                </div>
                <div className={"secondary-color"} style={{marginBottom: "5px"}}>
                  { creator }
                </div>
                <div className={"tertiary-color"} style={{marginBottom: "5px"}}>
                  { this.state.data["views"] } views • { this.renderDate(this.state.data["creation_date"]) }
                </div>
                <div className={"tertiary-color"} style={{marginTop: "10px", marginBottom: "-5px"}}>
                  { this.state.data["content_description"] }
                </div>
              </Col>
              <Col md="auto">
                <div className={"dateAndForm"} style={{borderRadius: "5px"}}>
                  <div className={"tertiary-color"}>
                    <span style={{marginRight: "30px"}}>
                      <Share 
                        title={this.state.data["content_title"]}
                        onOpen={() => {
                          user_activity.Save("Content Detail", "Share", this.props.id);
                        }}
                      />
                    </span>
                    <span style={{cursor: "pointer"}}>
                      <UncontrolledDropdown style={{display: "inline-block"}}>
                        <DropdownToggle tag="span">
                          •••
                        </DropdownToggle>
                        <DropdownMenu right>
                          { this.state.data["download"] == 1 &&
                            <DropdownItem onClick={() => this.Download()}><FontAwesomeIcon icon={faSave} /> Download</DropdownItem> 
                          }
                          <DropdownItem onClick={() => this.ToggleFavorites()}>
                            <FontAwesomeIcon icon={this.state.isFavorite?faHeart:faHeartBroken} /> {this.state.isFavorite?"Remove from Favorites":"Add to Favorites"}
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </span>
                  </div>
                </div>

                <div className="interactive_features">

                </div>
  
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    )
  }
}

export default ContentInfo
