import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_ENV==='production'?'https://anti-stigma.com':'https://stage.synapse.mdataws.com',
    headers: {'Content-Type': 'application/json'}
});

// instance.defaults.headers.common['token'] = null;

// instance.interceptors.request.use(function (config) {
//     const token = localStorage.getItem('token');
//     if (token) {
//         config.headers.Authorization = `JWT ${token}`;

//     }
//     return config;
// });

export default instance;