import React, { Component } from 'react'
import styles from './SpecialtiesTabs.module.scss';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

import { Localize } from './Localization';
import user_activity from './UserAction';

class SpecialtiesTabs extends Component {
  state = {
    specialties: ["all_specialties", "cardiology", "dermatology", "diabetology", "endocrinology", "hematology", "oncology", "pathology", "pediatric", "psychiatry", "surgery", "radiology"]
  }

  componentDidMount() {
    
  }

  SelectedTab(tab, name){
    this.props.SetTab(tab);
    user_activity.Save("Clicked on", name);
  }

  render() {
    return (
      <div className={styles.list}>
        {
          this.state.specialties.map((specialty, idx) => {
            return (
              <div className={"dark-blue-color " + styles.item + " " + (this.props.tab===specialty?styles.selected:"")} onClick={() => this.SelectedTab(specialty, Localize(this.props.locale, specialty))}>
                  { Localize(this.props.locale, specialty) }
              </div>
            );
          })
        }
      </div>
    )
  }
}

export default SpecialtiesTabs
