const localization = {
    "slide_deck": {
        "en": "SLIDE DECKS",
        "gr": "Επόμενα Live"
    },
    "infographics": {
        "en": "INFOGRAPHICS",
        "gr": "Επόμενα Live"
    },
    "poster": {
        "en": "POSTERS",
        "gr": "ΕΠΟΜΕΝΑ LIVE"
    },
    "all": {
        "en": "ALL",
        "gr": "Live Σήμερα"
    },
    "video": {
        "en": "CASE STUDIES",
        "gr": "On Demand"
    },
    "reports": {
        "en": "MEETING REPORTS",
        "gr": "Διαθέσιμα On Demand"
    },
    "today": {
        "en": "Today",
        "gr": "Σήμερα"
    },
    "today_cap": {
        "en": "TODAY",
        "gr": "ΣΗΜΕΡΑ"
    },
    "months": {
        "en": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        "gr": ["Ιανουαρίος", "Φεβρουάριος", "Μάρτιος", "Απρίλιος", "Μάιος", "Ιούνιος", "Ιούλιος", "Αύγουστος", "Σεπτέμβριος", "Οκτώβριος", "Νοέμβριος", "Δεκέμβριος"]
    },
    "days": {
        "en": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        "gr": ["Κυριακή", "Δευτέρα", "Τρίτη", "Τετάρτη", "Πέμπτη", "Παρασκευή", "Σάββατο"]
    },
    "hours": {
        "en": "hours",
        "gr": "ώρες"
    },
    "minutes": {
        "en": "minutes",
        "gr": "λεπτά"
    },
    "hour": {
        "en": "hour",
        "gr": "ώρα"
    },
    "minute": {
        "en": "minute",
        "gr": "λεπτό"
    },
    "join_live": {
        "en": "JOIN LIVE",
        "gr": "ΜΠΕΣ ΣΤΟ LIVE"
    },
    "get_access": {
        "en": "GET ACCESS",
        "gr": "ΑΠΟΚΤΗΣΕ ΠΡΟΣΒΑΣΗ"
    },
    "started": {
        "en": "Started",
        "gr": "Ξεκίνησε πριν"
    },
    "ended": {
        "en": "Ended",
        "gr": "Τελείωσε πριν"
    },
    "starting_in": {
        "en": "Created: ",
        "gr": "Ξεκινάει σε"
    },
    "size": {
        "en": "Size",
        "gr": " "
    },
    "duration": {
        "en": "Duration",
        "gr": "Διάρκεια"
    },
    "views": {
        "en": "views",
        "gr": "προβολές"
    },
    "view": {
        "en": "view",
        "gr": "προβολή"
    },
    "viewers": {
        "en": "viewers",
        "gr": "θεατές"
    },
    "viewer": {
        "en": "viewer",
        "gr": "θεατής"
    },
    "login": {
        "en": "Login",
        "gr": "Σύνδεση"
    },
    "new_user": {
        "en": "New User?",
        "gr": "Νέος Χρήστης;"
    },
    "sign_up": {
        "en": "Sign Up",
        "gr": "Εγγραφή"
    },
    "user_exists": {
        "en": "There is already a user with the same email",
        "gr": "Υπάρχει ήδη ένας χρήστης με το ίδιο email"
    },
    "wrong_credentials": {
        "en": "Wrong credentials. Try again.",
        "gr": "Λάθος στοιχεία σύνδεσης. Δοκιμάστε ξανά."
    },
    "passwords_do_not_match": {
        "en": "Passwords do not match",
        "gr": "Οι Κωδικοί δεν ταιριάζουν"
    },
    "please_fill_in": {
        "en": "Please fill in all the required fields",
        "gr": "Παρακαλούμε εισάγετε όλα τα υποχρεωτικά πεδία"
    },
    "warning": {
        "en": "Warning",
        "gr": "Προσοχή"
    },
    "registration_was_successful": {
        "en": "Registration was successful!",
        "gr": "Η Εγγραφή ήταν επιτυχής!"
    },
    "share": {
        "en": "Share",
        "gr": "Κοινοποίηση"
    },
    "forgot_password": {
        "en": "Forgot Password?",
        "gr": "Ξεχάσατε τον Κωδικό σας;"
    },
    "reset_password": {
        "en": "Reset Password",
        "gr": "Επαναφορά Κωδικού"
    },
    "first_name": {
        "en": "First Name",
        "gr": "Όνομα"
    },
    "last_name": {
        "en": "Last Name",
        "gr": "Επώνυμο"
    },
    "country": {
        "en": "Country",
        "gr": "Χώρα"
    },
    "password": {
        "en": "Password",
        "gr": "Κωδικός"
    },
    "verify_password": {
        "en": "Reset Password",
        "gr": "Επαλήθευση Κωδικού"
    },
    "email": {
        "en": "Email",
        "gr": "Email"
    },
    "type_in_what": {
        "en": "Type in what you are looking for...",
        "gr": "Πληκτρολογήστε τι ψάχνετε..."
    },
    "search": {
        "en": "Search",
        "gr": "Αναζήτηση"
    },
    "search_cap": {
        "en": "SEARCH",
        "gr": "ΑΝΑΖΗΤΗΣΗ"
    },
    "no_events_found": {
        "en": "0 Results Found",
        "gr": "Δεν Βρέθηκαν Αποτελέσματα"
    },
    "update_profile": {
        "en": "Update Profile",
        "gr": "Ενημέρωση Προφίλ"
    },
    "my_preferences": {
        "en": "My Preferences",
        "gr": "Οι Προτιμήσεις μου"
    },
    "logout": {
        "en": "Logout",
        "gr": "Αποσύνδεση"
    },
    "access_code": {
        "en": "Access Code",
        "gr": "Access Code"
    },
    "wrong_access_code": {
        "en": "Access code is incorrect. Please try again.",
        "gr": "Ο Access Code είναι λανθασμένος. Παρακαλώ δοκιμάστε ξανά."
    },
    "all_specialties": {
        "en": "ALL",
        "gr": "Όλες"
    },
    "cardiology": {
        "en": "VIDEO",
        "gr": "Καρδιολογία"
    },
    "dermatology": {
        "en": "INFOGRAPHICS",
        "gr": "Δερματολογία"
    },
    "diabetology": {
        "en": "CASE STUDY",
        "gr": "Διαβητολογία"
    },
    "endocrinology": {
        "en": "POSTER",
        "gr": "Ενδοκρινολογία"
    },
    "hematology": {
        "en": "Hematology",
        "gr": "Αιματολογία"
    },
    "oncology": {
        "en": "Oncology",
        "gr": "Ογκολογία"
    },
    "pathology": {
        "en": "Pathology",
        "gr": "Παθολογία"
    },
    "pediatric": {
        "en": "Pediatrics",
        "gr": "Παιδιατρική"
    },
    "psychiatry": {
        "en": "Psychiatry",
        "gr": "Ψυχιατρική"
    },
    "surgery": {
        "en": "Surgery",
        "gr": "Χειρουργική"
    },
    "radiology": {
        "en": "Radiology",
        "gr": "Ακτινολογία"
    },
    "events": {
        "en": "Events",
        "gr": "Εκδηλώσεις"
    },
    "about_mconferences": {
        "en": "About mConferences",
        "gr": "Σχετικά με το mConferences"
    },
    "faq": {
        "en": "FAQ",
        "gr": "Συχνές Ερωτήσεις"
    },
    "contact_us": {
        "en": "Contact Us",
        "gr": "Επικοινωνία"
    },
    "by": {
        "en": "Sort by",
        "gr": "ανά"
    }
};

export function Localize(locale, id) {
    /*if(!localization[id]){
        return "No translation";
    }*/
    return localization[id]["en"] || "No translation";
}