import React, { useState, useEffect } from 'react';

import logo from './logo.svg';
import './App.css';
import ReactPlayer from 'react-player'
import VerifyEmailPage from './VerifyEmailPage.js'
import ContentPage from './ContentPage.js'
import Header from './Header.js'
import Footer from './Footer.js'
import Home from './Home.js'
import Registration from './Registration.js'
import PollResults from './PollResults.js'
import EducationalContentPage from './EducationalContentPage.js'
import CookiesPolicy from './CookiesPolicy.js'
import ScrollToTop from './ScrollToTop.js'
import ForgotPassword from './ForgotPassword.js'
import ContentListPage from './ContentListPage'
import AdminPanel from './admin--/components/AdminPanel/AdminPanel'
import ContentForm from './admin--/components/AdminPanel/Content/ContentForm/ContentForm'
import ContentTable from './admin--/components/AdminPanel/Content/ContentTable/ContentTable'

import axios from './myAxios';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
  useLocation
} from "react-router-dom";
import Login from './Login';
import LandingPage from './LandingPage';
import ContactUs from './ContactUs'
import About from './About'
import PrivacyPolicy from './PrivacyPolicy';
import Terms from './Terms';
import Organizations from './Organizations';
import Members from './Members';
import IsLoggedIn from './IsLoggedIn';
import MyProfile from './MyProfile';
import ContentSubmission from './ContentSubmission';
import RecentlyVisitedPage from './RecentlyVisitedPage';
import FavoritesPage from './FavoritesPage';
import Popup from './Popup';

let redirect = "/contentlist/all";

function App() {
  const [styling, setStyling] = useState([]);
  const [user, setUser] = useState(null);
  const [status, setStatus] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  // const location = useLocation();

  // useEffect(() => {
  //   // track pageview with gtag / react-ga / react-ga4, for example:
  //   window.gtag("event", "page_view", {
  //     page_path: location.pathname + location.search,
  //   });
  // }, [location]);

  useEffect(()=>{
    axios.get(`/admin/api/getStyling.php`)
      .then(res => {
        setStyling(res.data);
        console.log(res.data);
      });
    axios.get(`/admin/api/isLoggedIn.php`)
      .then(res => {
        if(res.data.logged_in == "1"){
          redirect = window.location.pathname;
          setUser(res.data);
        }
        else{
          redirect = window.location.pathname;
          //setRed(window.location.pathname);
        }
        setStatus(true);
      });
  }, []);

  if(!status){
    return <></>;
  }

  return (
    <div>
      <style>
        {
          "\
            body{\
            }\
            .background{\
              background: "+styling.background+"\
            }\
            .primary-font{\
              font-family:"+styling.primaryFont+";\
            }\
            .secondary-font{\
              font-family:"+styling.secondaryFont+";\
            }\
            .primary-color{\
              color:"+styling.primaryColor+"!important;\
            }\
            .secondary-color{\
              color:"+styling.secondaryColor+"!important;\
            }\
            .tertiary-color{\
              color:"+styling.tertiaryColor+"!important;\
            }\
            .background-primary{\
              background-color:"+styling.primaryColor+"!important;\
            }\
            .background-secondary{\
              background-color:"+styling.secondaryColor+"!important;\
            }\
            \
            .border-all{\
              border-style: solid!important\
            }\
            .border-top{\
              border-top-style: solid!important\
            }\
            .border-right{\
              border-right-style: solid!important\
            }\
            .border-bottom{\
              border-bottom-style: solid!important\
            }\
            .border-left{\
              border-left-style: solid!important\
            }\
            .border-1{\
              border-width: 1px!important;\
            }\
            .border-2{\
              border-width: 2px!important;\
            }\
            .border-3{\
              border-width: 3px!important;\
            }\
            .border-4{\
              border-width: 4px!important;\
            }\
            .border-5{\
              border-width: 5px!important;\
            }\
            \
            .border-primary{\
              border-color: "+styling.primaryColor+"!important;\
            }\
            .border-secondary{\
              border-color: "+styling.secondaryColor+"!important;\
            }\
            \
            .my-navbar .navbar-nav > li > a{\
              color:"+styling.primaryColor+";\
            }\
            #menu > li > a{\
              color: "+styling.primaryColor+";\
            }\
          "
        }
      </style>
      <div className="primary-font primary-color">
        <Router>
          {/*<IsLoggedIn SetUser={(user) => setUser(user)} />*/}
          <ScrollToTop />
          <Switch>
            <Route path="/content/:id" render={(innerProps) => {
              if(!user || !user.logged_in){
                return <Redirect to="/login" />
              }
              return <>
                <Header user={user} SetUser={(user) => setUser(user)}/>
                <ContentPage user={user} {...innerProps} />
                <Footer code="E12-UNB-0339"/>
              </>;
            }}/>

            <Route path="/results">
              <Header user={user} SetUser={(user) => setUser(user)}/>
              <PollResults/>
            </Route>


            <Route exact path={`/contentlist/:type`} render={(innerProps) => {
              if(!user || !user.logged_in){
                return <Redirect to="/login" />
              }
                let type = "all"; 
                if(innerProps.match.params.type === "video"){
                  type = "video";
                }
                else if(innerProps.match.params.type === "infographics"){
                  type = "infographics";
                }
                else if(innerProps.match.params.type === "slide_deck"){
                  type = "slide_deck";
                }
                else if(innerProps.match.params.type === "poster"){
                  type = "poster";
                }
                else if(innerProps.match.params.type === "reports"){
                  type = "reports";
                }
                return (
                  <>
                    <Header user={user} SetUser={(user) => setUser(user)}/>
                    <ContentListPage type={type} user={user}/>
                    <Footer code="E12-UNB-0339"/>
                  </>
                );
              }
            }/>

            <Route exact path={`/recentlyvisited/:type`} render={(innerProps) => {
              if(!user || !user.logged_in){
                return <Redirect to="/login" />
              }
                let type = "all"; 
                if(innerProps.match.params.type === "video"){
                  type = "video";
                }
                else if(innerProps.match.params.type === "infographics"){
                  type = "infographics";
                }
                else if(innerProps.match.params.type === "slide_deck"){
                  type = "slide_deck";
                }
                else if(innerProps.match.params.type === "poster"){
                  type = "poster";
                }
                else if(innerProps.match.params.type === "reports"){
                  type = "reports";
                }
                return (
                  <>
                    <Header user={user} SetUser={(user) => setUser(user)}/>
                    <RecentlyVisitedPage type={type} user={user}/>
                    <Footer code="E12-UNB-0339"/>
                  </>
                );
              }
            }/>

            <Route exact path={`/favorites/:type`} render={(innerProps) => {
              if(!user || !user.logged_in){
                return <Redirect to="/login" />
              }
                let type = "all"; 
                if(innerProps.match.params.type === "video"){
                  type = "video";
                }
                else if(innerProps.match.params.type === "infographics"){
                  type = "infographics";
                }
                else if(innerProps.match.params.type === "slide_deck"){
                  type = "slide_deck";
                }
                else if(innerProps.match.params.type === "poster"){
                  type = "poster";
                }
                else if(innerProps.match.params.type === "reports"){
                  type = "reports";
                }
                return (
                  <>
                    <Header user={user} SetUser={(user) => setUser(user)}/>
                    <FavoritesPage type={type} user={user}/>
                    <Footer code="E12-UNB-0339"/>
                  </>
                );
              }
            }/>

            <Route path="/cookies" render={(innerProps) => {
                return <>
                  <Header user={user} SetUser={(user) => setUser(user)}/>
                  <CookiesPolicy {...innerProps}/>
                  <Footer code="E12-UNB-0339"/>
                </>;
              }}
            />

            <Route path="/register">
              <Registration user={user}/>
              <Footer code="E12-UNB-0339"/>
            </Route>

            <Redirect from="/unsubscribe/:id" to="/home/:id" />

            <Route path="/login" render={(innerProps) => {
              if(user && user.logged_in){
                console.log("LOGGED IN");
                console.log(redirect);
                if(redirect == "/login" || redirect == "/register"){
                  redirect = "/contentlist/all";
                }
                let tmpRedirect = redirect;
                redirect = "/contentlist/all"; //resetting redirect
                return <Redirect to={tmpRedirect} />
              }
              return <>
                <Login SetUser={(user) => setUser(user)}/>
                <Footer code="E12-UNB-0339"/>
              </>;
            }}/>

            <Route path="/home/:id?" render={(innerProps) => {
              if(innerProps.match.params.id){
                const token = innerProps.match.params.id;
                setModalVisible(true);

                let formData = new FormData();
                formData.append("token", token);
                axios.post(`/admin/api/unsubscribe.php`, formData)
                .then(res => {

                });
              }
              else{
                if(user && user.logged_in){
                  return <>
                    <Redirect to="/contentlist/all" />
                  </>;
                }
              }
              return <>
                <Popup onClose={() => window.location.href="/home"} visible={!!innerProps.match.params.id} title={"Success"} text={"You have successfully unsubscribed!"}/>
                <LandingPage/>
                <Footer code="E12-UNB-0339"/>
              </>;
            }}/>

            <Route path="/contact">
              <Header user={user} SetUser={(user) => setUser(user)}/>
              <ContactUs/>
              <Footer code="E12-UNB-0339"/>
            </Route>

            <Route path="/about">
                <Header user={user} SetUser={(user) => setUser(user)}/>
                <About/>
                <Footer code="E12-UNB-0339"/>
            </Route>

            <Route path="/privacy">
                <Header user={user} SetUser={(user) => setUser(user)}/>
                <PrivacyPolicy/>
                <Footer code="E12-UNB-0339"/>
            </Route>

            <Route path="/terms">
                <Header user={user} SetUser={(user) => setUser(user)}/>
                <Terms/>
                <Footer code="E12-UNB-0339"/>
            </Route>

            <Route path="/organizations" render={(innerProps) => {
              if(!user || !user.logged_in){
                return <Redirect to="/login" />
              }
              return <>
                <Header user={user} SetUser={(user) => setUser(user)}/>
                <Organizations/>
                <Footer code="E12-UNB-0339"/>
              </>;
            }}/>

            <Route path="/members" render={(innerProps) => {
              if(!user || !user.logged_in){
                return <Redirect to="/login" />
              }
              return <>
                <Header user={user} SetUser={(user) => setUser(user)}/>
                <Members/>
                <Footer code="E12-UNB-0339"/>
              </>;
            }}/>

            <Route path="/myprofile" render={(innerProps) => {
              if(!user || !user.logged_in){
                return <Redirect to="/login" />
              }
              return <>
                <Header user={user} SetUser={(user) => setUser(user)}/>
                <MyProfile user={user} SetUser={(user) => setUser(user)}/>
                <Footer code="E12-UNB-0339"/>
              </>;
            }}/>

            <Route path="/forgotPassword" render={(innerProps) => {
              return <>
                <ForgotPassword {...innerProps}/>
                <Footer code="E12-UNB-0339"/>
              </>;
            }}/>

            <Route path="/contentsubmission" render={(innerProps) => {
              if(!user || !user.logged_in){
                return <Redirect to="/login" />
              }
              return <>
                <Header user={user} SetUser={(user) => setUser(user)}/>
                <ContentSubmission user={user}/>
                <Footer code="E12-UNB-0339"/>
              </>;
            }}/>

            <Route path="/verify/:token" render={(innerProps) => {
              return <>
                <VerifyEmailPage {...innerProps} SetUser={(user) => setUser(user)}/>
              </>;
            }}/>

            <Route path="/admin--">
              <AdminPanel styling={styling}>
                <Switch>
                    <Route exact path="/admin--/content-management/:id">
                        <ContentForm styling={styling}/>
                    </Route>
                    <Route exact path="/admin--/content-management/new">
                        <ContentForm styling={styling}/>
                    </Route>
                    <Route exact path="/admin--/content-management">
                        <ContentTable styling={styling}/>
                    </Route>
                </Switch>
              </AdminPanel>
            </Route>

            <Route render={() => <Redirect to="/home" />} />

          </Switch>
        </Router>
      </div>
    </div>
  );
}

export default App;

/*
            <Route path="/contentlist">
              <Header/>
              <ContentListPage/>
            </Route>

            <AdminPanel styling={styling}>
          <Switch>
              <Route exact path="/admin--/content/:id">
                  <ContentForm styling={styling}/>
              </Route>
              <Route exact path="/admin--/content/new">
                  <ContentForm styling={styling}/>
              </Route>

              <Route exact path="/admin--/content">
                  <ContentTable styling={styling}/>
              </Route>
              
              <Route render={() => <Redirect to="/admin--/content" />} />
          </Switch>
        </AdminPanel>

        <Route path="/home/:login" component={Login} />
            <Route path="/home" component={Login} />
*/