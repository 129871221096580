import React, { Component } from 'react'
import './Registration.scss';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

import axios from './myAxios';

import TextInput from './RegistrationInput/TextInput.js';
import OptionInput from './RegistrationInput/OptionInput.js';
import CountryInput from './RegistrationInput/CountryInput.js';
import TelephoneInput from './RegistrationInput/TelephoneInput.js';
import EmailInput from './RegistrationInput/EmailInput.js';
import Popup from './Popup.js';
import LoginModal from './Modals/LoginModal.js';
import HomeHeader from './HomeHeader.js';

import UserAction from './UserAction';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import Banner from './Banner';

class Registration extends Component {
  state = {
    fields: {},
    countries: {},
    modalVisible: false,
    modalTitle: "",
    modalText: "",
    modalColor: "",
    hasError: {},
    showRed: false,
    modalLoginVisible: false,
    checked1: false,
    checked2: false,
    checked3: false
  }

  componentDidMount() {
    UserAction.Save("Register", "Visited");
    axios.get(`/admin/api/getRegistrationFields.php`)
      .then(res => {
        //let hasError = {};
        //for(const property in res.data["personal_information"]){
        //  hasError[property] = false;
        //}
        this.setState({
        //  hasError: hasError,
          fields: res.data["personal_information"],
          countries: res.data["countries"]
        });
        console.log(res.data);
      });
  }

  handleInputChange = (property, value) => {
    this.state.fields[property]["value"] = value;
    console.log(this.state.fields[property]);
  }

  handleOnErrorChange = (property, value) => {
    let hasError = this.state.hasError;
    hasError[property] = value;
    this.setState({hasError: hasError});
  }

  renderFields() {
    let result = [];
    let fields = this.state.fields;

    for(const property in fields){
      if(fields[property]["type"] == "text"){
        result.push(<TextInput onChange={this.handleInputChange} onErrorChange={this.handleOnErrorChange} showRed={this.state.showRed} name={property} options={fields[property]} type="text"/>)
      }
      else if(fields[property]["type"] == "option"){
        result.push(<OptionInput onChange={this.handleInputChange} onErrorChange={this.handleOnErrorChange} showRed={this.state.showRed} name={property} options={fields[property]} type="text"/>)
      }
      else if(fields[property]["type"] == "telephone"){
        result.push(<TelephoneInput onChange={this.handleInputChange} onErrorChange={this.handleOnErrorChange} showRed={this.state.showRed} name={property} options={fields[property]} type="text"/>)
      }
      else if(fields[property]["type"] == "email"){
        result.push(<EmailInput onChange={this.handleInputChange} onErrorChange={this.handleOnErrorChange} showRed={this.state.showRed} name={property} options={fields[property]} type="text"/>)
      }
      else if(fields[property]["type"] == "country"){
        result.push(<CountryInput onChange={this.handleInputChange} onErrorChange={this.handleOnErrorChange} showRed={this.state.showRed} name={property} options={fields[property]} countries={this.state.countries} type="text"/>)
      }
    }
    return result;
  }

  register(){
    let values = {};
    //let fields = this.state.fields;
    let valid = true;
    //let hasError = {};

    /*for(const property in fields){
      hasError[property] = false;
      if(fields[property]["required"] == 1){
        if(fields[property]["value"] && fields[property]["value"].trim() != ""){
          values[property] = fields[property]["value"];
        }
        else{
          hasError[property] = true;
          valid =  false;
        }
      }
    }
    this.setState({hasError: hasError});
    */
    let fields = this.state.hasError;
    for(const property in fields){
      if(fields[property]){
        valid = false;
      }
    }
    if(!valid){
      this.setState({
        showRed: true, //Initially disabled because we don't want to show the error messages on load, only after the first register click
        modalTitle: "Warning",
        modalText: "Please fill in all fields in red.",
        modalVisible: true,
        modalColor: "#E86651",
      });
      return;
    }

    fields = this.state.fields; //values

    for(const property in this.state.fields){
      values[property] = fields[property]["value"];
    }
    values["agree"] = this.state.checked1?"1":"0";
    values["future"] = this.state.checked2?"1":"0";
    values["gilead"] = this.state.checked3?"1":"0";

    values["Visible"] = 1;

    axios.post(`/admin/api/register.php`, values)
      .then(res => {
        if(res.data == "1"){
          UserAction.Save("Registered", "Clicked");
          this.setState({
            modalTitle: "Registration Submitted",
            modalText: `<span class="primary-color" style="font-weight: bold; font-size: 20px;">Thank you for your registration.</span><br/>Please check your email Inbox (including Spam/Junk), and click on the link to verify your registration.`,
            modalVisible: true,
            modalColor: "#243680",
          });
        }
        else{
          this.setState({
            modalTitle: "Warning",
            modalText: `You have already registered for the <br/><span class="primary-color" style="font-weight: bold">ANTI-STIGMA site.</span>`,
            modalVisible: true,
            modalColor: "#E86651",
          });
        }
      });
  }

  checkbox(){
    /*if(!this.state.checked1){
      this.setState({showRed: true});
    }*/
  }

  render() {
    return (
      <div className="background">
          <div className="background" style={{position: "fixed", zIndex: -1, left: "0px", top: "0px", right: "0px", bottom: "0px"}}></div>
        <Popup onClose={() => this.setState({ modalVisible: false })} visible={this.state.modalVisible} title={this.state.modalTitle} text={this.state.modalText} color={this.state.modalColor}/>
        <LoginModal onClose={() => this.setState({ modalLoginVisible: false })} visible={this.state.modalLoginVisible}/>
        
        <Banner/>
        
        <div className="my-container" style={{flexFlow: "wrap"}}>
          <div className="row">
            <div style={{color: "white", marginBottom: "45px", justifyContent: "center", display: "flex"}}>
              Register here to gain access to scientific, evidence-based, non-promotional anti-stigma resources
              and connect with other members of the community.
            </div>
          </div>

          <div className="box" style={{backgroundColor: 'white', width: 'fit-content', padding: '40px'}}>
            <div className="row tertiary-color">
              <div className="col-md-10 col-xl-9">
                <div className="row">
                  { this.renderFields() }
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-10 col-xl-9 tertiary-color">
              <div style={{marginBottom: "10px"}}>
                  By registering for this website you give consent to Gilead's <Link to={"/terms"} className="tertiary-color" style={{textDecoration: 'none', fontWeight: "bold"}}>terms and conditions</Link> and acknowledge that you have read Gilead's <Link to={"/privacy"} className="tertiary-color" style={{textDecoration: 'none', fontWeight: "bold"}}>privacy policy</Link>.
                </div>
                <div>
                  Gilead will use this personal data to give you access to ANTI-STIGMA.COM. The website is designed for healthcare professionals, patient advocacy groups, policy makers, and non-governmental organisation representatives. Gilead may transfer these data to the distributor of Gilead products in your country for this purpose.
                </div>
                <div style={{marginBottom: "10px"}}>
                  <label>
                    <input type="checkbox" onClick={()=> this.setState({checked1: !this.state.checked1})} checked={this.state.checked1}/>
                    <span style={{paddingLeft: '5px'}}> <b>I agree</b></span>
                  </label>
                </div>
                <div>
                  Would like to receive information about Gilead’s non-promotional meetings and events. Please note that Gilead or its marketing agencies may include technology (such as pixels, web beacons or other similar technology) in an email communication to determine if emails are opened and if links are clicked so that we can measure and improve the effectiveness of our emails.
                </div>
                <div style={{marginBottom: "10px"}}>
                  <label>
                    <input type="checkbox" onClick={()=> this.setState({checked2: !this.state.checked2})} checked={this.state.checked2}/>
                    <span style={{paddingLeft: '5px'}}> <b>Yes</b> - I would like to receive these communications via email</span>
                  </label>
                </div>
                <div>
                  Would like to receive information about Gilead, therapy areas, services, and news about us. Please note that Gilead or its marketing agencies may include technology (such as pixels, web beacons or other similar technology) in an email communication to determine if emails are opened and if links are clicked so that we can measure and improve the effectiveness of our emails.
                </div>
                <div style={{marginBottom: "10px"}}>
                  <label>
                  <input type="checkbox" onClick={()=> this.setState({checked3: !this.state.checked3})} checked={this.state.checked3}/>
                    <span style={{paddingLeft: '5px'}}> <b>Yes</b> - I would like to receive these communications via email</span>
                  </label>
                </div>
                
                <div className="row">
                    <div onClick={() => this.register()} className={"background-primary border-all border-1 border-primary"} style={{color: 'white', marginTop: '20px', cursor: 'pointer', width: '170px', borderRadius: '4px', padding: '4px 50px', fontFamily: 'Planer', fontWeight: 'bold', fontSize: '20px'}}>
                      Register
                    </div>
                </div>
                <Link to={"/login"} className="row" style={{textDecoration: 'none'}}>
                  <div style={{color: '#243680', width: '140px', fontFamily: 'Planer', fontSize: '16px', display:'inline-block'}}>
                    Already Registered?
                  </div>
                  <div style={{color: '#E86651', cursor: 'pointer', borderRadius: '10px', fontFamily: 'Planer', fontSize: '16px', display:'inline-block'}}>
                    Login
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Registration

/*
<Popup onClose={() => this.setState({ modalVisible: false })} visible={this.state.modalVisible} title={this.state.modalTitle} text={this.state.modalText}/>
        <LoginModal onClose={() => this.setState({ modalLoginVisible: false })} visible={this.state.modalLoginVisible}/>
        <HomeHeader/>
<div style={{marginBottom: '30px'}}>
            <div className="title-margin secondary-color" style={{fontSize: '44px', fontWeight: 'bold', fontFamily: 'Planer', lineHeight: '3rem', marginBottom: '1rem'}}>
              CF EXCEED 2021 Educational Portal
            </div>
          </div>
          <div className="row" style={{marginBottom: "10px"}}>
            <div className="col-sm-12 secondary-font primary-color" style={{fontWeight: "bold", fontSize: "26px"}}>
              REGISTRATION
            </div>
          </div>
          <div className="row" style={{marginBottom: "10px"}}>
            <div className="col-sm-12 primary-font secondary-color" style={{fontWeight: "bold", fontSize: "22px"}}>
              Personal Information
            </div>
          </div>
          <div className="row" style={{marginBottom: "50px"}}>
            <div className="col-sm-12 secondary-font primary-color">
              I agree and understand that by submitting this form, the information I include will be processed by Vertex Pharmaceuticals Incorporated, its
              global affiliates, and its service providers who provide services related to this event, to process your registration (including to facilitate and
              for record keeping obligations), to comply with Vertex’s legal obligations, for customer relationship management, and as further described
              in the Vertex Privacy Notice. Please do not include any health data in your registration.
            </div>
          </div>

          
*/
