import React, { Component } from 'react'
import './ContentPage.scss';
import { findDOMNode } from 'react-dom'

import MyVideoPlayer from './MyVideoPlayer.js'
import ContentInfo from './ContentInfo.js'
import Header from './Header.js'
import axios from './myAxios';
import user_activity from './UserAction.js';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import MyPlayer from './MyPlayer';
import EventList from './EventList';

class ContentPage extends Component {
  state = {
    playedSeconds: 0
  }

  componentDidMount() {
    user_activity.Save("Content Detail", "Visited", this.props.match.params.id);
  }

  componentDidUpdate(prevProps){
    if(prevProps.match.params.id !== this.props.match.params.id){
      user_activity.Save("Content Detail", "Visited", this.props.match.params.id);
    }
  }

  render() {
    return (
      <div style={{paddingBottom: "50px", backgroundColor: "white"}}>          
        <MyPlayer setPlayedSeconds={(sec) => this.setState({playedSeconds: sec})} id={this.props.match.params.id} user={this.props.user}/>
        <ContentInfo playedSeconds={this.state.playedSeconds} id={this.props.match.params.id} user={this.props.user}/>
        <EventList is_from_detail={true} type={"all"} page_type={"similar"} current_event={this.props.match.params.id} user={this.props.user}/>
      </div>
    )
  }
}

export default ContentPage

/*
line 32 <Link to={"/content"}>
            <div className="arrow" style={{position: "absolute", top: "32px", fontSize: "35px", color: "white"}}>
              <img src={axios.defaults.baseURL + "/documents/arrow.png"} style={{height: "20px", width: "auto"}}/>
            </div>
          </Link>
*/
