import { useEffect } from 'react';
import { useLocation, withRouter } from 'react-router-dom';

function ScrollToTop({ history }) {
  const location = useLocation();

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
      window.gtag("event", "page_view", {
        page_path: location.pathname + location.search,
      });
    });
    return () => {
      unlisten();
    }
  }, []);

  return (null);
}

export default withRouter(ScrollToTop);