import React from 'react';
import { Label, Input, FormGroup, Col } from 'reactstrap';
import styles from './MyInput.module.scss';


const mySelect = ( props ) => {
    console.log(props)

    const onChangeHandler = (optionValue) => {
        // console.log(optionValue)
        if (optionValue === props.formElem.placeholder){
            props.changed('', props.formElem.id);
            return;
        }
        props.changed(optionValue, props.formElem.id);
    }
    
    return (
        <FormGroup row className="m-0 mb-4 align-items-center">
            <Label xs="12" sm="4" md="3" lg="2" for={props.formElem.id} className={styles["label"] + " secondary-color"}>{props.formElem.name}</Label>
            <Col xs="12" sm="8" md="8" lg="6" className="p-0 pl-1">
                <Input 
                    className={styles["input"] + " pt-1  pl-1 custom-select " + (props.formElem.validity ? "border-secondary-color" : styles["input-invalid"])} 
                    type={props.formElem.type}
                    id={props.formElem.id}
                    onChange={e=>onChangeHandler(e.target.value)}
                >
                    <option defaultValue>{props.formElem.placeholder}</option>
                    {
                        props.formElem.options.map(opt => <option key={opt} value={opt}> {opt} </option>)
                    }
                </Input>
            </Col>
            {/* <Col sm={props.feedbackWidth} className="pl-4 font-weight-bold text-danger font-italic">
                { props.validity === "is-invalid" ? props.feedback : null }
            </Col> */}
        </FormGroup>
    );

};

// const arePropsEqual = (prevProps, nextProps) =>  prevProps.formElem.value === nextProps.formElem.value; 
// export default React.memo(mySelect, arePropsEqual);
export default React.memo(mySelect);