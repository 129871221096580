import React, { Component } from 'react'
import './Home.scss';
import styles from './ContentListPage.scss';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'

import axios from 'axios';

//import EducationalContent from './EducationalContent.js'
import Banner from './Banner';
import user_activity from './UserAction.js';

class About extends Component {
  state = {
    data: [],
    search: {
      freeText: ""
    },
    tab: "all_specialties",
    orderBy: ""
  }

  componentDidMount() {
    user_activity.Save("About ANTI-STIGMA", "Visited");
  }

  render() {
    return (
      <div style={{backgroundColor: "white"}}>
        <Banner/>
        <div className='title primary-color' style={{fontSize: "22px", fontWeight: "bold", marginLeft:"50px", marginTop: "20px"}}>
            About ANTI-STIGMA
        </div>
        <div className='tertiary-color' style={{margin:"20px 50px 20px 50px"}}>
                <p>
                    Anti-Stigma is a new platform organised and funded by Gilead Sciences Europe Ltd. aimed at healthcare professionals, 
                    patient advisory groups and non-governmental organisations representatives, 
                    who are working towards ending HIV stigma and discrimination in the community and healthcare settings.
                </p>
                <p>
                    The platform allows anti-stigma Champions to connect and share best practice approaches and initiatives to
                    continue the fight against stigma throughout the HIV community.
                </p>
                <p>
                Here you will be able to access community-generated, scientific, evidence-based, non-promotional resources
                and learn from real-world examples of anti-stigma initiatives, which may be adapted for use in other settings.
                The website will be updated regularly with new materials and upcoming anti-stigma events will also be announced here, so make sure to check the platform regularly to keep up to date. 
                </p> 
        </div>
        </div>
    )
  }
}

export default About