import React, { Component } from 'react'
import './Home.scss';
import styles from './PrivacyPolicy.scss';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'

import axios from 'axios';

//import EducationalContent from './EducationalContent.js'
import Banner from './Banner';
import user_activity from './UserAction.js';

class Terms extends Component {
  state = {
    
  }

  componentDidMount() {
    
  }

  render() {
    return (
        <div className="list" style={{backgroundColor: "white"}}>
            <Banner/>
            <div className='title primary-color' style={{fontSize: "22px", fontWeight: "bold", marginLeft:"50px", marginTop: "20px"}}>
                Terms of Use
            </div>
            <div className='tertiary-color' style={{padding: "20px 50px 50px 50px"}}>
              <p>These terms of use (together with the documents referred to in it) ("<strong>Terms of Use</strong>") are the terms on which you may access and use www.anti-stigma.com (our, or this "<strong>Website</strong>").&nbsp; Please read these Terms of Use carefully before using our Website.</p>
              <p>By accessing or using our Website, you confirm that you accept these Terms of Use, you agree to comply with them, and you represent that you are at least eighteen (18) years of age.&nbsp; If you do not agree to these Terms of Use, you must not access or use our Website.</p>
              <p>This Website is operated by Gilead Sciences Europe Ltd. ("<strong>Gilead</strong>", "<strong>we</strong>", "<strong>us</strong>", "<strong>our</strong>").&nbsp; which is a company incorporated and registered in England under company number 05510315 and whose registered office is at 2 Roundwood Avenue, Stockley Park, Uxbridge, Middlesex, UB11 1AF.&nbsp; Gilead may change these Terms of Use from time to time without notice. Any modification to these Terms of Use will become binding when published. Your continued access or use of this Website constitutes your acceptance of such changes.</p>
              <p>In these Terms of Use, you ("<strong>you</strong>", &ldquo;<strong>your</strong>&rdquo;) means a user or users of this Website.</p>
              <ol>
                <li>
                    <b>Agreement to Terms of Use</b>
                    <ol>
                      <li>This Website is intended for use by healthcare professionals, patient advocacy representatives, non-governmental organisations representatives, or policymakers in Central and Eastern European countries (Bosnia and Herzegovina, Bulgaria, Croatia, Cyprus, Czech Republic, Greece, Hungary, Latvia, Lithuania, Malta, Poland, Portugal, Romania, Serbia, Slovakia, Slovenia, Turkey) for you to connect, access, and share best practice approaches and initiatives to continue the fight against stigma in healthcare and community settings. If you are not a healthcare professional, patient advocacy representative, non-governmental organisation representative, or policymaker in one of these countries, you must stop using this Website immediately. You will only be authorised to access content on the Website if you have registered.</li>
                      <li>Gilead expressly disclaims any liability to those using the Website other than as set out in these Terms of Use, or as otherwise approved by Gilead, to the maximum extent permitted by applicable law.</li>
                    </ol>
                </li>
                <li>
                    <b>Accessing Our Website</b>
                    <ol>
                      <li>This Website is made available free of charge for your non-commercial use. However, you are responsible for making all arrangements necessary for you to have access to our Website.</li>
                      <li>We do not guarantee that our Website, or all materials, text, code, content, software, videos, music, sound, graphics, photographs, illustrations, artwork, names, logos, marks, formats, files, devices and links contained in it or linked to the Website (the "<strong>Content</strong>"), will always be available or be uninterrupted. Access to our Website is permitted on a temporary basis and we may suspend, withdraw, discontinue or change all or any part of our Website or the Content without notice or liability.&nbsp; We will not be liable to you if for any reason our Website is unavailable at any time or for any period.</li>
                      <li>You are also responsible for ensuring that all persons who access our Website through your internet connection are aware of these Terms of Use and any other applicable terms and conditions, and that they comply with them.</li>
                    </ol>
                </li>
                <li>
                    <b>Your use of the Website</b>
                    <ol>
                      <li>
                          <b>You agree that you will not:</b>
                          <ol>
                            <li>modify or redistribute any Content;</li>
                            <li>
                                use any Content on or in the Website:
                                <ol>
                                  <li>for any commercial purpose;</li>
                                  <li>for any illegal purpose, or</li>
                                  <li>for any other purpose prohibited by these Terms of Use; or</li>
                                </ol>
                            </li>
                            <li>upload to the Website any files that contain viruses, malware, spyware, "Trojan Horses", worms, cancelbots, corrupted files or other such similarly destructive features or otherwise in any way damage, disable or impair the operation of the Website, or attempt to do any of the same, or gain or attempt to gain unauthorised access to the Website, or to networks connected to it, or to Content delivered through it, by any means including by hacking, spoofing or seeking to circumvent or defeat any firewalls or other technological or other protections or security measures.</li>
                          </ol>
                      </li>
                      <li>
                          You agree that, when accessing restricted functions on the Website (the <b>&ldquo;Restricted Areas&rdquo;</b>), you will:
                          <ol>
                            <li>keep secret and safe any username, authorisation code or password that you may require to access the Restricted Functions; and</li>
                            <li>notify us as soon as possible if your username, authorisation code or password is lost or stolen or if you have any reason to believe that there has been unauthorised access to restricted areas of the Website.</li>
                          </ol>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <b>Purpose of the Website</b>
                      <ol>
                        <li>The Content is provided for general information only. It is not intended to amount to advice on which you should rely. You must obtain specialist advice before taking, or refraining from, any action on the basis of the content on our Website.</li>
                        <li>This Website and its Content does not, and is not intended to, provide medical advice, nor does it provide instruction on the appropriate use of any product produced or supplied by Gilead, its affiliates, related companies, or its licensors or joint venture partners.</li>
                        <li>The information presented on this Website and in the Content should not be interpreted or construed in any way as a replacement or substitution for medical advice provided by your doctor or healthcare provider. It is important that you discuss your treatment options, or any questions that you have with your doctor or healthcare provider.</li>
                        <li>This Website may contain information, news, and/or press releases, the content of which is accurate or current only as of the date of the particular information, news, press release, or posting. We do not update press releases once they have been made public.</li>
                      </ol>
                  </li>
                  <li>
                      <b>Limitation of Our Liability</b>
                          <ol>
                            <li>
                                This Website and its Content are provided "as is" and, subject to paragraph 5.3, we exclude all written, oral, statutory, express or implied representations, warranties, conditions and terms concerning the Website, to the fullest extent permitted by law. In particular (without limitation):
                                <ol>
                                  <li>
                                      we provide the Website and any Content in good faith and free of charge. We give no warranty or representation that the Website and any Content:
                                      <ol>
                                        <li>is accurate, complete or up-to-date; or</li>
                                        <li>does not infringe the rights of any third party.</li>
                                      </ol>
                                  </li>
                                  <li>we accept no responsibility or liability for your use of the Website and any Content and such use is entirely at your own risk. While we take reasonable precautions to prevent the existence of viruses, malware, spyware and/or other malicious programs on or through the Website and any Content, we accept no liability for them;</li>
                                  <li>we make no warranties or representations that the servers or other equipment and services required to make the Website and any Content available will be free of viruses or other harmful components; and</li>
                                  <li>we do not accept liability for your failure to take reasonable anti-virus, firewall, and other appropriate security precautions when using the Website and any Content. You are responsible for configuring your information technology, computer programmes and platforms in order to access the Website and you should use your own virus protection software.</li>
                                </ol>
                            </li>
                            <li>
                                Subject to 5.3, neither we or any of our agents, affiliates, related companies, licensors or joint venture partners shall be liable for:
                                <ol>
                                  <li>any direct loss, cost, expense, liability or damages arising out of user's access, use, or inability to use the Website, or any errors or omissions in the Content; and</li>
                                  <li>any loss of profit, loss of business, loss of goodwill, loss of data, or any special, indirect, incidental, consequential, or economic loss howsoever caused arising out of or in connection with these Terms of Use or your use of the Website.</li>
                                </ol>
                            </li>
                            <li>
                                Nothing in these Terms of Use shall limit our liability for:
                                <ol>
                                  <li>personal injury or death resulting from our negligence;</li>
                                  <li>our fraud; or</li>
                                  <li>any liability that cannot be excluded or limited by law.</li>
                                </ol>
                            </li>
                          </ol>
                        </li>
                        <li>
                            <b>No Responsibility</b>
                                <ol>
                                  <li>
                                      We assume no responsibility for:
                                      <ol>
                                        <li>the Website and/or its functionality;</li>
                                        <li>the Website&rsquo;s availability;</li>
                                        <li>any Content provided or made available by us;</li>
                                        <li>the servers and/or other equipment and services required to make the Website available; or</li>
                                        <li>your mobile device and any carrier charges that may apply as a result of your use of the Website.</li>
                                      </ol>
                                  </li>
                                  <li>We reserve the right to make any changes or updates to the Website, including to the services and functionality it provides, at any time, with or without notice.</li>
                                  <li>We reserve the right to withdraw the Website at any time, with or without notice.</li>
                                  <li>You acknowledge that we may, from time to time, issue upgraded versions of the Website and that we may automatically upgrade the version of the Website that is available online. You consent to such automatic upgrading of the Website, and agree that these Terms of Use (as may be amended from time to time) will apply to all such upgrades.</li>
                                </ol>
                            </li>
                          <li>
                              <b>Third Party Links and Resources in Our Website</b>
                              <ol>
                                <li>Our Website may contain links to other sites, which we believe may be of interest to you. We are not responsible for the content, security, or privacy practices employed by other sites and a link does not constitute an endorsement of the content, viewpoint, accuracy, opinions, policies, products, services, or accessibility of that website.&nbsp; Your use of such websites is entirely at your own risk.</li>
                                <li>Once you link to another website from our Website you are subject to the terms and conditions of that website, including, but not limited to, its Internet privacy policy and practices. Please check these policies before you submit any personal data to these websites.</li>
                                <li>We do not accept responsibility or liability for the availability or content of any third party websites, nor liability for any damage or harm that you may suffer from visiting and/or using them.</li>
                              </ol>
                          </li>
                          <li>
                            <b>Intellectual Property Rights</b>
                            <ol>
                              <li>The Gilead company name and logo, and all product names, regardless of whether or not they appear in large print or with a trademark symbol, are trademarks of us, our agents, affiliates, related companies, or our licensors or joint venture partners, unless otherwise noted. Use, reproduction, copying, or redistribution of these trademarks without our written permission is prohibited.</li>
                              <li>This Website and our Content, including any images or text, and all copyrights, trademarks, designs, and other intellectual property rights (collectively &ldquo;<strong>IPR</strong>&rdquo;) is owned by us or licensed to us.</li>
                              <li>The use or misuse of this IPR, except as permitted under these Terms of Use, is expressly prohibited and may be in violation of copyright law, trademark law, communications regulations and statutes, and other laws, statutes, and/or regulations.</li>
                              <li>Anyone accessing this Website is entitled to view any part of the IPR and use it for private non-commercial purposes. However, our content must not be used nor reproduced (in whole or part) for any other purpose, including on or in connection with another website or publication or for commercial gain, without first obtaining a licence to do so from us.</li>
                            </ol>
                          </li>
                          <li>
                            <b>Changes to the Terms of Use</b>
                            <ol>
                              <li>We may from time to time change the Terms of Use by updating or amending the version of the Terms of Use that is available on the Website. All amended terms will automatically take effect immediately on posting.&nbsp; Please check these Terms of Use periodically to inform yourself of any changes which are binding on you.</li>
                              <li>By continuing to use the Website after such changes have entered into effect, you acknowledge your acceptance of such changes and agree to be bound by the amended or updated Terms of Use.</li>
                            </ol>
                          </li>
                          <li>
                            <b>Other important terms</b>
                            <ol>
                              <li>If any of these Terms of Use are found to be illegal, invalid or unenforceable by any court of competent jurisdiction, the rest of these Terms of Use shall remain in full force and effect. No waiver by us of any breach of these terms shall constitute a waiver of any other breach.&nbsp; No failure by us to exercise any remedy shall constitute a waiver of the right subsequently to exercise that or any other remedy.</li>
                              <li>Only you and we shall be entitled to enforce these Terms of Use. No third party shall be entitled to enforce any of these Terms of Use.</li>
                              <li>These Terms of Use set out the entire agreement between you and us and supersede any and all prior terms, conditions, warranties and/or representations to the fullest extent permitted by law.</li>
                              <li>These Terms of Use are governed by English law. In the event of any matter or dispute arising out of or in connection with these Terms of Use, you and we shall submit to the exclusive jurisdiction of the English courts.</li>
                            </ol>
                          </li>
                          <li>
                            <b>Contact details</b>
                </li>
              </ol>
              <p>If you have any questions about these Terms of Use or wish to contact us for any other reason, please contact us by email at: <a href="mailto:Anti-Stigma@nucleusglobalteams.com">Anti-Stigma@nucleusglobalteams.com</a>.</p>
              <p>&nbsp;</p>
              <p>Version: May 2022</p>
            </div>
        </div>
    )
  }
}

export default Terms