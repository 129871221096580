import React, { Component } from 'react'
import './TelephoneInput.scss';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

class TelephoneInput extends Component {
  state = {
    value: ''
  }

  componentDidMount() {
    let error = true;
    if(this.props.options["required"] == 0){ //Setting initial error value
      error = false;
    }
    this.props.onErrorChange(this.props.name, error);
    this.setState({hasError: error});
  }

  handleChange(originalValue, country, e, formattedValue) {
    let value = formattedValue;
    this.setState({value: value});
    this.props.onChange(this.props.name, value);

    let error = false;

    if(value == ""){
      if(this.props.options["required"]==1){
        error = true;
      }
    }
    else{
      if(value.length < this.props.options["min"]){
        error = true;
      }
      else if(value.length > this.props.options["max"]){
        error = true;
      }
    }

    this.props.onErrorChange(this.props.name, error);
    this.setState({hasError: error});
  }

  render() {
    return (
      <div className={"col-sm-12 col-md-6 col-xl-"+this.props.options["size"]} style={{marginBottom: "20px"}}>
        <div style={{fontSize: "14px"}}>
          { this.props.name }{ this.props.options["required"]==1?"*":"" }
        </div>
        <PhoneInput
          value={this.state.value}
          country={'gr'}
          onChange={this.handleChange.bind(this)}
          enableSearch={true}
          inputStyle={{border: this.props.showRed&&this.state.hasError?"1px solid red":"1px solid #67696e", width: "100%"}}
        />
      </div>
    )
  }
}

export default TelephoneInput
