import { colors } from '../../../../styles/_colors';

export let customStyles = {
    // header: {
    //     style: {
    //         minHeight: '56px',
    //     },
    // },
    subHeader: {
        style: {
            // backgroundColor: "blue",
            minHeight: '52px',
            padding: 0,
            margin: 0,
            marginBottom: "3vh"
        },
    },
    headRow: {
        style: {
            backgroundColor: "grey",
            borderBottomStyle: 'solid',
            borderBottomWidth: '3px',
            borderBottomColor: "#fff"
        },
    },
    headCells: {
        style: {
            '&:not(:last-of-type)': {
                color: "#fff",
                fontSize: "18px",
                fontWeight: "bold",
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
            },
        },
        activeSortStyle: {
            color: "#fff",
            '&:hover': {
                color: "#fff",
            },
        },
        inactiveSortStyle: {
            '&:hover': {
                color: "#fff",
            },
        },
    },
    rows: {
        style: {
            '&:nth-child(odd)': {
                backgroundColor: colors.tableOddRow
            },
            '&:nth-child(even)': {
                backgroundColor: colors.tableEvenRow
            },
            '&:not(:last-of-type)': {
                borderBottomStyle: 'solid',
                borderBottomWidth: '1px',
                borderBottomColor: "#fff"
            }
        }
    },
    cells: {
        style: {
            '&:not(:last-of-type)': {
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                fontSize: "16px",
                borderRightColor: "#fff",
            },
        },
    },
};