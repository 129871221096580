import React, { Component } from 'react'
import './EmailInput.scss';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

class EmailInput extends Component {
  state = {
    value: "",
    hasError: true,
    hasFirstTyped: false
  }

  componentDidMount() {
    let error = true;
    this.props.onErrorChange(error);
    this.setState({
      hasError: error,
      hasFirstTyped: false
    });
  }

  handleChange(event) {
    let value = event.target.value.trim().toLowerCase();
    this.setState({value: value});
    if(!this.state.hasFirstTyped){
      this.setState({hasFirstTyped: true});
    }
    this.props.onChange(value);

    let error = false;

    if(value == ""){
      error = true;
    }
    else{
      if(!this.validateEmail(value)){
        error = true;
      }
    }

    this.props.onErrorChange(error);
    this.setState({hasError: error});
  }

  validateEmail(email){
    let reg = /^.+@.+\..+$/;

    if (reg.test(email) == false)
    {
      return false;
    }
    return true;
  }

  render() {
    return (
      <div>
        <div style={{fontSize: "16px"}}>
          Email Address*
        </div>
        <input className={"textInput"} type="text" value={this.state.value} onChange={this.handleChange.bind(this)} style={{width: "100%", border: this.state.hasError&&this.state.hasFirstTyped?"1px solid red":""}}/>
        <div style={{color: "red", fontSize: "13px", display: this.state.hasError&&this.state.hasFirstTyped?"block":"none"}}>
          Please enter a valid email
        </div>
      </div>
    )
  }
}

export default EmailInput
