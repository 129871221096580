import React, { Component } from 'react'
import './EducationalContent.scss';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'

import axios from './myAxios';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

class EducationalContent extends Component {
  state = {

  }

  componentDidMount() {
    console.log(this.props.data);
  }

  formatDate(dateString){
    if(!dateString){
      return "";
    }
    let dateArr = dateString.split("-");
    let date = new Date(dateArr[0], dateArr[1]-1, dateArr[2]);
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return  months[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear();
  }

  render() {
    return (
      <div className={"border-all border-2 border-secondary"} style={{display: "flex", flexFlow: "column", backgroundColor: "white", margin: "10px", width: "300px", height: "512px", borderRadius: "10px", padding: "10px", marginBottom: "20px", position: "relative"}}>
        <div style={{flex: "0 0 260px"}}>
          <Link to={"content/"+this.props.data["id"]}>
            <img src={ this.props.data["thumbnail"] } style={{cursor: "pointer", width: "100%", height: "165px", objectFit: "cover", borderRadius: "10px"}}/>
          </Link>
          <div className={"primary-color"} style={{fontWeight: "bold", fontSize: "20px", lineHeight: "25px", paddingTop: "5px"}}>
            { this.props.data["content_title"] }
          </div>
          <div className="presenters" style={{position: "absolute", top: "260px"}}>
            <div className={""}>
              <div style={{fontSize: "14px"}}>
                <b>{ this.props.data["creator"].length > 1?"Presenters":"Presenter"}: </b>{ this.props.data["creator"].join(", ") }<br/>
                <b>{ this.formatDate(this.props.data["creation_date"]) }</b>
              </div>
            </div>
          </div>
        </div>
        <div style={{flex: "1 1 auto", position: "relative", marginTop: "20px"}}>
          <div style={{marginTop: "45px"}}>
            <div className={""} style={{fontSize: "14px", lineHeight: "16px", marginBottom: "7px"}}>
              { this.props.data["description"] }
            </div>
          </div>
        </div>
        <div style={{flex: "0 0 40px"}}>
          <Link to={"content/"+this.props.data["id"]}>
            <div className={"background-primary primary-font"} style={{fontWeight: "bold", cursor: "pointer", display: "inline-block", color: "white", padding: "5px 15px", borderRadius: "5px", position: "absolute", bottom: "8px"}}>
              VIEW
            </div>
          </Link>
        </div>
      </div>
    )
  }
}

export default EducationalContent
