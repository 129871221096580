import React, { Component } from 'react';
//import produce from 'immer';
import { withRouter } from 'react-router-dom';
import styles from './AdminPanel.module.scss';
import ContentTable from './Content/ContentTable/ContentTable';
import logoImg from '../../../images/logo.png';
import {
    Container, Col, Row,Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Button,
    ListGroup, ListGroupItem, CardFooter, CardHeader 
} from 'reactstrap';
import { LightenDarkenColor } from '../../utilities/colorUtility';


const adminMenuItems = ["Registered Users", "User Activity", "Content Activity", "Content Management"];


class AdminPanel extends Component {

    getMenuTabPath = (menuItem) => "/admin--/" + menuItem.replace(" ", '-').toLowerCase();

    menuItemClickedHandler = (menuItem) => {
        const tabPath = this.getMenuTabPath(menuItem);
        this.props.history.push(tabPath);
    } 

    logOutHandler = () => {
        alert("logout")
    }

    getMenuItemStyle = (menuItem) => {
        const currPath = this.props.location.pathname;
        const tabPath = this.getMenuTabPath(menuItem);
        // console.log(tabPath)
        if (currPath.includes(tabPath)) {
            return { backgroundColor: this.props.styling.secondaryColor };
        } else {
            return { backgroundColor: this.props.styling.primaryColor};
        }
    }


    render() {
        return (
            <Container fluid>
                <Row className={styles["padding-left"]} >
                        <Card id={styles["side-panel"]} className="primaryColor">
                            <CardHeader className="secondary-font" style={{backgroundColor: this.props.styling.primaryColor, textDecoration: "none", color: "white", fontSize: "32px", fontWeight: "bold", whiteSpace: "nowrap"}}>
                                ANTI-STIGMA
                            </CardHeader>
                            {/*<CardImg className="p-4" top width="100%" src={logoImg} alt="Logo" style={{backgroundColor: this.props.styling.primaryColor}} />*/}
                            <CardBody className="p-0" style={{backgroundColor: this.props.styling.primaryColor}}>
                                <ListGroup>
                                    {
                                        adminMenuItems.map(menuItem => (
                                            <ListGroupItem style={this.getMenuItemStyle(menuItem)} className={styles["menu-item"]} key={menuItem} onClick={(() => this.menuItemClickedHandler(menuItem))}>{menuItem}</ListGroupItem>
                                        ))
                                    }
                                </ListGroup>
                            </CardBody>
                            <CardFooter id={styles["logout"]} onClick={()=>this.logOutHandler()} style={{backgroundColor: this.props.styling.primaryColor}}>
                                Logout
                            </CardFooter>
                        </Card>

                    <Col className={"p-4"} style={{backgroundColor: "white"}}>

                        <Row>
                            <Col id={styles["title"]} className="primary-color">
                                SYNAPSE Portal - Admin Panel
                            </Col>
                        </Row>
                        

                        <Row>
                            <Col>
                                {this.props.children}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }

}

export default withRouter(AdminPanel);


/*line 36 return { backgroundColor: LightenDarkenColor(this.props.styling.secondaryColor, 40) };
line 38 return { backgroundColor: this.props.styling.secondaryColor};
line 70 <Row className="mb-5">
                            <Col id={styles["subtitle"]} className="primary-color">
                                SYNAPSE Portal - Admin Panel
                            </Col>
                        </Row>
*/