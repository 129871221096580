import React, { Component } from 'react'
import axios from './myAxios';

class MyPDFPlayer extends Component {

  state = {
    page: -1
  }

  componentDidMount(){
    /*let values = {};
    values["watched_percentage"] = 1;
    values["watched_seconds"] = 1;
    values["content_id"] = this.props.id;
    values["user_id"] = this.props.user.user_id;
    axios.post(`/admin/api/saveWatchedPercentage.php`, values)
      .then(res => {
        console.log(res);
      });*/
    
    let values = {};
    values["content_id"] = this.props.id;
    values["user_id"] = this.props.user.user_id;
    axios.post(`/admin/api/getWatchedPercentage.php`, values)
    .then(res => {
      console.log(res.data["watchedPercentage"]);
      let page = res.data["watchedPercentage"];
      this.setState({page: page});
    });

    this.handleMsg = this.handleMsg.bind(this);

    window.addEventListener('message', this.handleMsg);
  }

  componentWillUnmount(){
    window.removeEventListener('message', this.handleMsg);
  }

  handleMsg(e){
    const data = e.data;
    if(!Number.isInteger(data) || data <= 0)
      return;
    let values = {};
    values["watched_percentage"] = data;
    values["watched_seconds"] = data;
    values["content_id"] = this.props.id;
    values["user_id"] = this.props.user.user_id;
    axios.post(`/admin/api/saveWatchedPercentage.php`, values)
      .then(res => {
        console.log(res);
      });
  }

  render () {
    if(this.state.page == -1){
      return <>Loading...</>;
    }
    return (
      <div className={"background-secondary"} style={{width: "100%", height: "100%"}}>
        <iframe title="PDF" src={axios.defaults.baseURL + "/admin/api/getPDF.php?url="+encodeURIComponent(axios.defaults.baseURL + "/documents/" + this.props.url)+"&page="+this.state.page} style={{width: "100%", height: "100%", border: "none"}}/>
      </div>
    )
  }
}

export default MyPDFPlayer
