import React, { Component } from 'react';
import produce from 'immer';
// import { connect } from 'react-redux';
import styles from './ContentForm.module.scss';
import { Row, Col, Button } from 'reactstrap';
import MyInput from '../../UI/MyInputs/MyInput';
import MySelect from '../../UI/MyInputs/MySelect';
import MyFile from '../../UI/MyInputs/MyFile';
import MyImg from '../../UI/MyInputs/MyImg';
//import HtmlEditor from '../../UI/HtmlEditor/HtmlEditor';
import DatePicker from '../../UI/DatePicker/DatePicker';
import { checkValidity } from '../../../../utilities/validityUtility';
import axios from '../../../../../myAxios';


class ContentForm extends Component{

    state = {
        formControls: {
            category: {
                rules: {
                    required: true
                },
                id: "category",
                name: "Category",
                value: '',
                type: "select",
                options: ["HIV"],
                placeholder: "Choose..",
                feedback: '',
                validity: true,
            },
            
            content_type: {
                rules: {
                    required: true
                },
                id: "content_type",
                name: "Content Type",
                value: '',
                type: "select",
                placeholder: "Choose..",
                feedback: '',
                validity: true,
                options: ["Posters", "Slide decks", "Meeting Reports", "Case Studies", "Infographics"]
            },

            content_material_link: {
                rules: {
                    required: true,
                    isLink: true
                },
                id: "content_material_link",
                name: "Content Material",
                value: '',
                type: "text",
                placeholder: 'Enter Link https://...',
                feedback: '',
                validity: true
            },

            content_material_file: {
                rules: {
                    required: true,
                    acceptedTypes: []
                },
                id: "content_material_file",
                name: "",
                value: null,
                type: "file",
                feedback: '',
                prefix: "OR",
                validity: true
            },

            title: {
                rules: {
                    required: true,
                },
                id: "title",
                name: "Title",
                value: '',
                type: "text",
                placeholder: '',
                feedback: '',
                validity: true
            },

            thumbnail: {
                rules: {
                    required: true,
                    acceptedTypes: ["png", "jpg"]
                },
                id: "thumbnail",
                name: "Thumbnail",
                value: null,
                type: "img",
                feedback: '',
                validity: true
            },


            speaker: {
                rules: {
                    required: true,
                },
                id: "speaker",
                name: "Speaker/Author",
                value: '',
                type: "text",
                placeholder: '',
                feedback: '',
                validity: true
            },

            duration: {
                rules: {
                    required: true,
                },
                id: "duration",
                name: "Duration",
                value: '',
                type: "text",
                placeholder: '',
                feedback: '',
                validity: true
            },

            source: {
                rules: {
                    required: true,
                },
                id: "source",
                name: "Source",
                value: '',
                type: "text",
                placeholder: '',
                feedback: '',
                validity: true
            },

            description: {
                rules: {
                    required: true,
                },
                id: "description",
                name: "Description",
                value: '',
                type: "text",
                placeholder: '',
                feedback: '',
                validity: true
            },

            tags: {
                rules: {
                    required: true,
                },
                id: "tags",
                name: "Tags",
                value: '',
                type: "text",
                placeholder: '',
                feedback: '',
                validity: true
            },

            creation_date: {
                rules: {
                    required: true,
                },
                id: "creation_date",
                name: "Created On",
                value: new Date(),
                type: "date",
                placeholder: '',
                feedback: '',
                validity: true
            },

            posted_date: {
                rules: {
                    required: true,
                },
                id: "posted_date",
                name: "Posted On",
                value: new Date(),
                type: "date",
                placeholder: '',
                feedback: '',
                validity: true
            }, 

            favorites: {
                rules: {
                    required: true
                },
                id: "favorites",
                name: "Add to Favorites",
                value: '',
                type: "select",
                placeholder: "Choose..",
                feedback: '',
                validity: true,
                options: ["Yes", "No"]
            },

            download_content: {
                rules: {
                    required: true
                },
                id: "download_content",
                name: "Download Content",
                value: '',
                type: "select",
                placeholder: "Choose..",
                feedback: '',
                validity: true,
                options: ["Yes", "No"]
            },

            show: {
                rules: {
                    required: true
                },
                id: "show",
                name: "Show Content",
                value: '',
                type: "select",
                placeholder: "Choose..",
                feedback: '',
                validity: true,
                options: ["Yes", "No"]
            }
        }
        //editContent: (this.props.match.params.id==='undefined')?false:true //define if page is Edit Content or New Content
        //editContent: false
    }

    onChangeHandler = (value, formElemId) => {
        console.log("onChangeHandler",value, formElemId)
        this.setState(
			produce( draft => {
				draft.formControls[formElemId].value = value;
			})
		)
        
    }


    renderFormElem = (formElem) => {
        
        switch(formElem.type) {
            case "text":
                return (
                    <MyInput 
                        key={formElem.id}
                        labelWidth={2}
                        inputWidth={8}
                        feedbackWidth={2}
                        formElem={formElem}
                        changed={(e)=>this.onChangeHandler(e.target.value, formElem.id)}
                    />
                );
            /*case "htmlEditor":
                return (
                    <HtmlEditor
                        key={formElem.id}
                        labelWidth={2}
                        inputWidth={8}
                        feedbackWidth={2}
                        formElem={formElem}
                        onChangeHandler={this.onChangeHandler}
                    />
                );*/
            case "select":
                return(
                    <MySelect
                        key={formElem.id}
                        labelWidth={2}
                        inputWidth={6}
                        feedbackWidth={4}
                        formElem={formElem}
                        changed={this.onChangeHandler}
                    />
                );
            case "date":
                return (
                    <DatePicker
                        key={formElem.id}
                        labelWidth={2}
                        inputWidth={8}
                        feedbackWidth={2}
                        formElem={formElem}
                        onChangeHandler={this.onChangeHandler}
                    /> 
                );
            case "img":
                return (
                    <MyImg
                        key={formElem.id}
                        labelWidth={2}
                        inputWidth={"auto"}
                        feedbackWidth={2}
                        formElem={formElem}
                        onChangeHandler={(e)=>this.onChangeHandler(e.target.files[0], formElem.id)}
                    />
                );
            case "file":
                return (
                    <MyFile
                        key={formElem.id}
                        labelWidth={2}
                        inputWidth={"auto"}
                        feedbackWidth={2}
                        formElem={formElem}
                        onChangeHandler={(e)=>this.onChangeHandler(e.target.files[0], formElem.id)}
                    />
                );
            default:
                //alert("ERROR")
        }
    }

    submitFormHandler = () => {
        /*for (let [label, elem] of Object.entries(this.state.formControls)) {
            console.log("checking validity for:", label)
            if (["thumbnail", "content_material_file"].includes(label)) {
                // alert("aa") , "creation_date"
                this.setState(
                    produce( draft => {
                        draft.formControls[label].validity = elem.value ? true : false
                    })
                );
            }
            else{
                const isValid = checkValidity(elem.value, elem.rules).report ;
                /*if(["content_material_file"].validity || ["content_material_link"].validity){
                    ["content_material_file"].validity = true;
                    ["content_material_link"].validity = true;
                }
                this.setState(
                    produce( draft => {
                        draft.formControls[label].validity = isValid;
                    })
                );
            }
        }*/
        let values = {};
        values["category"] = this.state.formControls["category"].value;
        values["content_type"] = this.state.formControls["content_type"].value;
        values["title"] = this.state.formControls["title"].value;
        values["created_on"] = this.state.formControls["creation_date"].value;
        values["posted_date"] = this.state.formControls["posted_date"].value;
        values["first_name"] = this.state.formControls["speaker"].value.split(" ")[0];
        values["last_name"] = this.state.formControls["speaker"].value.split(" ")[1];
        values["duration"] = this.state.formControls["duration"].value;
        values["source"] = this.state.formControls["source"].value;
        values["description"] = this.state.formControls["description"].value;
        values["tags"] = this.state.formControls["tags"].value;
        axios.post(`/admin/api/saveContent.php`, values)
        .then(res => {
            console.log("saving content");
            console.log(res);
        });
    }


    render() {
        console.log(this.state.formControls)

        let formElements = [];
        for (let [label, elem] of Object.entries(this.state.formControls)) {
            formElements.push(this.renderFormElem(elem));
        }

        /*this.state.editContent = (typeof this.props.match ==='undefined')?false:true
        if(this.state.editContent){
            console.log("edit content, id:", this.props.match.params.id);
        }*/

        return (
            <>
                <Row className="mb-4">
                    <Col className={styles["header"] + " primary-color"} style={{"text-decoration-color": this.props.styling.primaryColor}}>
                         <u>New Content</u>
                    </Col>
                </Row>

                <Row className="pl-3 pr-3">
                    <Col  className="p-0">
                        {
                            formElements
                        }
                        <Row className="justify-content-center pt-5">
                            <Button size="m" className="custom-btn bg-primary-color" onClick={this.submitFormHandler} style={{backgroundColor: this.props.styling.primaryColor, borderColor: this.props.styling.primaryColor, width: "100px"}}>
                                Add
                            </Button>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
}




// const mapStateToProps = (state) => ({
    
// })

// const mapDispatchToProps = {
    
// }

// export default connect(mapStateToProps, mapDispatchToProps)(ContentForm)
export default ContentForm;