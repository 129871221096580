import React, { Component } from 'react'

import axios from './myAxios';
import MyPDFPlayer from './MyPDFPlayer';
import MyVideoPlayer from './MyVideoPlayer';

import './MyPlayer.scss';

class MyPlayer extends Component {

    state = {
        url: "",
        voting: "",
        document_type: "",
        playedSeconds: 0
    }

  componentDidMount () {
    axios.get(`/admin/api/getContentDetails.php`, { params: { id: this.props.id } })
      .then(res => {
        this.setState({voting: res.data["voting"], url: res.data["content_material_url"], document_type: res.data["document_type"]});
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id) {
        axios.get(`/admin/api/getContentDetails.php`, { params: { id: this.props.id } })
        .then(res => {
          this.setState({voting: res.data["voting"], url: res.data["content_material_url"], document_type: res.data["document_type"]});
        });
    }
  }

  render () {
    if(this.state.document_type == "Video"){
        return (
          <div className="myPlayer myVideoPlayer">
            <MyVideoPlayer setPlayedSeconds={(sec) => this.props.setPlayedSeconds({playedSeconds: sec})} url={this.state.url} voting={this.state.voting} id={this.props.id} user={this.props.user}/>
          </div>
        )
    }
    else if(this.state.document_type == "PDF"){
        console.log("PDF");
        return (
          <div className="myPlayer myPDFPlayer">
            <MyPDFPlayer url={this.state.url} id={this.props.id} user={this.props.user}/>
          </div>
        )
    }
    return (
      <>
        Unknown file type
      </>
    )
  }
}

export default MyPlayer
