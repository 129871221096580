import React, { Component } from 'react'
import './EducationalContentPage.scss';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'

import axios from './myAxios';

import EducationalContent from './EducationalContent.js'
import UserAction from './UserAction.js';
import Banner from './Banner.js';

class EducationalContentPage extends Component {
  state = {
    data: []
  }

  componentDidMount() {
    UserAction.Save("Educational Content", "Visited");
    axios.get(`/admin/api/getContents.php`)
      .then(res => {
        console.log(res.data);
        this.setState({data: res.data});
      });
  }

  render() {
    return (
      <div style={{marginBottom: "150px"}}>
        <Banner/>
        <div className={"tertiary-color"} style={{display: "flex", flexFlow: "row wrap", justifyContent: "space-around", padding: "0px 30px 30px"}}>
          {
            this.state.data.map(educationalContent => {
              return <EducationalContent data={educationalContent}/>
            })
          }
        </div>
      </div>
    )
  }
}

export default EducationalContentPage
