import React, { Component } from 'react'
import './Voting.scss';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'

import QuestionsContainer from './QuestionsContainer.js'

class Voting extends Component {
  state = {

  }

  renderQuestion(){
    let questions = this.props.data["questions"];
    let result = [];

    for(const property in questions){
      result.push(<p>{questions[property]["question"]}</p>);
      break;
    }
    return result;
  }

  renderAnswers(){
    let questions = this.props.data["questions"];
    let result = [];
    for(const property in questions){
      questions[property]["answers"].forEach(answers => {
        result.push(<li>{answers["answer"]}</li>)
      });
      break;
    }
    return result;
  }

  componentDidUpdate(){
    //this.setState({data: this.props.data});
    //console.log(this.props.data);
  }

  componentDidMount() {

  }

  render() {
    return (
      <div className={"popup-bg"}>
        <div className={"popup border-all border-2 border-primary primary-color"} style={{borderRadius: "20px"}}>
          <QuestionsContainer
            doneHandler={() => this.props.doneHandler()}
            top_text={this.props.data["top_text"]}
            votingId={this.props.votingId}
            questions={this.props.data["questions"]} />
        </div>
      </div>
    )
  }
}

export default Voting
