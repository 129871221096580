import React, { Component } from 'react'
import './Footer.scss';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import UserAction from './UserAction.js'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import axios from './myAxios';
import ConfirmPopup from './ConfirmPopup';

let h = 0;
class Footer extends Component {
  state = {
    interval: null,
    modalVisible: false,
    modalTitle: "",
    modalText: ""
  }

  componentDidMount() {
    this.Resized = this.Resized.bind(this);

    window.addEventListener('resize', this.Resized);
    //this.Resized();

    let intervalId = setInterval(this.Resized, 16);
    this.setState({ intervalId: intervalId });
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.Resized);
    clearInterval(this.state.intervalId);
  }

  Resized(){
    //document.getElementById("myFooter").style.display = "flex";
    const footer = document.getElementById("myFooter");
    //const footerHeight = footer.offsetHeight;
    //document.getElementById("myFooter").style.display = "none";

    const windowHeight = window.innerHeight;
    const documentHeight = document.body.scrollHeight;

    const main = document.body;

    //console.log(main.scrollTop + main.offsetHeight - main.scrollHeight);

    //footer.style.bottom = main.scrollTop + main.offsetHeight - main.scrollHeight + "px";

    //document.getElementById("myFooter").style.display = "flex";

    let extra = footer.clientHeight;
    if(document.getElementById("myFooter").style.position == "initial"){
      extra = 0;
    }
    if(documentHeight < windowHeight - extra){
      if(document.getElementById("myFooter").style.position !== "fixed")
        document.getElementById("myFooter").style.position = "fixed";
    }
    else{
      if(document.getElementById("myFooter").style.position !== "initial")
        document.getElementById("myFooter").style.position = "initial";
    }
    
    /*if(deviceHeight >= bodyHeight + footerHeight){ //content is smaller
      if(document.getElementById("myFooter").style.position !== "fixed")
        document.getElementById("myFooter").style.position = "fixed";
    }
    else{
      if(document.getElementById("myFooter").style.position !== "initial")
        document.getElementById("myFooter").style.position = "initial";
    }
    h = document.body.scrollHeight;*/
  }

  OpenURL = (url) => {
    this.setState({
        modalTitle: "Warning",
        modalText: "Please note, you are now leaving<br>a Gilead-owned site.",
        modalVisible: true,
        modalUrl: url
    });
    return false;
  }

  render() {
    return (
      <>
        <ConfirmPopup onClose={() => this.setState({ modalVisible: false })} url={this.state.modalUrl} visible={this.state.modalVisible} title={this.state.modalTitle} text={this.state.modalText}/>
        <div className="myFooter" id="myFooter" style={{position: "fixed"}}>
          <div>
            <div>
              {(this.props.code || "")} | November 2023
            </div>
            <div style={{fontSize: "70%", fontWeight: "normal", lineHeight: "11px"}}>
              Reporting suspected adverse reactions of medicinal product is important. It allows continued monitoring of the benefit/risk balance of the medicinal product. Healthcare professionals and patients are asked to report any suspected adverse reactions via the national reporting schemes. For Great Britain & Northern Ireland, reporting forms and information can be found at <span className="a" onClick={() => this.OpenURL("http://www.mhra.gov.uk/yellowcard")}>www.mhra.gov.uk/yellowcard/</span> or via the Yellow Card app (download from the Apple App Store or Google Play Store). Adverse reactions may also be reported directly to Gilead. Adverse reactions related to Gilead products may be reported directly to Gilead via details below:<br/>
              Gilead: <a href="tel:01223897555">01223 897 555</a> or by email to <a href="mailto:ukmedinfo@gilead.com">ukmedinfo@gilead.com</a><br/>
              National reporting line: Yellow Card Scheme at Website: <span className="a" onClick={() => this.OpenURL("http://www.mhra.gov.uk/yellowcard")}>www.mhra.gov.uk/yellowcard</span>
            </div>
          </div>
          <div style={{display:"flex", textAlign: "center", flexDirection: "column"}}>
            <div className="footerImage" style={{position: "relative", bottom: "0", display:"flex"}}>
              <span className="a" onClick={() => this.OpenURL("https://gilead.co.uk")}>
                <img className="footerImageImg" src={axios.defaults.baseURL + "/documents/gileadLogo.png"} alt=""/>
              </span>
            </div>
            This non-promotional website is created and funded by Gilead Sciences<br/>
            <div style={{fontSize: "85%", fontWeight: "normal", lineHeight: "25px"}}>
              <a class="ot-sdk-show-settings">Manage Cookie Settings</a>
            </div>
          </div>
          <div style={{textAlign: "right"}}>
            <Link to={"/terms"} style={{textDecoration: 'none', color: "white"}}>Terms of Use</Link> | <Link to={"/privacy"} style={{textDecoration: 'none', color: "white"}}>Privacy Policy</Link> | <Link to={"/cookies"} style={{textDecoration: 'none', color: "white"}}>Cookie Statement</Link><br/>
          </div>
          {/*  */}
        </div>
      </>
    )
  }
}

export default Footer

/*<div className="footerText primary-color">
          <a onClick={this.userActionMethod} href="https://global.vrtx.com/international-privacy" target="_blank" className="primary-color">Privacy Policy</a> | <Link to={"/cookies"} className="primary-color">Cookies Policy</Link><br/>
          <b>EXCEED is an educational programme funded by Vertex Pharmaceuticals (Europe) Ltd.</b><br/>
          BH-20-2100008 | October 2021
        </div>
*/