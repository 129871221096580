import React, { Component } from 'react'
import './PasswordInput.scss';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'

import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

class PasswordInput extends Component {
  state = {
    value: "",
    showPassword: false,
  }

  componentDidMount() {
    
  }

  handleClickShowPassword = () => {
    //setValues({ ...values, showPassword: !values.showPassword });
    this.setState({value: this.state.value});
    this.setState({showPassword: !this.state.showPassword });
  };

  handleChange(event) {
    let value = event.target.value;
    this.setState({value: value});
    this.props.onChange(value);
  }

  render() {
    return (
      <div>
        <div style={{fontSize: "16px"}}>
          Password*
        </div>
        <Input className={"textInput"} type={this.state.showPassword ? "text" : "password"} disableUnderline={true} style={{paddingRight: "0px"}}
          onChange={this.handleChange.bind(this)}
          value={this.state.value}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={this.handleClickShowPassword} style={{outline: "none"}}>
                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }/>
      </div>
    )
  }
}

/*<div>
        <div style={{fontSize: "16px"}}>
          Password*
        </div>
        <input className={"textInput"} type="password" value={this.state.value} onChange={this.handleChange.bind(this)} style={{width: "100%"}}/>
      </div>*/

export default PasswordInput
