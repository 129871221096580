import React, { Component } from 'react'
import './Home.scss';
import styles from './ContentListPage.scss';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'

import axios from 'axios';

//import EducationalContent from './EducationalContent.js'
import Banner from './Banner';
import user_activity from './UserAction.js';
import { Link } from 'react-router-dom';

class ContactUs extends Component {
  state = {
    data: [],
    search: {
      freeText: ""
    },
    tab: "all_specialties",
    orderBy: ""
  }

  componentDidMount() {
    user_activity.Save("Contact Us", "Visited");
  }

  

  render() {
    return (
      <div style={{backgroundColor: "white"}}>
        <Banner/>
        <div className='title primary-color' style={{fontSize: "22px", fontWeight: "bold", marginLeft:"50px", marginTop: "20px"}}>
            Contact Us
        </div>
        <div className='tertiary-color' style={{margin:"20px 50px 20px 50px"}}>
            <div style={{marginBottom: "7px"}}>For all enquiries, please contact <a href='mailto:Anti-Stigma@nucleusglobalteams.com'>Anti-Stigma@nucleusglobalteams.com</a>.</div>
            Do you have a project case study or other content you would like to publish on Anti-Stigma?<br/>
            If you would like to submit content, please fill in the form <Link to={"/contentsubmission"} className="tertiary-color" style={{textDecoration: "none", fontWeight: "bold"}}>here</Link>. Submissions will be reviewed by the Anti-Stigma Portal Secretariat and a member of the team will respond to you shortly.
        </div>
      </div>
    )
  }
}

export default ContactUs
