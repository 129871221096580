import React, { Component } from 'react'
import './SortBy.scss';
import { Localize } from './Localization';

import Select from 'react-select';
//import Toggle from 'react-toggle';
//import "react-toggle/style.css";

import user_activity from './UserAction.js';

const optionsEn = [
    { value: 'posted_date', label: 'Date of Post', width: '126px'},
    { value: 'source', label: 'Source', width: '126px' },
    { value: 'content_type_title', label: 'Content Type', width: '126px' }
];
const optionsEnFavorites = [
  { value: 'favorites.datetime', label: 'Added to Favorites', width: '160px' },
  { value: 'posted_date', label: 'Date of Post', width: '126px'},
  { value: 'source', label: 'Source', width: '126px' },
  { value: 'content_type_title', label: 'Content Type', width: '126px' }
];

const optionsGr = [
    { value: '', label: 'Ημ. Εκδήλωσης', width: '137px'},
    { value: 'speaker', label: 'Ομιλητή/Συντάκτη', width: '158px' },
    { value: 'views', label: 'Προβολές', width: '96px' },
    { value: 'likes', label: 'Likes', width: '64px' },
    { value: 'date_post', label: 'Ημ. Ανάρτησης', width: '134px' }
];

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        background: '#fff',
        border: 'none',
        minHeight: '32px',
        height: '32px',
        boxShadow: state.isFocused ? null : null,
      }),
      menu: (provided, state) => ({
        ...provided,
        width: "150px",
      }),
  
      valueContainer: (provided, state) => ({
        ...provided,
        height: '30px',
        padding: '0'
      }),
  
      input: (provided, state) => ({
        ...provided,
        margin: '0px',
      }),
      indicatorSeparator: state => ({
        display: 'none',
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '30px',
      }),
      dropdownIndicator: (styles) => ({
        ...styles,
        padding: '0px',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#284972'
    }),
    option: (provided, {isSelected}) => ({
        ...provided,
        color: isSelected?'white':'#284972',
        backgroundColor: isSelected?'#e46b23':'inherit',
        "&:hover": {
            color: '#284972',
            backgroundColor: '#f0f4f7'
        },
    }),
}

class SortBy extends Component {
  state = {
    descending: false,
    sortBy: optionsEn[0]
  }

  componentDidMount() {
    if(this.props.default){
      if(this.props.page && this.props.page == "favorites")
        this.setState({sortBy: optionsEnFavorites[0]});
      else
        this.setState({sortBy: optionsEn[0]});
    }
  }

  componentDidUpdate(prevProps) {
    /*optionsEn.forEach(op => {
        if(op.value === this.state.sortBy.value){
            this.setState({sortBy: op});
        }
    })
    if(prevProps.locale !== this.props.locale){
      optionsEn.forEach(op => {
          if(op.value === this.state.sortBy.value){
              this.setState({sortBy: op});
          }
      })
        if(this.props.locale === "en"){
            optionsEn.forEach(op => {
                if(op.value === this.state.sortBy.value){
                    this.setState({sortBy: op});
                }
            })
        }
        else if(this.props.locale === "gr"){
            optionsGr.forEach(op => {
                if(op.value === this.state.sortBy.value){
                    this.setState({sortBy: op});
                }
            })
        }
    }*/
  }

  onChange(e){
    this.setState({sortBy: e});
    this.props.onChange(e);
    console.log(e.value);
  }

  render() {
    return (
      <span style={{whiteSpace: "nowrap", textAlign: "left"}}>
        <span style={{marginRight: "12px"}}>{Localize(this.props.locale, "by")}</span>
        <div className={"select"} style={{width: this.state.sortBy.width, display: "inline-block", marginLeft: "-3px", marginRight: "5px", verticalAlign: "middle", marginTop: "-2px"}}>
            <Select 
                styles={customStyles}
                options={(this.props.page && this.props.page=="favorites")?optionsEnFavorites:optionsEn}
                value={this.state.sortBy}
                isSearchable={false}
                onChange={(e) => this.onChange(e)}
            />
        </div>
        {/*<span style={{userSelect: "none", fontWeight: "bold", fontSize: "20px", display: "inline-block", cursor: "pointer", transition: "all 0.3s ease-in-out", transform: this.state.descending?"rotate(180deg)":"rotate(0deg)"}} onClick={() => this.setState({descending: !this.state.descending})}>
            ↑
        </span>*/}
      </span>
    );
  }
}

export default SortBy

/*line 78: sortBy: this.props.locale==="en"?optionsEn[0]:optionsGr[0]
line 121: options={this.props.locale==="en"?optionsEn:optionsGr}
*/