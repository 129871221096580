import React, { Component } from 'react'
//import './Home.scss';
import './MyProfile.scss';
import styles from './ContentListPage.scss';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'

import axios from './myAxios';

//import EducationalContent from './EducationalContent.js'
import Banner from './Banner';
import user_activity from './UserAction.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'

import { UncontrolledDropdown, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Container, Row, Col, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import CountryInput from './RegistrationInput/CountryInput';

import Toggle from 'react-toggle';
import "react-toggle/style.css";

let updated_photo = 0;

class MyProfile extends Component {
  state = {
    countries: {},
    countriesOptions: {
        required: "1",
        min: "1",
        max: "100",
        size: "12"
    },
    has_error_first_name: false,
    has_error_last_name: false,
    has_error_title: false,
    has_error_institution: false,
    has_error_country: false,
    hasError: false,
    visibility: {
        visible: this.props.user.visible
    },
    first_name: {
        value: this.props.user.first_name,
        visible: this.props.user.first_name_visible
    },
    last_name: {
        value: this.props.user.last_name,
        visible: this.props.user.last_name_visible
    },
    email: {
        value: this.props.user.email,
        visible: this.props.user.email_visible
    },
    telephone: {
        value: this.props.user.telephone,
        visible: this.props.user.telephone_visible
    },
    title: {
        value: this.props.user.title,
        visible: this.props.user.title_visible
    },
    institution: {
        value: this.props.user.institution,
        visible: this.props.user.institution_visible
    },
    country: {
        value: {value: this.props.user.country_id, label: this.props.user.country_name},
        visible: this.props.user.country_visible
    },
    photo: {
        name: this.props.user.photo,//?.split("_").slice(2).join("_"),
        value: null,
        visible: this.props.user.photo_visible
    },
    formControls: {
        first_name: {
            rules: {
                required: true,
            },
            id: "title",
            name: "Title",
            value: '',
            type: "text",
            placeholder: '',
            feedback: '',
            validity: true
        }
    },
    successVisible: false,
    pleaseWait: false
  }

  Update(){
      let hasError = false;
      this.setState({
            successVisible: false,
            pleaseWait: true,
            has_error_first_name: false,
            has_error_last_name: false,
            has_error_title: false,
            has_error_institution: false,
            hasError: false,
        });

        if(!this.state.first_name.value || this.state.first_name.value == ""){
            hasError = true;
            this.setState({has_error_first_name: true});
        }
        if(!this.state.last_name.value || this.state.last_name.value == ""){
            hasError = true;
            this.setState({has_error_last_name: true});
        }
        if(!this.state.title.value || this.state.title.value == ""){
            hasError = true;
            this.setState({has_error_title: true});
        }
        if(!this.state.institution.value || this.state.institution.value == ""){
            hasError = true;
            this.setState({has_error_institution: true});
        }

        if(hasError){
            this.setState({successVisible: false, pleaseWait: false, hasError: true});
            return;
        }

      let formData = new FormData();
      let data = {};
      data["First Name"] = this.state.first_name.value;
      data["Last Name"] = this.state.last_name.value;
      data["Email Address"] = this.state.email.value;
      data["Telephone"] = this.state.telephone.value;
      data["Title"] = this.state.title.value;
      data["Institution/Organisation"] = this.state.institution.value;
      data["Country"] = this.state.country.value.value;
      data["Profile Photo"] = this.state.photo?.name ?? "";
      
      data["Visible"] = this.state.visibility.visible?1:0;
      /*if(this.state.first_name.visible) data["Visible"].push("First Name");
      if(this.state.last_name.visible) data["Visible"].push("Last Name");
      if(this.state.email.visible) data["Visible"].push("Email Address");
      if(this.state.telephone.visible) data["Visible"].push("Telephone");
      if(this.state.title.visible) data["Visible"].push("Title");
      if(this.state.institution.visible) data["Visible"].push("Institution/Organisation");
      if(this.state.country.visible) data["Visible"].push("Country");
      if(this.state.photo.visible) data["Visible"].push("Profile Photo");*/

      formData.append("info", JSON.stringify(data));
      formData.append("id", this.props.user.user_id);
      formData.append("updated_photo", updated_photo);
      if(this.state.photo.value)
        formData.append("profile_photo", this.state.photo.value);


        user_activity.Save("My Profile", "Updated Profile");


      axios.post("/admin/api/updateProfile.php", formData)
      .then((response)=> {
        this.setState({successVisible: true, pleaseWait: false, hasError: false});
          let user = {};
            updated_photo = 0;

            user["logged_in"] = "1";

            user["first_name"] = this.state.first_name.value;
            user["last_name"] = this.state.last_name.value;
            user["email"] = this.state.email.value;
            user["telephone"] = this.state.telephone.value;
            user["title"] = this.state.title.value;
            user["institution"] = this.state.institution.value;
            user["country_name"] = this.state.country.value.label;
            user["country_id"] = this.state.country.value.value;
            user["photo"] = this.state.photo.value?(response.data.split("-newphoto-")[1]):this.state.photo.name;

            /*user["first_name_visible"] = this.state.first_name.visible;
            user["last_name_visible"] = this.state.last_name.visible;
            user["email_visible"] = this.state.email.visible;
            user["telephone_visible"] = this.state.telephone.visible;
            user["title_visible"] = this.state.title.visible;
            user["institution_visible"] = this.state.institution.visible;
            user["country_visible"] = this.state.country.visible;
            user["photo_visible"] = this.state.photo.visible;*/

            user["visible"] = this.state.visibility.visible;

            user["user_id"] = this.props.user.user_id;

            console.log(user);
            
        this.props.SetUser(user);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  componentDidMount() {
    user_activity.Save("My Profile", "Visited");
    axios.get(`/admin/api/getRegistrationFields.php`)
      .then(res => {
        this.setState({
          countries: res.data["countries"]
        });
      });
  }

  ChangedPhoto(e) {
    this.setState({
        photo: {
            value: e.target.files[0], name: e.target.files[0].name, 
            visible: this.state.photo.visible
        }
    }); 
    updated_photo = 1;
  }

  render() {
      console.log(this.props.user);
    return (
        <div className="" style={{backgroundColor: "white"}}>
            <Banner/>
            <div className='title primary-color' style={{fontSize: "22px", fontWeight: "bold", marginLeft:"50px", marginTop: "20px"}}>
                My Profile
            </div>
            <div className='tertiary-color' style={{margin:"20px 50px"}}>
                <Form>
                    <Container style={{maxWidth: "550px"}}>
                        <Row>
                            <Col sm="12" style={{marginTop: "30px"}}>
                                Please note that the aim of this platform is to foster collaboration between Anti-Stigma Champions, so please make sure your profile is set to ‘visible’.
                            </Col>
                            <Col sm="12" style={{marginTop: "10px", marginBottom: "30px"}}>
                                <div style={{display: "flex", flexFlow: "row"}}>
                                    <div style={{flex: "0 0 50px", paddingTop: "6px"}}>
                                        <div style={{transform: "scale(0.5)", marginLeft: "-12px"}}>
                                            <Toggle className="myToggle" icons={false} checked={this.state.visibility.visible} onChange={(e) => {this.setState({visibility: {visible: e.target.checked} })} }/>
                                        </div>
                                    </div>
                                    <div>
                                        Turn OFF the switch, if you don’t want your profile to be visible in the Members’ page
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6">
                                <FormGroup>
                                    {/* <div style={{transform: "scale(0.5)", position: "absolute", top: "-2px", right: "4px"}}>
                                        <Toggle className="myToggle" icons={false} checked={this.state.first_name.visible} onChange={(e) => {this.setState({first_name: {value: this.state.first_name.value, visible: e.target.checked} })} }/>
                                    </div> */}
                                    <Label for="first_name">First Name*</Label>
                                    <Input style={{borderColor: this.state.has_error_first_name?"red":""}} value={this.state.first_name.value} onChange={(e) => {this.setState({first_name: {value: e.target.value, visible: this.state.first_name.visible} })}} type="text" name="first_name" id="first_name" placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col sm="6">
                                <FormGroup>
                                    {/* <div style={{transform: "scale(0.5)", position: "absolute", top: "-2px", right: "4px"}}>
                                        <Toggle className="myToggle" icons={false} checked={this.state.last_name.visible} onChange={(e) => {this.setState({last_name: {value: this.state.last_name.value, visible: e.target.checked} })} }/>
                                    </div> */}
                                    <Label for="last_name">Last Name*</Label>
                                    <Input style={{borderColor: this.state.has_error_last_name?"red":""}} value={this.state.last_name.value} onChange={(e) => {this.setState({last_name: {value: e.target.value, visible: this.state.last_name.visible} })}} type="text" name="last_name" id="last_name" placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6">
                                <FormGroup>
                                    {/* <div style={{transform: "scale(0.5)", position: "absolute", top: "-2px", right: "4px"}}>
                                        <Toggle className="myToggle" icons={false} checked={this.state.email.visible} onChange={(e) => {this.setState({email: {value: this.state.email.value, visible: e.target.checked} })} }/>
                                    </div> */}
                                    <Label for="email">Email Address*</Label>
                                    <Input value={this.state.email.value} disabled type="email" name="email" id="email" placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col sm="6">
                                <FormGroup>
                                    {/* <div style={{transform: "scale(0.5)", position: "absolute", top: "-2px", right: "4px"}}>
                                        <Toggle className="myToggle" icons={false} checked={this.state.telephone.visible} onChange={(e) => {this.setState({telephone: {value: this.state.telephone.value, visible: e.target.checked} })} }/>
                                    </div> */}
                                    <Label for="telephone">Telephone</Label>
                                    <Input value={this.state.telephone.value} onChange={(e) => {this.setState({telephone: {value: e.target.value, visible: this.state.telephone.visible} })}} type="text" name="telephone" id="telephone" placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6">
                                <FormGroup>
                                    {/* <div style={{transform: "scale(0.5)", position: "absolute", top: "-2px", right: "4px"}}>
                                        <Toggle className="myToggle" icons={false} checked={this.state.title.visible} onChange={(e) => {this.setState({title: {value: this.state.title.value, visible: e.target.checked} })} }/>
                                    </div> */}
                                    <Label for="title">Title*</Label>
                                    <Input style={{borderColor: this.state.has_error_title?"red":""}} value={this.state.title.value} onChange={(e) => {this.setState({title: {value: e.target.value, visible: this.state.title.visible} })}} type="text" name="title" id="title" placeholder="" />
                                </FormGroup>
                            </Col>
                            <Col sm="6">
                                <FormGroup>
                                    {/* <div style={{transform: "scale(0.5)", position: "absolute", top: "-2px", right: "4px"}}>
                                        <Toggle className="myToggle" icons={false} checked={this.state.institution.visible} onChange={(e) => {this.setState({institution: {value: this.state.institution.value, visible: e.target.checked} })} }/>
                                    </div> */}
                                    <Label for="institution">Institution/Organisation*</Label>
                                    <Input style={{borderColor: this.state.has_error_institution?"red":""}} value={this.state.institution.value} onChange={(e) => {this.setState({institution: {value: e.target.value, visible: this.state.institution.visible} })}} type="text" name="institution" id="institution" placeholder="" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6">
                                {/* <div style={{transform: "scale(0.5)", position: "absolute", top: "-2px", right: "4px", zIndex: 1}}>
                                    <Toggle className="myToggle" icons={false} checked={this.state.country.visible} onChange={(e) => {this.setState({country: {value: this.state.country.value, visible: e.target.checked} })} }/>
                                </div> */}
                                <CountryInput mSize="12" style={{padding: "0px"}} marginBottom={"0.5rem"} fontSize={"16px"} defaultValue={this.state.country.value} onChange={(name, value) => {this.setState({country: {value: {value: value, label: name}, visible: this.state.country.visible} })}} onErrorChange={() => {}} showRed={false} name={"Country"} options={this.state.countriesOptions} countries={this.state.countries} type="text"/>
                            </Col>
                            <Col sm="6">
                                <FormGroup>
                                    {/* <div style={{transform: "scale(0.5)", position: "absolute", top: "-2px", right: "4px"}}>
                                        <Toggle className="myToggle" icons={false} checked={this.state.photo.visible} onChange={(e) => {this.setState({photo: {value: this.state.photo.value, name: this.state.photo.name, visible: e.target.checked} })} }/>
                                    </div> */}
                                    <Label for="photo">Profile Photo</Label>
                                    <Input autoComplete={"off"} value={this.state.photo.value?this.state.photo.name:(this.state.photo?.name?.split("_").slice(2).join("_") ?? "")} style={{cursor: "pointer"}} onClick={() => document.getElementById("photoInput").click()} type="text" name="photo" id="photo" placeholder="" />

                                    <div style={{fontWeight: "bold", pointerEvents: "none", position: "absolute", right: "15px", top: "32px", backgroundColor: "#616161", padding: "7px 14px", color: "white", borderRadius: "0px 0.25rem 0.25rem 0px"}}>
                                        Upload
                                    </div>
                                    <Input id="photoInput" style={{display: "none"}} type="file" onChange={(e) => this.ChangedPhoto(e)} />

                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12" style={{textAlign: "center", marginTop: "30px", marginBottom: "50px"}}>
                                <div onClick={() => this.Update()} style={{fontSize: "24px", padding: "5px 60px", backgroundColor: "#e04f40", color: "white", borderRadius: "5px", display: this.state.pleaseWait?"none":"inline-block", fontWeight: "bold", cursor: "pointer", userSelect: "none"}}>
                                    Update
                                </div>
                                <p style={{textAlign: 'center', fontSize: "24px", display: this.state.pleaseWait?"block":"none"}}>
                                    Please wait...
                                </p>
                                <p style={{textAlign: 'center', color: 'red', fontSize: "16px", display: this.state.hasError?"block":"none"}}>
                                    Please fill in all required fields
                                </p>
                                <p style={{textAlign: 'center', color: 'green', display: this.state.successVisible?"block":"none"}}>
                                    Profile successfully updated!
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </Form>
            </div>
            <div style={{display: "flex", flexFlow: "column"}}>
                
            </div>
        </div>
    )
  }
}

export default MyProfile