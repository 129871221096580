import React, { Component } from 'react'
import './ContentPage.scss';
import { findDOMNode } from 'react-dom'

import axios from './myAxios';

class PollResults extends Component {
  state = {
    results: []
  }

  componentDidMount() {
    axios.get(`/admin/api/getPollResults.php`)
      .then(res => {
        this.setState({results: res.data});
      });
  }

  render() {
    return (
      <div>
        {
          this.state.results.map((row, index) => {
            return <div>{index+1}, {row["answered_on"]}, {row["content"]}, {row["question"]}, {row["voting_type"]}, {row["answer"]}</div>;
          })
        }
      </div>
    )
  }
}

export default PollResults
