import React, { Component } from 'react'
import styles from './Event.module.scss';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'

import axios from 'axios';

import thumbnail from './images/thumbnail.png';

import { Localize } from './Localization';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faThumbsUp } from '@fortawesome/free-solid-svg-icons'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
//import AccessCode from '../Modals/AccessCode/AccessCode';

const monthsEn = {'1':'Jan', '2':'Feb', '3':'Mar', '4':'Apr', '5':'May', '6':'Jun', '7':'Jul', '8':'Aug', '9':'Sep', '10':'Oct', '11':'Nov', '12':'Dec'};
const monthsGr = {'1':'Ιαν', '2':'Φεβ', '3':'Μαρ', '4':'Απρ', '5':'Μαϊ', '6':'Ιουν', '7':'Ιουλ', '8':'Αυγ', '9':'Σεπ', '10':'Οκτ', '11':'Νοε', '12':'Δεκ'};

class Event extends Component {
  state = {
    now: new Date()
  }

  componentDidMount() {
    console.log(this.props.data);
  }

  dateFromString(str) {
    var year = str.split(" ")[0].split("-")[0];
    var month = str.split(" ")[0].split("-")[1];
    var day = str.split(" ")[0].split("-")[2];

    /*var hours = str.split(" ")[1].split(":")[0];
    var minutes = str.split(" ")[1].split(":")[1];
    var seconds = str.split(" ")[1].split(":")[2];*/

    //return new Date(Date.UTC(year, month-1, day, hours, minutes, seconds, 0));
    return new Date(Date.UTC(year, month-1, day));
  }

  formatDate(dateString){
    if(!dateString){
      return "";
    }
    const months = Localize(this.props.locale, "months");
    let dateArr = dateString.split(" ")[0].split("-");
    let date = new Date(dateArr[0], dateArr[1]-1, dateArr[2]);
    //return  months[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear();
    return  dateArr[2] + "/" + dateArr[1] + "/" + dateArr[0];
  }

  formatDates(start, end){
    const months = Localize(this.props.locale, "months");

    let startDate = this.dateFromString(start);
    let endDate = this.dateFromString(end);

    let result = "";

    if(startDate.getDate() === endDate.getDate() && startDate.getMonth() === endDate.getMonth() && startDate.getFullYear() === endDate.getFullYear()){
      result = months[startDate.getMonth()] + " " + startDate.getDate() + ", " + startDate.getFullYear();
    }
    else if(startDate.getDate() !== endDate.getDate() && startDate.getMonth() === endDate.getMonth() && startDate.getFullYear() === endDate.getFullYear()){
      result = months[startDate.getMonth()] + " " + startDate.getDate() + " - " + endDate.getDate() + ", " + startDate.getFullYear();
    }
    else if(startDate.getDate() === endDate.getDate() && startDate.getMonth() !== endDate.getMonth() && startDate.getFullYear() === endDate.getFullYear()){
      result = months[startDate.getMonth()] + " " + startDate.getDate() + " - " + months[endDate.getMonth()] + " " + endDate.getDate() + ", " + startDate.getFullYear();
    }
    else if(startDate.getDate() !== endDate.getDate() && startDate.getMonth() !== endDate.getMonth() && startDate.getFullYear() === endDate.getFullYear()){
      result = months[startDate.getMonth()] + " " + startDate.getDate() + " - " + months[endDate.getMonth()] + " " + endDate.getDate() + ", " + startDate.getFullYear();
    }
    else{
      result = months[startDate.getMonth()] + " " + startDate.getDate() + startDate.getFullYear() + " - " + months[endDate.getMonth()] + " " + endDate.getDate() + ", " + endDate.getFullYear();
    }
    return result;
  }

  padLeft(num){
    if(num<10){
      return "0"+num;
    }
    return num;
  }

  /*formatTime(start, end){
    let startDate = this.dateFromString(start);
    let endDate = this.dateFromString(end);
    
    return [[this.padLeft(startDate.getHours()), this.padLeft(startDate.getMinutes())].join(":"), [this.padLeft(endDate.getHours()), this.padLeft(endDate.getMinutes())].join(":")].join(" - ");
  }*/

  calculateDiff(to){
    let today = new Date();
    today.setHours(to.getHours());
    today.setMinutes(to.getMinutes());
    today.setSeconds(to.getSeconds());
    today.setMilliseconds(0);

    let diffMs = Math.abs(today - this.props.now);

    let diffDays = Math.floor(diffMs/(86400 * 1000));
    diffMs -= diffDays*(86400*1000);
    let diffHrs = Math.floor(diffMs/(60 * 60 * 1000 ));
    diffMs -= diffHrs * (60 * 60 * 1000);
    let diffMins = Math.floor(diffMs/(60 * 1000));

    //let diffDays = (Math.floor(diffMs / 86400000)); // days
    //let diffHrs = (Math.round((diffMs % 86400000) / 3600000)); // hours
    //let diffMins = (Math.round(((diffMs % 86400000) % 3600000) / 60000)); // minutes

    //let diffInMinutes = (Math.floor((diffMs/1000)/60));

    let result = "";
    if(diffHrs > 0){
      result += diffHrs + " " + (diffHrs!==1?Localize(this.props.locale, "hours"):Localize(this.props.locale, "hour"));
      if(diffMins > 0){
        result += ", " + diffMins + " " + (diffMins!==1?Localize(this.props.locale, "minutes"):Localize(this.props.locale, "minute"));
      }
    }
    else{
      if(diffMins === 0){
        diffMins = 1;
      }
      result += diffMins + " " + (diffMins!==1?Localize(this.props.locale, "minutes"):Localize(this.props.locale, "minute"));
    }
    return result;
  }

  convertToHoursMinutes(mins){
    var num = mins;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);

    let result = "";
    if(rhours > 0){
      result += rhours + " " + (rhours!==1?Localize(this.props.locale, "hours"):Localize(this.props.locale, "hour"));
      if(rminutes > 0){
        result += ", " + rminutes + " " + (rminutes!==1?Localize(this.props.locale, "minutes"):Localize(this.props.locale, "minute"));
      }
    }
    else{
      result += rminutes + " " + (rminutes!==1?Localize(this.props.locale, "minutes"):Localize(this.props.locale, "minute"));
    }
    return result;
  }

  renderDates(startDate){
    /*if(this.props.isToday && startDate.getDate() === endDate.getDate() && startDate.getMonth() === endDate.getMonth() && startDate.getFullYear() && endDate.getFullYear()){
      return (<></>);
    }*/
    return (<div className={"dark-blue-color"} style={{fontSize: "13px", lineHeight: "16px", marginBottom: "7px"}}>
      { this.formatDates(this.props.data["creation_date"], this.props.data["creation_date"]) }
    </div>);
  }

  generateDate(){
    let startDate = this.dateFromString(this.props.data["creation_date"]);
    //let endDate = this.dateFromString(this.props.data["creation_date"]);

    if(!this.props.isToday){
      return(
        <div>
          { this.renderDates(startDate) }
        </div>
      );
    }
    const tempDate = new Date(this.props.now.getTime());
    tempDate.setHours(startDate.getHours());
    tempDate.setMinutes(startDate.getMinutes());
    tempDate.setSeconds(startDate.getSeconds());
    tempDate.setMilliseconds(startDate.getMilliseconds());

    return(
      <div>
        { this.renderDates(startDate) }
        <div className={"light-blue-color"} style={{fontSize: "13px", lineHeight: "16px", marginBottom: "7px"}}>
          {Localize(this.props.locale, "starting_in")} {this.calculateDiff(startDate)}
        </div>
      </div>
    );

    /*if(tempDate.getTime() > this.props.now.getTime()){
      return(
        <div>
          { this.renderDates(startDate, endDate) }
          <div className={"light-blue-color"} style={{fontSize: "13px", lineHeight: "16px", marginBottom: "7px"}}>
            {Localize(this.props.locale, "starting_in")} {this.calculateDiff(startDate)}
          </div>
        </div>
      );
    }
    else{
      if(endDate.getTime() < this.props.now.getTime()){
        return(
          <div>
            { this.renderDates(startDate, endDate) }
            <div className={"light-blue-color"} style={{fontSize: "13px", lineHeight: "16px", marginBottom: "7px"}}>
              {Localize(this.props.locale, "ended")} {this.calculateDiff(startDate)} {Localize(this.props.locale, "ago")}
            </div>
          </div>
        );
      }
      else{
        return(
          <div>
            { this.renderDates(startDate, endDate) }
            <div className={"light-blue-color"} style={{fontSize: "13px", lineHeight: "16px", marginBottom: "7px"}}>
              {Localize(this.props.locale, "started")} {this.calculateDiff(startDate)} {Localize(this.props.locale, "ago")}
            </div>
          </div>
        );
      }
    }*/
  }

  renderSpeaker(){
    if(!this.props.data["creator"]){
      return (<></>);
    }
    return (<div className={"dark-blue-color"} style={{fontWeight: "bold", fontSize: "16px", lineHeight: "16px", marginBottom: "7px"}}>
      { this.props.data["creator"] }
    </div>);
  }

  renderDuration1 = () => {
    return(
      Localize(this.props.locale, this.props.data["category_b"]==="Case Study Video"?"duration":"size")
    );
  }

  renderDuration2 = () => {
    return(
      this.props.data["duration_size"]
    );
  }

  render() {
    return (
      <div className={"border-all border-2"} style={{display: "flex", flexFlow: "column", backgroundColor: "white", margin: "10px", width: "300px", minHeight: "300px", padding: "10px", marginBottom: "20px", position: "relative", borderColor: "lightgray"}}>
        <div style={{flex: "0 0 250px"}}>
          <Link to={"../content/"+this.props.data["id"]}>
            <div>
              <img src={ this.props.data["thumbnail"] } alt="" style={{width: "100%", height: "155px", objectFit: "cover"}}/>
            </div>
          </Link>
          <div className={"primary-color " + styles.twoLines} title={this.props.data["content_title"]} style={{marginTop: "20px", marginBottom: "10px", fontWeight: "bold", fontSize: "22px", lineHeight: "23px", width: "100%"}}>
            { this.props.data["content_title"] }
          </div>
          <div style={{position: "absolute", top: "260px"}}>
            <div className={""}>
              <div className={"secondary-color"} style={{fontSize: "13px"}}>
                <b>{ this.renderSpeaker() }</b>
              </div>
              <div className={"tertiary-color"} style={{fontSize: "14px"}}>
                <b>{ this.props.data["category_a"] }</b>
              </div>
              <div className={"tertiary-color"} style={{fontSize: "14px"}}>
                <b>{ this.props.data["category_b"] }</b>
              </div>
              <div className={"tertiary-color"} style={{fontSize: "14px"}}>
                <b>{ this.props.data["source"] }</b>
              </div>
            </div>
          </div>
        </div>
        <div style={{flex: "1 1 auto", position: "relative", marginTop: "95px", marginBottom: "20px"}}>
          <div className={""} style={{color: "#159871", fontSize: "13px", fontWeight: "bold", display: "flex"}}>
            <table>
              <tr>
                <td style={{paddingRight: "5px"}}>
                  {this.renderDuration1()}
                </td>
                <td>
                  <span style={{marginRight: "5px"}}>:</span>{this.renderDuration2()}
                </td>
              </tr>

              <tr>
                <td style={{paddingRight: "5px"}}>
                  Created
                </td>
                <td>
                  <span style={{marginRight: "5px"}}>:</span>{this.formatDate(this.props.data["creation_date"])}
                </td>
              </tr>

              <tr>
                <td style={{paddingRight: "5px"}}>
                  Posted
                </td>
                <td>
                  <span style={{marginRight: "5px"}}>:</span>{this.formatDate(this.props.data["posted_date"])}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div style={{flex: "0 0 34px", margin: "0 auto"}}>
          <Link to={"../content/"+this.props.data["id"]}>
            <div className={"background-primary primary-font"} style={{fontSize: "13px", fontWeight: "bold", display: "inline-block", color: "white", padding: "5px 45px", borderRadius: "4px"}}>
              VIEW
            </div>
          </Link>
        </div>
      </div>
    )
  }
}

export default Event

/*<AccessCode search={this.props.search} tab={this.props.tab} to={"/" + this.props.locale + "/channel/"+(this.props.isOnDemand?"ondemand":"live")+"/"+this.props.data["id"]} event_id={this.props.data["id"]} title={ this.props.isToday?Localize(this.props.locale, "join_live"):Localize(this.props.locale, "get_access") } type={this.props.isOnDemand?"ondemand":"live"} locale={this.props.locale} />
line 256 { this.formatTime(this.props.data["creation_date"], this.props.data["creation_date"])}

line 281 <div className={""} style={{fontSize: "13px", lineHeight: "16px", marginBottom: "7px"}}>
              { this.props.data["event_kind_name_raw"] } | { this.props.data["event_type_name_raw"] }
            </div>
            <div className={""} style={{fontSize: "13px", lineHeight: "16px", marginBottom: "7px"}}>
              { this.props.data["city_name_raw"] }, { this.props.data["country_name_raw"] }
            </div>

line 257  <div>
            <div className={"tertiary-color"} title={"Views " + this.props.data["ondemand_views"]} style={{marginLeft: "7px", cursor: "default", userSelect: "none", display: this.props.isOnDemand?"block":"none", marginTop: "5px", fontSize: "12px", float: "left"}}>
              <FontAwesomeIcon icon={faEye}/> {this.props.data["ondemand_views"]}
            </div>
            <div className={"tertiary-color"} title={"Likes " + this.props.data["ondemand_likes"]} style={{marginRight: "7px", cursor: "default", userSelect: "none", display: this.props.isOnDemand?"block":"none", marginTop: "5px", fontSize: "12px", float: "right"}}>
              <FontAwesomeIcon icon={faThumbsUp}/> {this.props.data["ondemand_likes"]}
            </div>
            <div className={"tertiary-color"} style={{display: this.props.isOnDemand?"none":"block", marginTop: "5px", fontSize: "12px", float: "right"}}>
              { //this.props.data["creation_date"]
              }
            </div>
          </div>

line 245      {this.convertToHoursMinutes(duration)}

line 256 this.props.data["banner"] || thumbnail
*/