import React, { Component } from 'react';
import { customStyles } from './ContentTableStyles';
import styles from './ContentTable.module.scss';
import { orderBy, differenceBy } from 'lodash';
import { contentAPI } from '../../../../services/content';
import { withRouter } from 'react-router-dom';
import produce from 'immer';
//import Card from '@material-ui/core/Card';
// import Button from '@material-ui/core/Button';
import { InputGroup,
    InputGroupAddon,
    InputGroupButtonDropdown,
    Input, Row, Col, Button, InputGroupText
} from 'reactstrap';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Delete from '@material-ui/icons/Delete';
//import Add from '@material-ui/icons/Add';
import memoize from 'memoize-one';
import DataTable from 'react-data-table-component';
import { colors } from '../../../../styles/_colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTimesCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
//import { StylesProvider } from '@material-ui/core';

const sortIcon = <ArrowDownward />;
const contextActions = memoize(deleteHandler => (
    <IconButton color="secondary" onClick={deleteHandler} >
        <Delete />
    </IconButton>
));

const getSubHeaderComponent = memoize((onFilterHandler, onClearFilterHandler, filterText, redirectToNewContent) => (
    <FilterComponent
        onFilterHandler={(e)=>onFilterHandler(e.target.value)}
        onClearFilterHandler={onClearFilterHandler}
        filterText={filterText}
        redirectToNewContent={redirectToNewContent}
    />
))

const renderList = (list) => {

    return (
        <ul className="pl-3 pt-2 pb-2 m-0">
            {
                list.map((item, i) => <li key={i}>{item}</li>)
            }
        </ul> 
    );
}

const columns = memoize((editHandler, deleteHandler) => [    
    {
        name: 'Content Title',
        selector: 'content_title',
        sortable: true,
        wrap: true,
        minWidth: "180px"
    },
    /*{
        name: 'Type',
        selector: 'type',
        sortable: true,
        // right: true,
        width: "75px"
    },*/
    {
        name: 'Category',
        selector: 'category_b',
        sortable: true,
        wrap: true,
        width: "120px"
    },
    {
        name: 'Duration/ Size',
        selector: 'duration_size',
        sortable: true,
        width: "110px"
    },
    {
        name: 'Source',
        selector: 'source',
        sortable: true,
        wrap: true,
        width: "120px"
    },
    {
        name: 'Created On',
        selector: 'creation_date',
        sortable: true,
        width: "120px"
    },
    {
        name: 'Created By',
        selector: 'creator',
        sortable: true,
        minWidth: "160px",
        maxWidth: "250px",
        cell: row => renderList(row["creator"])
    }/*,
    {
        name: 'Contains Voting',
        selector: 'contains_voting',
        sortable: true,
        center: true,
        width: "100px"
    },
    {
        name: 'Interactive Features',
        selector: 'interactive_features',
        // sortable: true,
        minWidth: "160px",
        maxWidth: "250px",
        cell: row => renderList(row["interactive_features"])
    }*/,
    {
        button: true,
        style: {
            fontSize: "16px"
        },
        cell: row => (
            <span>
                <FontAwesomeIcon className="pointer" onClick={()=>editHandler(row)} color="#fff" style={{backgroundColor: colors.editIcon, padding:"1px", marginRight: "0.75vw"}} icon={faEdit} />
                <FontAwesomeIcon className="pointer" onClick={()=>deleteHandler(row)} color={colors.deleteIcon} icon={faTimesCircle} />
            </span>
        )
    },
]);


const data = [
    { id:"1", content_title: "XXX", type: 'Video', duration_size: '01:30:00 h', creation_date: "29/12/2020", timestamp: 1229, creator: ["John Doe", "George Miller"],  contains_voting: "YES", interactive_features: ["Ask questionsdkskdkskdkskdkskdkskdks"]},
    { id:"2", content_title: "ABC", type: 'PDF', duration_size: '23 pages', creation_date: "27/12/2020", timestamp: 1227, creator: ["John Doe"],  contains_voting: "YES", interactive_features: ["Ask question", "ask question 2dkkdwkdwkkdwkdkwkdkwkdwkdkwkkdwkd"]},         
    { id:"3", content_title: "AAC", type: 'PPT', duration_size: '51 slides', creation_date: "23/12/2020", timestamp: 1223, creator: ["George Milner"],  contains_voting: "NO", interactive_features: ["Ask question", "ask question 2"]},         
    { id:"4", content_title: "XAsdsdsdsdsdsdsdsdsdsdsdsdsdsdsdsdsdsdsdA", type: 'Video', duration_size: '01:30:00 h', creation_date: "20/12/2020", timestamp: 1220, creator: ["John Pap", "Max Peilin", "nick castro", "kwnstantinos Papastathopoulos"],  contains_voting: "YES", interactive_features: ["Ask question"]},         
    { id:"5", content_title: "AXX", type: 'PPT', duration_size: '12 slides', creation_date: "30/11/2020", timestamp: 1130, creator: ["Alex Kalog"],  contains_voting: "YES", interactive_features: ["Ask question"]},         
    { id:"6", content_title: "BBA", type: 'Video', duration_size: '01:30:00 h', creation_date: "19/9/2020", timestamp: 2, creator: ["John Doe", "Jim Paulson"],  contains_voting: "NO", interactive_features: ["Ask question", "ask question 2"]},         
    { id:"7", content_title: "BOB", type: 'Video', duration_size: '01:30:00 h', creation_date: "2/7/2020", timestamp: 1, creator: ["John Doe"],  contains_voting: "YES", interactive_features: ["Ask question"]},         
];

const FilterComponent = ({ filterText, onFilterHandler, onClearFilterHandler, redirectToNewContent }) => (
    <Row className='w-100 m-0 justify-content-between'>
        <Col sm="auto" className='p-0'>
            <Button size="m" className={"custom-btn bg-primary-color h-100"} onClick={redirectToNewContent}>
                <FontAwesomeIcon size="sm" icon={faPlus} className='mr-2' />
                New
            </Button>
        </Col>

        <Col xs="6" md="auto" lg="3" className='p-0' style={{minWidth: "300px"}}>
            <InputGroup size="sm h-100">
                <Input style={{fontSize: "18px"}} className="h-100 border-secondary-color" type="text" placeholder="Filter data" value={filterText} onChange={onFilterHandler} />
                <InputGroupAddon addonType="append" onClick={onClearFilterHandler} className="pointer">
                    <InputGroupText className="border-secondary-color">
                        X
                    </InputGroupText>
                </InputGroupAddon>
            </InputGroup>
        </Col>
    </Row>
);
    
const getFilterItems = (data, filterText) => {
    const lowerFilterText = filterText.toLowerCase();
    return data.filter(item => {
        return (
            item['content_title'].toLowerCase().includes(lowerFilterText) ||
            //item['type'].toLowerCase().includes(lowerFilterText) ||
            item['category_b'].toLowerCase().includes(lowerFilterText) ||
            item['duration_size'].toLowerCase().includes(lowerFilterText) ||
            item['source'].toLowerCase().includes(lowerFilterText) ||
            item['creation_date'].toLowerCase().includes(lowerFilterText) ||
            item['creator'].join(' ').toLowerCase().includes(lowerFilterText) 
            //item['contains_voting'].toLowerCase().includes(lowerFilterText) ||
            //item['interactive_features'].join(' ').toLowerCase().includes(lowerFilterText)
        );
    });
}

class ContentTable extends Component {

    state = { 
        selectedRows: [], 
        toggleCleared: false, 
        data: [],
        filterText: "",
        resetPaginationToggle: false,
    };
    // filteredItems = getFilterItems(this.state.data, "content_title", this.state.filterText);

    handleChange = state => {
        this.setState({ selectedRows: state.selectedRows });
    }

    handleRowClicked = row => {
        // console.log(`${row.id} was clicked!`);
        // alert(`${row.id} was clicked!`);
        // this.props.history.push("/content/" + row.id);
    }

    handleClearFilter = () => {
        const { resetPaginationToggle, filterText } = this.state;

        if (filterText) {
            this.setState({
                resetPaginationToggle: !resetPaginationToggle,
                filterText: ""
            });
            // this.filteredItems = getFilterItems(this.state.data, "content_title", "");

        }
    };

    handleFilter = (newFilterText) => {
        // console.log("newFilterText", newFilterText)
        // console.log("filteredItems prin", this.filteredItems )
        // this.filteredItems = getFilterItems(this.state.data, "content_title", newFilterText);
        // console.log("filteredItems", this.filteredItems )
        this.setState({ filterText: newFilterText });
    }

    handleSort = (rows, selector, sortDirection) => {
        return orderBy(rows, selector, sortDirection);
    }

    deleteSingleRow = row => {

        console.log("deleting row", row);    
        if (window.confirm(`Are you sure you want to delete:\r "${row.content_title}" ?`)) {
            const { data } = this.state;
            const index = data.findIndex(r => r.id === row.id);

            const reqParams = {
                contentIds: [data[index].id]
            };
            console.log("reqParams", reqParams)
            contentAPI.deleteContents(reqParams).then(res => {
                if (!res || !res.success) {
                    return;
                }
                this.setState(state => ({
                    toggleCleared: !state.toggleCleared,
                    data: [...state.data.slice(0, index), ...state.data.slice(index + 1)],
                }));
            });
        }
    }

    deleteMultipleRows = () => {
        const { selectedRows } = this.state;
        const rowsContentTitles = selectedRows.map(r => `"${r.content_title}"`).join(", ");
        if (window.confirm(`Are you sure you want to delete:\r ${rowsContentTitles} ?`)) {

            const reqParams = {
                contentIds: selectedRows.map(r => r.id)
            };
            console.log("reqParams", reqParams)
            contentAPI.deleteContents(reqParams).then(res => {
                if (!res || !res.success) {
                    return;
                }
                
                this.setState(state => ({ 
                    toggleCleared: !state.toggleCleared, 
                    data: differenceBy(state.data, state.selectedRows, 'id') 
                }));
            });
        }
    }

    editHandler = row => {
        // alert(`Going to edit row with id ${row.id} and title ${row.content_title}`)
        this.props.history.push(this.props.location.pathname + "/" + row.id);
    }

    redirectToNewContent = () => {
        this.props.history.push(this.props.location.pathname + "/new");
    }
  
    render() {
        console.log("render-state:", this.state)

        const filteredData = getFilterItems(this.state.data, this.state.filterText);
        console.log("filtered: ", filteredData)
        const { data, toggleCleared } = this.state;

        customStyles.headRow.style.backgroundColor = this.props.styling["primaryColor"];
        return (
            <DataTable
                // title="Arnold Movies"

                customStyles={customStyles}
                defaultSortField="creation_date"
                columns={columns(this.editHandler, this.deleteSingleRow)}   
                contextActions={contextActions(this.deleteMultipleRows)}
                    
                // data={this.filteredItems}
                data={filteredData}
                clearSelectedRows={toggleCleared}
                
                selectableRowsComponent={Checkbox}
                sortIcon={sortIcon}

                onSelectedRowsChange={this.handleChange}
                onRowClicked={this.handleRowClicked}
                // onSort={this.handleSort}
                sortFunction={this.handleSort}
                paginationResetDefaultPage={this.state.resetPaginationToggle}
                
                selectableRows
                highlightOnHover
                // pointerOnHover
                pagination
                persistTableHead
                subHeader
                // fixedHeader
                // fixedHeaderScrollHeight="100vh"
                
                subHeaderComponent={getSubHeaderComponent(this.handleFilter, this.handleClearFilter, this.state.filterText, this.redirectToNewContent)}
                // paginationPerPage={5}
            />
        )
    }


    componentDidMount() {
        console.log("ContentTable did mount")
        contentAPI.getContents()
            .then(data => {
                console.log("data", data);
                if (!data) {
                    return;
                }
                this.setState(
                    produce(draft => {
                        draft.data = data;
                    })
                );
            })
    }

}




export default withRouter(ContentTable);