import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import styles from './Banner.scss';
/*import mobile_landscape from '../../images/banners/mobile_landscape.jpg';
import mobile_portrait from '../../images/banners/mobile_portrait.jpg';
import tablet_landscape from '../../images/banners/tablet_landscape.jpg';
import tablet_portrait from '../../images/banners/tablet_portrait.jpg';*/
//import web from './images/banner1.png';
import web from './images/bannerNew2.jpg';
import mobile from './images/bannerMobile.png';

class Banner extends Component {

  state = {
    src: web
  }

  componentDidMount() {
    window.onresize = ()=>{
      this.Resized();
    };
    this.Resized();
  }

  Resized(){
    //Portrait
    if (window.innerWidth <= 500 && window.innerHeight > 500) {
      this.setState({src: mobile});
    } 
    else if (window.innerWidth > 500 && window.innerHeight <= 500) {
      this.setState({src: mobile});
    } 
    else if (window.innerWidth <= 1024 && window.innerHeight > 1024) {
      this.setState({src: web}); //tablet
    }
    else if (window.innerWidth > 1024 && window.innerWidth < 1400 && window.innerHeight <= 1024) {
      this.setState({src: web}); //tablet
    }
    else{
      this.setState({src: web});
    }
  }

  render() {
    return (
      <div className={styles.banner}>
        <Link to={"/home"}>
          <img id="banner-img" alt="Banner" src={this.state.src} style={{width: '100%', height: 'auto'}}/>
        </Link>
      </div>
    )
  }
}

export default Banner
 