import React from 'react';
import { Label, Input, FormGroup, Col } from 'reactstrap';
import styles from './MyInput.module.scss';


const myInput = ( props ) => {
    console.log(props)
	
    return (
        <FormGroup row className="m-0 mb-4 align-items-center">
            <Label xs="12" sm="4" md="3" lg="2" for={props.formElem.id} className={styles["label"] + " secondary-color"}>{props.formElem.name}</Label>
            <Col xs="12" sm="8" md="8" lg="6" className="p-0 pl-1">
                {
                    !props.formElem.readOnly ? 
                    <Input 
                        // onBlur={props.blurred} 
                        className={styles["input"] + " " + (props.formElem.validity ? "border-secondary-color" : styles["input-invalid"])} 
                        onChange={props.changed} 
                        value={props.formElem.value}  
                        type={props.formElem.type} 
                        id={props.formElem.id} 
                        placeholder={props.formElem.placeholder} 
                    /> :
                    <Input readOnly
                        className={styles["input"] + " border-secondary-color"} 
                        value={props.formElem.value}  
                        type={props.formElem.type} 
                        id={props.formElem.id} 
                        placeholder={props.formElem.placeholder} 
				    />
                }
            </Col>
            {/* <Col sm={props.feedbackWidth} className="pl-4 font-weight-bold text-danger font-italic">
                { props.validity === "is-invalid" ? props.feedback : null }
            </Col> */}
        </FormGroup>
    );

};


// const arePropsEqual = (prevProps, nextProps) =>  prevProps.formElem.value === nextProps.formElem.value; 
// export default React.memo(myInput, arePropsEqual);
// line 11 style={{justifyContent: "right", display: "flex"}}
export default React.memo(myInput);