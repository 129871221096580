import React, { Component } from 'react'
import './SpeakerBio.scss';
import { findDOMNode } from 'react-dom'

import { Container, Row, Col, Collapse, CardBody, Card } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import axios from './myAxios';

import UserAction from './UserAction';

class SpeakerBio extends Component {
  state = {
    isOpen: false,
    item: ""
  }

  toggleCollapse(){
    this.setState({ isOpen: !this.state.isOpen });
    if(!this.state.isOpen){
      UserAction.Save("Presenter "+this.state.item["last_name"], "Clicked");
    }
  }

  renderPresenter(){
    let result = [];
    let item = this.props.creator;
    this.state.item = item;
    result.push(<div className={""}>
                  <hr className={"border-top border-1 border-primary"}/>
                    <div onClick={this.toggleCollapse.bind(this)} className={"primary-color"} style={{fontWeight: "bold", position: "relative", fontSize: "18px", cursor: "pointer"}}>
                      <img src={item["photo"]} style={{width: "50px", height: "50px", objectFit: "cover", borderRadius: "5px", marginRight: "10px"}}/>
                      {item["prefix"]} {item["first_name"]} {item["last_name"]}
                      <div style={{position: "absolute", right: "10px", top: "10px"}}>
                        { this.state.isOpen?"-":"+" }
                      </div>
                    </div>
                    <div className={"primary-color"} style={{whiteSpace: "pre-line"}}>
                      <Collapse isOpen={this.state.isOpen}>
                        <br/>
                        {item["short_bio"]}
                      </Collapse>
                    </div>
                  <hr className={"border-top border-1 border-primary"}/>
                </div>);
    return result;
  }

  componentDidMount() {
  }

  render() {
    return (
      <div style={{width: "100%"}}>
        <div style={{marginTop: "20px"}}>
          { this.renderPresenter() }
        </div>
      </div>
    )
  }
}

export default SpeakerBio
