import React, { Component } from 'react'
import './Home.scss';
import styles from './PrivacyPolicy.scss';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'

import axios from 'axios';

//import EducationalContent from './EducationalContent.js'
import Banner from './Banner';
import user_activity from './UserAction.js';

class PrivacyPolicy extends Component {
  state = {
    data: [],
    search: {
      freeText: ""
    },
    tab: "all_specialties",
    orderBy: ""
  }

  componentDidMount() {
    
  }

  render() {
    return (
      <div className="list" style={{backgroundColor: "white"}}>
        <Banner/>
        <div className='title primary-color' style={{fontSize: "22px", fontWeight: "bold", marginLeft:"50px", marginTop: "20px"}}>
            Privacy Policy
        </div>
        <div className='tertiary-color' style={{padding: "20px 50px 50px 50px"}}>
            <p>
                <b>Gilead Sciences Europe Ltd</b> (together with its affiliates) collects and processes personal data submitted by you, such as your name and email address as part of your registration for the <b>Anti-Stigma platform (“Platform”),</b> which is a platform aimed at healthcare professionals, patient advisory groups and non-governmental organisations representatives, who are working towards ending stigma and discrimination in the community and healthcare settings. The platform allows users to connect and share best practice approaches and initiatives to continue the fight against stigma throughout the community.
            </p>
            <ol>
                <li>
                    We use the personal data submitted by you at the time of registration in order to grant you access to the Platform and to inform you about updates and new content on the Platform.
                </li>
                <li>
                    We may share your personal data with members of the Gilead group of companies and our vendors and
                    suppliers (who are bound by obligations of confidentiality), who are appointed to facilitate and administer
                    your use of the Platform. We may also share your personal data with regulatory bodies, where required, in
                    order for us to fulfil our regulatory obligations, for example in connection with reporting transfers of value.
                </li>
                <li>
                    When you use the Platform, we will process information relating to your use of the Platform. We process
                    your usage data for the purpose of facilitating, administering and analysing your use of the Platform.
                    We will process information submitted by users such as case studies, slide decks, and other educational
                    materials in order to assess its suitability for publication on the Platform.
                </li>
                <li>
                    We may aggregate your personal data with other individuals’ personal data for data analysis, or to
                    generate statistics for our legitimate business interest in gaining insights. No individual will be identified
                    or identifiable from the aggregated data.
                </li>
                <li>
                    We may use the Platform to host webinars and other virtual events, which may be recorded.
                    The recording may capture event attendees, including questions or comments to the speakers.
                    We may use the recording (or parts of it) or photographs/stills from the recording for the purpose of
                    training,education and awareness in the following places:
                    <ul>
                        <li>
                            internal training and education of Gilead staff;
                        </li>
                        <li>
                            external training and education of healthcare professionals
                            and other carefully selectedgroups or individuals; or
                        </li>
                        <li>
                            external patient and community health awareness and patient support activities;
                        </li>
                    </ul>
                    We may also use the recording (or parts of it) or photographs/stills from the recording for the purpose of
                    sharing knowledge and insights more widely in the following places:
                    <ul>
                        <li>
                            Our websites, including Gilead.com;
                        </li>
                        <li>
                            Gilead-controlled social media channels (such as Twitter, Facebook, LinkedIn, YouTube); or
                        </li>
                        <li>
                            Journals, magazines and other news media.
                        </li>
                    </ul>
                </li>
                <li>
                    Subject to applicable law and the purpose for which we collect your personal data, we rely on one or more
                    of the following legal grounds to process your personal data, namely: the performance of a contract, our
                    legitimate interests and compliance with law or regulations.
                </li>
                <li>
                    We will retain your personal data for no longer than is necessary for the purposes set out in this Privacy
                    Notice, and to comply with our financial reporting and legal obligations or pharmaceutical industry requirements
                    on transparency, after which it will be deleted.
                </li>
                <li>
                    We will ensure that the personal data that we hold is subject to appropriate security measures. We maintain
                    servers and other storage facilities in the United States, EU and Asia. Where we transfer your personal data
                    outside the EU, we will ensure that it is protected and transferred in a manner consistent with applicable data
                    protection law.
                </li>
                <li>
                    You have various rights in relation to the personal data we hold about you, such as information about our
                    processing of your personal data and access to personal data we hold on you, and to have your personal
                    data corrected or erased. You can exercise your rights by contacting us using the details below. Please note
                    that rights may be limited by law in some circumstances.
                </li>
                <li>
                    For further information about how we handle personal data, please visit <a href="https://www.gilead.com/privacy-statements" target="_blank">https://www.gilead.com/privacy-statements</a>
                </li>
            </ol>
            You can also contact us (including to exercise your rights) at <a href="mailto:Privacy@gilead.com">Privacy@gilead.com</a>
        </div>
    </div>
    )
  }
}

export default PrivacyPolicy