import React, { Component } from 'react'
import './Home.scss';
import styles from './ContentListPage.scss';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'
import avatar from './images/default_avatar.jpg'

import axios from './myAxios';

//import EducationalContent from './EducationalContent.js'
import Banner from './Banner';
import user_activity from './UserAction.js';

const MemberItem = ({ item }) => {
    if(!item.first_name && !item.last_name && !item.title && !item.institution && !item.country && !item.email && !item.telephone && !item.website){
        return <></>;
    }
    return (<div style={{width: "130px", textAlign: "center"}}>
        <img style={{width: "130px", height: "130px", objectFit: "cover", backgroundColor: "#e04f40"}} src={axios.defaults.baseURL + "/admin/profile_photos/" + item.img} onError={(e) => e.target.src = avatar } alt="avatar"/>
        <div style={{width: "130px", margin: "0 auto", textAlign: "left"}}>
            <div style={{fontWeight: "bold", marginTop: "10px"}}>
                {item.first_name} {item.last_name}
            </div>
            <div style={{fontSize: "80%"}}>
                <div style={{color: "#1b256d"}}>
                    {item.title}
                </div>
                <div className="tertiary-color">
                    {item.institution}
                </div>
                <div className="tertiary-color">
                    {item.country}
                </div>
                { item.email && <div>
                    <a href={"mailto:"+item.email} style={{color: "#1da175"}}>{item.email}</a>
                </div> }
                { item.telephone && <div>
                    <a href={"tel:"+item.telephone} style={{color: "#1da175"}}>{item.telephone}</a>
                </div> }
                { item.website && <div>
                    <a href={item.website} style={{color: "#1da175"}} target="_blank" rel="noreferrer">{item.website}</a>
                </div> }
            </div>
        </div>
    </div>
    );
};

class Members extends Component {
  state = {
    members: []
  }

  componentDidMount() {
    user_activity.Save("Members", "Visited");
    axios.get(`/admin/api/getMembers.php`)
    .then(res => {
      this.setState({members: res.data});
      console.log(res.data);
    });
  }

  render() {
      let length = 0;
      this.state.members.forEach(item => {
        if(!item.first_name && !item.last_name && !item.title && !item.institution && !item.country && !item.email && !item.telephone && !item.website){
            return;
        }
        length++;
    });
    return (
        <div className="" style={{backgroundColor: "white"}}>
            <Banner/>
            <div className='title primary-color' style={{fontSize: "22px", fontWeight: "bold", marginLeft:"50px", marginTop: "20px"}}>
                <span className="secondary-font">ANTI-STIGMA</span> Members
            </div>
            <div className="membersGrid" style={{padding: "45px 50px", display: "flex", flexFlow: "row wrap", rowGap: "50px"}}>
                {
                    this.state.members.map(item => {
                        return <MemberItem item={item}/>;
                    })
                }
            </div>
        </div>
    )
  }
}

export default Members