import React, { Component } from 'react'
import styles from './AdvancedSearch.module.scss';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'

import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { Localize } from './Localization';

class AdvancedSearch extends Component {
  state = {
    freeText: "",
    prevFreeText: "",
    isOpen: false
  }

  componentDidMount() {
    
  }

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.setState({freeText: ""});
    }
  }

  onSearch(){
    let search = {freeText: this.state.freeText};

    //this.setState({isOpen: false});
    this.props.onSearch(search);
    console.log(this.state.freeText);
    if(!this.state.freeText){
      this.setState({isOpen: false});
    }
    this.setState({prevFreeText: this.state.freeText});
  }

  Clear(){
    let search = {freeText: ""};

    //this.setState({isOpen: false});
    this.props.onSearch(search);
    this.setState({isOpen: false});
    this.setState({freeText: "", prevFreeText: ""});
  }

  Open(){
    this.setState({isOpen: true});
  }

  Close(){
    if(!this.state.freeText && this.state.prevFreeText){
      this.onSearch();
    }
    else if(!this.state.freeText && !this.state.prevFreeText){
      this.setState({isOpen: false});
    }
    else if(this.state.freeText){
      this.Clear();
    }
  }

  render() {
    return (
      <div style={{fontSize: "18px", transition: "all 0.3s ease-in-out", paddingBottom: this.state.isOpen?"60px":"13px"}}>
        <span style={{padding: "10px", marginRight: "-20px", cursor: "pointer", pointerEvents: this.state.isOpen?"none":"all"}}  onClick={() => this.Open()}>
          <FontAwesomeIcon icon={faSearch} style={{transform: this.state.isOpen?"rotate(180deg) scale(0.5)":"rotate(0deg) scale(1)", transition: "all 0.3s ease-in-out", opacity: this.state.isOpen?0:0.95}}/>
        </span>
        <input style={{
          position: "absolute",
          pointerEvents: this.state.isOpen?"all":"none",
          transition: "all 0.3s ease-in-out",
          backgroundColor: "#fbfbfb",
          left: 0,
          opacity: this.state.isOpen?0.95:0,
          right: 0,
          width: "100%",
          border: "2px solid #dcdcdc",
          fontSize: "13px",
          padding: this.state.isOpen?"10px 15px":"0px 15px",
          marginTop: "35px",
          height: this.state.isOpen?"43px":"0px"
        }} placeholder={Localize(this.props.locale, "type_in_what")} type="text" value={this.state.freeText} onChange={(e)=>this.setState({freeText: e.target.value})} onKeyDown={(e) => {if(e.key === 'Enter'){this.onSearch()}}}/>
        <span className={"background-dark-blue"} onClick={()=>this.onSearch()} style={{
          transition: "all 0.3s ease-in-out",
          marginTop: "42px",
          pointerEvents: this.state.isOpen?"all":"none",
          fontSize: "13px",
          color: "white",
          position: "absolute",
          right: "20px",
          padding: this.state.isOpen?"5px 12px":"0px 12px",
          height: this.state.isOpen?"29px":"0px",
          opacity: this.state.isOpen?0.95:0,
          cursor: "pointer",
          userSelect: "none",
          backgroundColor: "#243680"
        }}>
          {Localize(this.props.locale, "search_cap")}
        </span>
        <span style={{padding: "10px", marginRight: "-20px", cursor: "pointer", pointerEvents: this.state.isOpen?"all":"none", position: "relative", right: "15px"}}  onClick={() => this.Close()}>
          <FontAwesomeIcon icon={faTimes} style={{transform: this.state.isOpen?"rotate(0deg) scale(1)":"rotate(-180deg) scale(0.5)", transition: "all 0.3s ease-in-out", pointerEvents: this.state.isOpen?"all":"none", opacity: this.state.isOpen?1:0}}/>
        </span>
        {/*<div className={styles.searchBg} style={{display: this.state.isOpen?"flex":"none"}}>
          <div className={styles.searchPopup}>
            Search
            <input className={styles.freeText} type="text" value={this.state.freeText} onChange={(e)=>this.setState({freeText: e.target.value})}/>
            <div onClick={() => this.onSearch()} className={styles.submit + " background-dark-blue"}>
              Search
            </div>
          </div>
        </div>*/}
      </div>
    );
  }
}

export default AdvancedSearch
