import React, { Component } from 'react'
import './Home.scss';
import styles from './ContentListPage.scss';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'

import axios from 'axios';

//import EducationalContent from './EducationalContent.js'
import Banner from './Banner';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import AdvancedSearch from './AdvancedSearch';
import EventList from './EventList';
//import RegistrationPopup from '../Profile/RegistrationPopup';
//import LoginPopup from '../Profile/LoginPopup';

import { Localize } from './Localization';
import user_activity from './UserAction.js';
import SpecialtiesTabs from './SpecialtiesTabs';
import SortBy from './SortBy';

class RecentlyVisitedPage extends Component {
  state = {
    data: [],
    search: {
      freeText: ""
    },
    tab: "all_specialties",
    orderBy: ""
  }

  getType(){
    let type = "All";
    if(this.props.type === "video"){
      type = "Case Studies";
    }
    else if(this.props.type === "infographics"){
      type = "Infographics";
    }
    else if(this.props.type === "slide_deck"){
      type = "Slide Decks";
    }
    else if(this.props.type === "poster"){
      type = "Posters";
    }
    else if(this.props.type === "reports"){
      type = "Meeting Reports";
    }
    return type;
  }

  componentDidMount() {
    this.getType = this.getType.bind(this);
    user_activity.Save("Recently Visited - " + this.getType(this.props.type), "Visited");
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.search !== prevState.search || this.state.tab !== prevState.tab || this.state.orderBy !== prevState.orderBy) {
      window.scrollTo(0, 0);
    }
  }

  shouldComponentUpdate(nextProps, nextState){
    if(this.props.type !== nextProps.type || this.props.locale !== nextProps.locale){
      if(this.props.type !== nextProps.type){
        user_activity.Save("Recently Visited - " + this.getType(nextProps.type), "Visited");
      }
      this.clearSearch(); //This will update the component. Doing it this way to prevent double re-renders
      return false;
    }
    return true;
  }

  clearSearch(){
    this.setState({
      search: {
        freeText: ""
      }
    })
  }

  render() {
    return (
      <div style={{backgroundColor: "white"}}>
        <Banner/>
        <div style={{padding: "10px 20px 10px 20px"}} >
        <div style={{display: "flex", flexFlow: "wrap", justifyContent: "right"}}>
              <div style={{flex: "1 1"}}>
                <div style={{display: "inline-block"}}>
                  <Link to={"/recentlyvisited/all"} className={this.props.type==="all"?"primary-color":"secondary-color"}>
                      { Localize(this.props.locale, "all") }
                  </Link>
                  <span style={{marginLeft: "15px", marginRight: "15px"}}>
                    |
                  </span>
                </div>
                <div style={{display: "inline-block"}}>
                  <Link to={"/recentlyvisited/video"} className={this.props.type==="video"?"primary-color":"secondary-color"}>
                    { Localize(this.props.locale, "video") }
                  </Link>
                  <span style={{marginLeft: "15px", marginRight: "15px"}}>
                    |
                  </span>
                  <Link to={"/recentlyvisited/infographics"} className={this.props.type==="infographics"?"primary-color":"secondary-color"}>
                    { Localize(this.props.locale, "infographics") }
                  </Link>
                  <span style={{marginLeft: "15px", marginRight: "15px"}}>
                    |
                  </span>
                </div>
                <div style={{display: "inline-block"}}>
                  <Link to={"/recentlyvisited/reports"} className={this.props.type==="reports"?"primary-color":"secondary-color"}>
                    { Localize(this.props.locale, "reports") }
                  </Link>
                  <span style={{marginLeft: "15px", marginRight: "15px"}}>
                    |
                  </span>
                  <Link to={"/recentlyvisited/poster"} className={this.props.type==="poster"?"primary-color":"secondary-color"}>
                    { Localize(this.props.locale, "poster") }
                  </Link>
                  <span style={{marginLeft: "15px", marginRight: "15px"}}>
                    |
                  </span>
                </div>
                <div style={{display: "inline-block"}}>
                  <Link to={"/recentlyvisited/slide_deck"} className={this.props.type==="slide_deck"?"primary-color":"secondary-color"}>
                    { Localize(this.props.locale, "slide_deck") }
                  </Link>
                </div>
              </div>
            </div>
            <div className={styles.search} style={{display: 'flex', justifyContent: "right"}}>
              <div className={styles.advancedSearch} style={{display: 'inline-block'}}>
                <AdvancedSearch 
                  onSearch={(search) => {
                      this.setState({search: search});
                      if(search.freeText)
                        user_activity.Save("Recently Visited - " + this.getType(this.props.type), "Searched for: " + search.freeText);
                    }
                  }
                  type={this.props.type} locale={this.props.locale}/>
              </div>
            </div>
        </div>
        <div style={{margin: "3px 15px 0px 15px"}} className="dark-blue-color">
          </div>
        <EventList is_from_detail={false} search={this.state.search} tab={this.state.tab==="all_specialties"?"":this.state.tab} orderBy={this.state.orderBy} type={this.props.type} locale={this.props.locale} user={this.props.user} page_type={"recentlyvisited"} extra_text="RECENTLY VISITED"/>
      </div>
    )
  }
}

export default RecentlyVisitedPage

/*<SpecialtiesTabs locale={this.props.locale} tab={this.state.tab} SetTab={(tab) => this.setState({tab: tab})} />
  style={{opacity: this.props.type==="ondemand"?1:0, pointerEvents: this.props.type==="ondemand"?"all":"none"}}  
  
  line 86 92 95 98 101 <Link to={"/" + this.props.locale + "/all"} 

  line 96  <Link to={"/contentlist/all"} className={this.props.type==="all"?"primary-color":"secondary-color"} style={{marginRight: "15px"}}>
                { Localize(this.props.locale, "all") }
            </Link>|
*/