import React, { Component } from 'react'
import './TextInput.scss';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

class TextInput extends Component {
  state = {
    value: "",
    hasError: true
  }

  componentDidMount() {
    let error = true;
    if(this.props.options["required"] == 0){ //Setting initial error value
      error = false;
    }
    this.props.onErrorChange(this.props.name, error);
    this.setState({hasError: error});
  }

  handleChange(event) {
    let value = event.target.value;
    this.setState({value: value});
    this.props.onChange(this.props.name, value);

    let error = false;

    if(value == ""){
      if(this.props.options["required"]==1){
        error = true;
      }
    }
    else{
      if(value.length < this.props.options["min"]){
        error = true;
      }
      else if(value.length > this.props.options["max"]){
        error = true;
      }
    }

    this.props.onErrorChange(this.props.name, error);
    this.setState({hasError: error});
  }

  render() {
    return (
      <div className={"col-sm-12 col-md-6 col-xl-"+this.props.options["size"]} style={{marginBottom: "20px"}}>
        <div style={{fontSize: "14px"}}>
          { this.props.name }{ this.props.options["required"]==1?"*":"" }
        </div>
        <input className={"textInput"} type="text" value={this.state.value} onChange={this.handleChange.bind(this)} style={{width: "100%", border: this.props.showRed&&this.state.hasError?"1px solid red":""}}/>
      </div>
    )
  }
}

export default TextInput
