import React, { Component } from 'react'
import styles from './Share.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShare, faSave, faHeart, faHeartBroken, faHeartbeat } from '@fortawesome/free-solid-svg-icons'

import {
    FacebookShareButton,
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
    LinkedinShareButton,
    TwitterShareButton,
    PinterestShareButton,
    VKShareButton,
    OKShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    RedditShareButton,
    EmailShareButton,
    TumblrShareButton,
    LivejournalShareButton,
    MailruShareButton,
    ViberShareButton,
    WorkplaceShareButton,
    LineShareButton,
    WeiboShareButton,
    PocketShareButton,
    InstapaperShareButton,
    HatenaShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    PinterestIcon,
    VKIcon,
    OKIcon,
    TelegramIcon,
    WhatsappIcon,
    RedditIcon,
    TumblrIcon,
    MailruIcon,
    EmailIcon,
    LivejournalIcon,
    ViberIcon,
    WorkplaceIcon,
    LineIcon,
    PocketIcon,
    InstapaperIcon,
    WeiboIcon,
    HatenaIcon,
  } from "react-share";
//import user_activity from './UserActivity';


class Share extends Component {
  state = {
    isOpen: false
  }

  componentDidMount() {
    
  }

  Open(){
    //user_activity.SaveAction("Shared " + (this.props.type==="live"?"Live":"On Demand"), this.props.title);
    this.setState({isOpen: true});
    this.props.onOpen();
  }

  render() {
    const shareUrl = window.location.href;//this.props.shareUrl;
    const title = this.props.title;
    return (
        <span>
            <span style={{cursor: "pointer"}} onClick={() => this.Open()}><FontAwesomeIcon icon={faShare} /></span>
            <div className={styles.shareBg} style={{display: this.state.isOpen?"flex":"none"}}>
                <div className={styles.sharePopup}>
                    <span style={{cursor: "pointer"}} onClick={() => this.setState({isOpen: false})}>X </span>Share
                    <div className={styles.networkContainer}>
                        <div className={styles.network}>
                        <FacebookShareButton
                            url={shareUrl}
                            quote={title}
                            className="Demo__some-network__share-button"
                        >
                            <FacebookIcon size={32} round />
                        </FacebookShareButton>

                        </div>

                        <div className={styles.network}>
                        <FacebookMessengerShareButton
                            url={shareUrl}
                            appId="521270401588372"
                            className="Demo__some-network__share-button"
                        >
                            <FacebookMessengerIcon size={32} round />
                        </FacebookMessengerShareButton>
                        </div>

                        {/*
                        <div className={styles.network}>
                        <TwitterShareButton
                            url={shareUrl}
                            title={title}
                            className="Demo__some-network__share-button"
                        >
                            <TwitterIcon size={32} round />
                        </TwitterShareButton>

                        </div>
                        */}
                        <div className={styles.network}>
                        <EmailShareButton
                            url={shareUrl}
                            subject={title}
                            body="body"
                            className="Demo__some-network__share-button"
                        >
                            <EmailIcon size={32} round />
                        </EmailShareButton>
                        </div>
                        {/*
                        <div className={styles.network}>
                        <TelegramShareButton
                            url={shareUrl}
                            title={title}
                            className="Demo__some-network__share-button"
                        >
                            <TelegramIcon size={32} round />
                        </TelegramShareButton>

                        </div>
                        */}
                        <div className={styles.network}>
                        <WhatsappShareButton
                            url={shareUrl}
                            title={title}
                            separator=":: "
                            className="Demo__some-network__share-button"
                        >
                            <WhatsappIcon size={32} round />
                        </WhatsappShareButton>

                        </div>

                        <div className={styles.network}>
                        <LinkedinShareButton url={shareUrl} className="Demo__some-network__share-button">
                            <LinkedinIcon size={32} round />
                        </LinkedinShareButton>
                        </div>
                        {/*
                        <div className={styles.network}>
                        <PinterestShareButton
                            url={String(window.location)}
                            media={`${String(window.location)}/${exampleImage}`}
                            className="Demo__some-network__share-button"
                        >
                            <PinterestIcon size={32} round />
                        </PinterestShareButton>
                        </div>

                        <div className={styles.network}>
                        <VKShareButton
                            url={shareUrl}
                            image={`${String(window.location)}/${exampleImage}`}
                            className="Demo__some-network__share-button"
                        >
                            <VKIcon size={32} round />
                        </VKShareButton>

                        </div>

                        <div className={styles.network}>
                        <OKShareButton
                            url={shareUrl}
                            image={`${String(window.location)}/${exampleImage}`}
                            className="Demo__some-network__share-button"
                        >
                            <OKIcon size={32} round />
                        </OKShareButton>
                        </div>

                        <div className={styles.network}>
                        <RedditShareButton
                            url={shareUrl}
                            title={title}
                            windowWidth={660}
                            windowHeight={460}
                            className="Demo__some-network__share-button"
                        >
                            <RedditIcon size={32} round />
                        </RedditShareButton>
                        </div>

                        <div className={styles.network}>
                        <TumblrShareButton
                            url={shareUrl}
                            title={title}
                            className="Demo__some-network__share-button"
                        >
                            <TumblrIcon size={32} round />
                        </TumblrShareButton>
                        </div>

                        <div className={styles.network}>
                        <LivejournalShareButton
                            url={shareUrl}
                            title={title}
                            description={shareUrl}
                            className="Demo__some-network__share-button"
                        >
                            <LivejournalIcon size={32} round />
                        </LivejournalShareButton>
                        </div>

                        <div className={styles.network}>
                        <MailruShareButton
                            url={shareUrl}
                            title={title}
                            className="Demo__some-network__share-button"
                        >
                            <MailruIcon size={32} round />
                        </MailruShareButton>
                        </div>
                        */}
                        <div className={styles.network}>
                        <ViberShareButton
                            url={shareUrl}
                            title={title}
                            className="Demo__some-network__share-button"
                        >
                            <ViberIcon size={32} round />
                        </ViberShareButton>
                        </div>
                        {/*
                        <div className={styles.network}>
                        <WorkplaceShareButton
                            url={shareUrl}
                            quote={title}
                            className="Demo__some-network__share-button"
                        >
                            <WorkplaceIcon size={32} round />
                        </WorkplaceShareButton>
                        </div>

                        <div className={styles.network}>
                        <LineShareButton
                            url={shareUrl}
                            title={title}
                            className="Demo__some-network__share-button"
                        >
                            <LineIcon size={32} round />
                        </LineShareButton>
                        </div>

                        <div className={styles.network}>
                        <WeiboShareButton
                            url={shareUrl}
                            title={title}
                            image={`${String(window.location)}/${exampleImage}`}
                            className="Demo__some-network__share-button"
                        >
                            <WeiboIcon size={32} round />
                        </WeiboShareButton>
                        </div>

                        <div className={styles.network}>
                        <PocketShareButton
                            url={shareUrl}
                            title={title}
                            className="Demo__some-network__share-button"
                        >
                            <PocketIcon size={32} round />
                        </PocketShareButton>
                        </div>

                        <div className={styles.network}>
                        <InstapaperShareButton
                            url={shareUrl}
                            title={title}
                            className="Demo__some-network__share-button"
                        >
                            <InstapaperIcon size={32} round />
                        </InstapaperShareButton>
                        </div>

                        <div className={styles.network}>
                        <HatenaShareButton
                            url={shareUrl}
                            title={title}
                            windowWidth={660}
                            windowHeight={460}
                            className="Demo__some-network__share-button"
                        >
                            <HatenaIcon size={32} round />
                        </HatenaShareButton>
                        
                        </div>
                        */}
                    </div>
                    <div>
                        <input type="text" value={shareUrl} className={styles.urlInput}/>
                    </div>
                </div>
            </div>
        </span>
    )
  }
}

export default Share
