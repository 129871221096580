import React, { Component } from 'react'

import Skeleton from 'react-loading-skeleton';

class EventSkeleton extends Component {

  render() {
    return (
        <div className={"border-all border-2 border-secondary"} style={{display: "flex", flexFlow: "column", backgroundColor: this.props.isCurrentEvent?"#f0f4f7":"white", margin: "10px", width: "300px", minHeight: "300px", borderRadius: "10px", padding: "10px", marginBottom: "20px", position: "relative"}}>
            <div style={{flex: "0 0 250px"}}>
                <div style={{borderRadius: "10px"}}>
                    <Skeleton height={155} />
                </div>
                <div style={{width: "80px", marginTop: "5px", fontSize: "12px", float: "right"}}>
                    <Skeleton />
                </div>
                <div style={{marginTop: "20px", fontWeight: "bold", fontSize: "15px", lineHeight: "23px", width: "100%"}}>
                    <Skeleton count={2}/>
                </div>
            </div>
            <div style={{flex: "1 1 auto", position: "relative"}}>
                <div style={{width: "50%"}}>
                    <Skeleton count={3} />
                </div>
            </div>
        </div>
    )
  }
}

export default EventSkeleton
