import React, { Component } from 'react'
import styles from './EventList.module.scss';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'

import axios from './myAxios';
import Event from './Event';

import { Localize } from './Localization';

import Skeleton from 'react-loading-skeleton';
import EventSkeleton from './EventSkeleton';

let page = 0;

class EventList extends Component {
  state = {
    data: [],
    now: new Date(),
    isLoading: true,
    length: 20
  }

  componentDidMount() {
    this.setState({data: [], isLoading: true});
    this.getConferences();
    setInterval(() => {
      this.setState({now: new Date()});
    }, 5000);
  }

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type || this.props.search !== prevProps.search || this.props.tab !== prevProps.tab || this.props.orderBy !== prevProps.orderBy || this.props.locale !== prevProps.locale || this.props.current_event !== prevProps.current_event) {
      page = 0;
      this.getConferences();
    }
  }

  getConferences(){
    this.setState({data: [], isLoading: true});
    let params = new URLSearchParams();
    params.append('displayData', this.props.type);
    params.append('isLive', 1);
    params.append('locale', this.props.locale);
    params.append('q', this.props.search?(this.props.search.freeText||""):"");
    params.append('filter', this.props.tab||"");
    params.append('no_preferences', 1);
    params.append('page', page);
    params.append('page_type', this.props.page_type??"");
    params.append('current_event', this.props.current_event??"");
    params.append('user_id', this.props.user.user_id);
    if(this.props.orderBy){
      params.append('orderBy', this.props.orderBy);
    }
    axios.post(`/admin/api/getContents.php`, params)
    // axios.post(`/admin/api/getContents.php`, params, { withCredentials: true })
      .then(res => {
        console.log(res.data);
        this.setState({data: res.data, isLoading: false, length: res.data.length});
      });
  }

  viewMore(){
    page++;
    this.setState({isLoading: true});
    let params = new URLSearchParams();
    params.append('displayData', this.props.type);
    params.append('isLive', 1);
    params.append('locale', this.props.locale);
    params.append('q', this.props.search?(this.props.search.freeText||""):"");
    params.append('filter', this.props.tab||"");
    params.append('no_preferences', 1);
    params.append('page', page);
    params.append('page_type', this.props.page_type??"");
    params.append('current_event', this.props.current_event??"");
    params.append('user_id', this.props.user.user_id);
    axios.post(`/admin/api/getContents.php`, params)
    // axios.post(`/admin/api/getContents.php`, params, { withCredentials: true })
      .then(res => {
        console.log(res.data);
        this.setState({data: this.state.data.concat(res.data), isLoading: false, length: res.data.length});
      });
  }

  /*dateFromString(str) {
    var year = str.split(" ")[0].split("-")[0];
    var month = str.split(" ")[0].split("-")[1];
    var day = str.split(" ")[0].split("-")[2];

    var hours = str.split(" ")[1].split(":")[0];
    var minutes = str.split(" ")[1].split(":")[1];
    var seconds = str.split(" ")[1].split(":")[2];

    return new Date(Date.UTC(year, month-1, day, hours, minutes, seconds, 0));
  }*/

  formatDate(date){
    const months = Localize(this.props.locale, "months");
    const days = Localize(this.props.locale, "days");
    return  days[date.getDay()] + " " + months[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear();
  }

  renderAll(allEvents, hasSkeleton){
    let type = this.props.type;
    let text;
    switch(type){
      case "poster":
        text = "Find and access posters presented at conferences on combatting stigma.";
        break;
      case "slide_deck":
        text = "Access slide decks, presentations and other useful resources that will support you to run effective local anti-stigma activities.";
        break;
      case "reports":
        text = "Read our meeting reports summarising key insights and learnings from previous anti-stigma events.";
        break;
      case "video":
        text = "Access and learn from real-world case studies shared by members of the anti-stigma community. Each case provides key insights into the extent of stigma in the respective countries and offers practical solutions for implementation of similar programmes in other regions.";
        break;
      case "infographics":
        text = "Review the latest infographics developed from current research on stigma and discrimination in the HIV community and healthcare settings.";
        break;
      default:
        text = "";
    }
    if(allEvents.length > 0){
      return (
        <div>
          <div className={"tertiary-color " + styles.eventList}>
            <div className={styles.label}>
              <div className={ this.props.is_from_detail?"primary-color":"primary-color" } style={{ fontWeight: this.props.is_from_detail?"bold":"normal"}}>
                {this.props.extra_text?(this.props.extra_text + " - "):""}{ this.props.is_from_detail?"SIMILAR CONTENT":Localize(this.props.locale, type) }
                <br/>
              </div>
              {text}
            </div>
            {
              allEvents.map((data, idx) => {
                return <Event key={idx} now={this.state.now} search={this.props.search} tab={this.props.tab} locale={this.props.locale} data={data} isToday={true} isCurrentEvent={data.id == this.props.current_event} isOnDemand={false}/>;
              })
            }
            {
              hasSkeleton && this.state.isLoading && <EventSkeleton/>
            }
          </div>
        </div>
      )
    }
    else{
      return (<></>);
    }
  }

  render() {
    let allEvents = [];
    let videoEvents = [];
    let infographicEvents = [];
    let slideDeckEvents = [];
    let posterEvents = [];
    let reportEvents = [];

    this.state.data.forEach(event => {
      //Push event to the appropriate array according to the type (which category the user clicked) 
      //and the category of the confenrence (category_b)
      if(this.props.type === "all"){
        allEvents.push(event);
      } else if(this.props.type === "video"){
        if(event.category_b.toLowerCase() === Localize(this.props.locale, "video").toLowerCase()){
          videoEvents.push(event);
        }
      }else if(this.props.type === "infographics"){
        if(event.category_b.toLowerCase() === Localize(this.props.locale, "infographics").toLowerCase()){
          infographicEvents.push(event);
        }
      }else if(this.props.type === "slide_deck"){
        if(event.category_b.toLowerCase() === Localize(this.props.locale, "slide_deck").toLowerCase()){
          slideDeckEvents.push(event);
        }
      }else if(this.props.type === "poster"){
        if(event.category_b.toLowerCase() === Localize(this.props.locale, "poster").toLowerCase()){
          posterEvents.push(event);
        }
      }else if(this.props.type === "reports"){
        if(event.category_b.toLowerCase() === Localize(this.props.locale, "reports").toLowerCase()){
          reportEvents.push(event);
        }
      }
    });

    if(allEvents.length === 0 && videoEvents.length === 0 && infographicEvents.length === 0 && slideDeckEvents.length === 0 && posterEvents.length === 0 && reportEvents.length === 0 && !this.state.isLoading){
      return (
        <div style={{width: "100%", textAlign: "center", fontWeight: "bold", fontSize: "24px", marginTop: "30px"}}>
          { this.props.is_from_detail?"":"0 Results Found" }
        </div>
      )
    }
    return (
      <div>
        <hr style={{marginBottom: "2rem"}}/>
        { /*this.props.extra_text && <div>
          <div className={"tertiary-color " + styles.eventList} style={{justifyContent: allEvents.length>2?"left":"left"}}>
            <div className={styles.label}>
              <div className={ this.props.is_from_detail?"tertiary-color":"primary-color" } style={{ fontWeight: this.props.is_from_detail?"bold":"normal"}}>
                {this.props.extra_text}
              </div>
            </div>
          </div>
        </div> */}

        { this.renderAll(allEvents, true) }
        { this.renderAll(videoEvents, true) }
        { this.renderAll(infographicEvents, true) }
        { this.renderAll(slideDeckEvents, true) }
        { this.renderAll(posterEvents, true) }
        { this.renderAll(reportEvents, true) }
        <div style={{textAlign: "center", display: (!this.state.isLoading && this.state.length === 20)?"block":"none"}}>
          <div className={styles.viewMore} onClick={() => this.viewMore()}>
            View More
          </div>
        </div>
      </div>
    )
  }
}

export default EventList

