import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import axios from './myAxios';

function IsLoggedIn({ SetUser, history }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
        axios.get(`/admin/api/isLoggedIn.php`)
        .then(res => {
            SetUser(res.data);
        });
    });
    return () => {
      unlisten();
    }
  }, []);

  return (null);
}

export default withRouter(IsLoggedIn);