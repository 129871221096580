import React, { Component } from 'react'
import './Registration.scss';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

import axios from './myAxios';

import TextInput from './RegistrationInput/TextInput.js';
import OptionInput from './RegistrationInput/OptionInput.js';
import CountryInput from './RegistrationInput/CountryInput.js';
import TelephoneInput from './RegistrationInput/TelephoneInput.js';
import EmailInput from './RegistrationInput/EmailInput.js';
import Popup from './Popup.js';
import LoginModal from './Modals/LoginModal.js';
import HomeHeader from './HomeHeader.js';

import UserAction from './UserAction';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import Banner from './Banner';

class LandingPage extends Component {
  state = {
    fields: {},
    countries: {},
    modalVisible: false,
    modalTitle: "",
    modalText: "",
    hasError: {},
    showRed: false,
    modalLoginVisible: false,
    checked1: true,
    checked2: true
  }

  componentDidMount() {
    UserAction.Save("Landing Page", "Visited");
  } 

  render() {
    return (
      <div className="background">
          <div className="background" style={{position: "fixed", zIndex: -1, left: "0px", top: "0px", right: "0px", bottom: "0px"}}></div>
        <Popup onClose={() => this.setState({ modalVisible: false })} visible={this.state.modalVisible} title={this.state.modalTitle} text={this.state.modalText}/>
        <LoginModal onClose={() => this.setState({ modalLoginVisible: false })} visible={this.state.modalLoginVisible}/>
        
        <Banner/>
        
        <div className="my-container">
            <div className="row">
                <div className="text" style={{color: "white"}}>
                    <div><b>Anti-Stigma</b> is a new platform for healthcare professionals, patient advisory groups, and non-governmental
                    organisations to share and facilitate best practice to combat stigma in healthcare settings and the community.</div>
                    <div style={{marginTop: "8px"}}>Here you will find scientific, evidence-based, non-promotional resources and real-world examples that offer
                    practical insights and increase awareness of anti-stigma initiatives. Bookmark this page and visit it regularly to
                    avoid missing important updates on anti-stigma events and resources.</div>
                    <div style={{marginTop: "8px"}}>To access the platform, please register.</div>
                </div>
              <div className="row col-sm-12">
                  <Link to={"/register"} className={"background-primary border-all border-1 border-primary"} style={{textDecoration: 'none', color: 'white', marginTop: '50px', cursor: 'pointer', width: '170px', borderRadius: '4px', padding: '4px 50px', fontFamily: 'Planer', fontWeight: 'bold', fontSize: '20px'}}>
                    Register
                  </Link>
              </div>
              <Link to={"/login"} className="row col-sm-12" style={{textDecoration: 'none', marginTop: "5px"}}>
                <div style={{color: 'white', width: '140px', fontFamily: 'Planer', fontSize: '16px', display:'inline-block'}}>
                  Already Registered?
                </div>
                <div style={{color: '#E86651', cursor: 'pointer', borderRadius: '10px', fontFamily: 'Planer', fontSize: '16px', display:'inline-block'}}>
                  Login
                </div>
              </Link>
            </div>
        </div>
      </div>
    )
  }
}

export default LandingPage

