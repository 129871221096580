import React, { Component } from 'react'
import './ContentPage.scss';

import logo from "./images/exceed.svg";

import { findDOMNode } from 'react-dom'

import ContentInfo from './ContentInfo.js'
import Header from './Header.js'

import axios from './myAxios';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  withRouter
} from "react-router-dom";
import Banner from './Banner';

class VerifyEmailPage extends Component {
  state = {
    status: -1,
    email: ""
  }

  componentDidMount() {
    let values = {};
    values["token"] = this.props.match.params.token;
    axios.post(`/admin/api/verifyEmail.php`, values)
      .then(res => {
        this.setState({email: res.data["email"], status: res.data["status"]});
        setTimeout(() => {
          this.props.SetUser(res.data.user);
          this.props.history.push('/myprofile');
        },3000);
      });
  }

  render() {
    return (
      <>
        <div className="background">
          <div className="background" style={{position: "fixed", zIndex: -1, left: "0px", top: "0px", right: "0px", bottom: "0px"}}></div>
          <Banner/>
          <div className="my-container" style={{marginTop: "20px"}}>
            <div className="row" style={{marginTop: "30px", color: "white"}}>
              <div className="col-sm-12" style={{fontSize: '18px', display: (this.state.status==1||this.state.status==2)?"block":"none"}}>
                <p>
                  Thank you for registering for the <b>Anti-Stigma Portal.</b>
                </p>
                {/* <p>
                  Please use the following credentials, to <Link to={"/login"}>login</Link> to the Anti-Stigma Portal:<br/>
                  User ID: {this.state.email}<br/>
                  Password: synapse
                </p> */}
                <p>
                  If you have any question or problem, please contact <a href='mailto:support@mdata.gr'>support</a>.
                </p>
                <p>
                  Anti-Stigma Portal Team
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(VerifyEmailPage)
