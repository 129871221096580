import React from 'react';
import { Label, Input, FormGroup, Col, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import styles from '../MyInputs/MyInput.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { colors } from '../../../../styles/_colors';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const datePicker = ( props ) => {
    console.log(props)
    
    return (
        <FormGroup row className="m-0 mb-4 align-items-center">
            <Label xs="12" sm="4" md="3" lg="2" for={props.formElem.id} className={styles["label"] + " secondary-color"}>{props.formElem.name}</Label>
            <Col xs="12" sm="8" className="p-0 pl-1">
                <InputGroup>
                    <DatePicker
                        selected={props.formElem.value}
                        onChange={date => props.onChangeHandler(date, props.formElem.id)}
                        dateFormat="dd/MM/yyyy"
                        // isClearable
                        placeholderText={props.formElem.placeholder}
                        className={styles["input-date"] + " pl-2 " + (props.formElem.validity ? "border-secondary-color" : styles["input-invalid"])} 
                    />
                    <InputGroupAddon addonType="append">
                        <InputGroupText className={styles["input-append-icon"] + " " + (props.formElem.validity ? "border-secondary-color" : styles["input-invalid"])}>
                            <FontAwesomeIcon icon={faCalendarAlt} style={{color: colors.editIcon}}/>
                        </InputGroupText>
                    </InputGroupAddon>
                </InputGroup>
            </Col>
            {/* <Col sm={props.feedbackWidth} className="pl-4 font-weight-bold text-danger font-italic">
                { props.validity === "is-invalid" ? props.feedback : null }
            </Col> */}
        </FormGroup>
    );

};

// const arePropsEqual = (prevProps, nextProps) =>  prevProps.formElem.value === nextProps.formElem.value; 
// export default React.memo(datePicker, arePropsEqual);
export default React.memo(datePicker);