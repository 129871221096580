import axios from './axiosConfig';

export const contentAPI = {
    getContents,
    deleteContents
};

function getContents() {
    return axios.get('/admin/api/getContents.php')
        .then( response =>  response ? response.data : null)
        .catch( err => console.log(err));
}

function deleteContents(data) {
    console.log("service", data)
    return axios.delete('/admin/api/deleteContent.php', { data })
        .then( response =>  response ? response.data : null)
        .catch( err => console.log(err));
}



