import React, { Component } from 'react'
import './Home.scss';
import './Organizations.scss';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'

import axios from './myAxios';

//import EducationalContent from './EducationalContent.js'
import Banner from './Banner';
import user_activity from './UserAction.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'

import { UncontrolledDropdown, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ConfirmPopup from './ConfirmPopup';

class Organizations extends Component {
  state = {
    organizations: [],
    modalVisible: false,
    modalTitle: "",
    modalText: ""
  }

  componentDidMount() {
    user_activity.Save("Organizations", "Visited");
    axios.get(`/admin/api/getOrganizations.php`)
    .then(res => {
      this.setState({organizations: res.data});
      console.log(res.data);
    });
  }

    LearnMore = (url) => {
        this.setState({
            modalTitle: "Warning",
            modalText: "Please note, you are now leaving<br>a Gilead-owned site.",
            modalVisible: true,
            modalUrl: url
        })
    }

    OrganizationItem = ({ item }) => (
        <div style={{display: "flex", flexFlow: "wrap", columnGap: "25px", padding: "45px 50px", borderBottom: "1px solid lightgray"}}>
            {/* <div className="organizationImage" style={{height: "132px", marginTop: "8px"}}>
                <img style={{width: "200px", height: "100%", objectFit: "contain"}} src={axios.defaults.baseURL + "/admin/organizations_photos/" + item.img} alt="logo"/>
            </div> */}
            <div style={{flex: "1 1"}}>
                <div style={{fontSize: "20px", fontWeight: "bold", marginBottom: "10px"}}>
                    {item.name}
                </div>
                <div className="tertiary-color" style={{marginBottom: "10px"}}>
                    {item.description}
                </div>
                {item.url && <div onClick={() => this.LearnMore(item.url)} className="organizations_contact" style={{padding: "5px 20px", backgroundColor: "#e04f40", color: "white", borderRadius: "5px", display: "inline-block", fontWeight: "bold", cursor: "pointer", userSelect: "none"}}>
                    LEARN MORE
                </div>}
                {/* <UncontrolledDropdown>
                    <DropdownToggle tag="div">
                        <div className="organizations_contact" style={{padding: "5px 20px", backgroundColor: "#e04f40", color: "white", borderRadius: "5px", display: "inline-block", fontWeight: "bold", cursor: "pointer", userSelect: "none"}}>
                            CONTACT
                        </div>
                    </DropdownToggle>
    
                    <DropdownMenu>
                        <DropdownItem href={"mailto:"+item.email}><FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon> {item.email}</DropdownItem>
                        <DropdownItem href={"tel:"+item.telephone}><FontAwesomeIcon icon={faPhone} style={{transform: "rotate(90deg)"}}></FontAwesomeIcon> {item.telephone}</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown> */}
            </div>
        </div>
    );

  render() {
    return (
        <div className="" style={{backgroundColor: "white"}}>
            <Banner/>
            <ConfirmPopup onClose={() => this.setState({ modalVisible: false })} url={this.state.modalUrl} visible={this.state.modalVisible} title={this.state.modalTitle} text={this.state.modalText}/>
            <div className='title primary-color' style={{fontSize: "22px", fontWeight: "bold", marginLeft:"50px", marginTop: "20px"}}>
                Organisations
            </div>
            <div className='tertiary-color' style={{margin:"20px 50px"}}>
                <div style={{marginBottom: "7px", lineHeight: "1rem"}}>
                    Learn more about and connect with institutions and organisations involved in fighting stigma.
                </div>
            </div>
            <div style={{display: "flex", flexFlow: "column"}}>
                {
                    this.state.organizations.map(item => {
                        return <this.OrganizationItem item={item}/>;
                    })
                }
            </div>
        </div>
    )
  }
}

export default Organizations