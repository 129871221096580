import React, { Component } from 'react'
import './Home.scss';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

import HomeHeader from './HomeHeader.js';
import Popup from './Popup.js';
import UserAction from './UserAction';
import axios from './myAxios';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

class Home extends Component {
  state = {

  }

  componentDidMount() {
    UserAction.Save("Home", "Visited");
  }

  render() {
    return (
      <div className="body background">

        <HomeHeader login={this.props.match.params.login}/>

        <div className="my-container" style={{marginTop: "20px"}}>
          <div style={{marginBottom: '30px'}}>
            <div className="title-margin secondary-color title" style={{fontWeight: 'bold', fontFamily: 'Planer', lineHeight: '3rem', marginBottom: '1rem'}}>
              CF EXCEED 2021 Educational Portal
            </div>
            <div className="title-margin title-text" style={{}}>
              <span style={{fontWeight: 'bold'}}>
                Welcome
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12" style={{fontSize: '18px'}}>
              <p>
                CF EXCEED 2021 is a 4-hour educational meeting customized for CF
                treating physicians and members of multi-disciplinary teams from the
                Gulf countries and beyond. We aim to facilitate education on a wide
                range of cystic fibrosis management topics, based on scientific and
                clinical elements of lung disease and extra-pulmonary pathologies.
                Furthermore, we focus on topics of special interest, such as transition of
                care, pharmacology, adherence, and COVID-19 challenges.
              </p>
              <p>
                This portal provides the option to experience the lectures and
                subsequent discussions of your choice. Interactive questions between
                the speaker and the audience are part of our live event; the same
                questions are now available during the on-demand access.
              </p>
              <p>
                We hope you find this educational experience fruitful. We are interested
                to hear your comments and suggestions. Your feedback helps us
                improve your experience and design future activities that would be as
                customized as possible to your needs.
              </p>
              <p>
                The CF EXCEED 2021 Educational Portal is exclusively intended for healthcare 
                professionals, who are regional experts. Participation is by invitation only. 
                Please do not share this link.
              </p>
              <p>
                Warm regards,<br/>
                Ilias Kirkinezos<br/>
                Associate Medical Director<br/>
                Vertex Pharmaceuticals
              </p>
            </div>
          </div>
          <div className="row" style={{marginBottom: '35px'}}>
            <div className="col-sm-12">
              <Link to={"/register"} style={{textDecoration: "none"}}>
                <div className={"background-primary border-all border-1 border-primary"} style={{color: 'white', marginTop: '20px', cursor: 'pointer', width: '189px', borderRadius: '10px', padding: '10px 50px', fontFamily: 'Planer', fontWeight: 'bold', fontSize: '20px'}}>
                  REGISTER
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Home
