import React, { Component } from 'react'
import './Header.scss';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'

import QuestionsContainer from './QuestionsContainer.js'

import avatar from './images/default_avatar.jpg'

import axios from './myAxios';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
  withRouter
} from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import HamburgerMenu from 'react-hamburger-menu'
import user_activity from './UserAction.js'

import gileadLogo from './images/gileadLogo.png'

class Header extends Component {
  state = {
    modalLoginVisible: false,
    hamburgerOpen: false,
    gotLoggedIn: false,
    menuVisible: false,
    user: {}
  }

  componentDidMount() {
    
  }

  handleHamburgerClick() {
    if(!this.state.hamburgerOpen){
      this.Open();
    }
    else{
      this.Close();
    }
  }

  Close(){
    setTimeout(() => {
      this.setState({
          hamburgerOpen: false
      });
    },10);
    setTimeout(() => {
      this.setState({
        menuVisible: false
      });
    },510);
  }

  Open(){
    this.setState({
      menuVisible: true
    });
    setTimeout(() => {
      this.setState({
          hamburgerOpen: true,
      });
    },10);
  }

  logout(){
    user_activity.Save("Logout", "Clicked");
    axios.get(`/admin/api/logout.php`)
      .then(res => {
        this.props.SetUser(null);
        this.props.history.push('/login');
      });
  }

  render() {
    let name = "";
    if(this.props.user && this.props.user.first_name && this.props.user.last_name){
      name = this.props.user.first_name + " " + this.props.user.last_name;
    }
    return (
      <div>
        <div className={"header background-primary"}>
          <Link to={"/contentsubmission"} className="secondary-font" style={{textDecoration: "none", color: "white", fontSize: "32px", fontWeight: "bold", whiteSpace: "nowrap"}}>
            SUBMIT CONTENT
          </Link>
          {name && <div className={"header-details"} style={{right: '90px', position: "absolute", top: "12px"}}>
            
            <div style={{color: 'white', fontSize: "16px", paddingLeft: '7px', right: "0px"}}>
            <img style={{marginRight: "3px", borderRadius: "50%", width: "26px", height: "26px", objectFit: "cover", backgroundColor: "#e04f40"}} src={axios.defaults.baseURL + "/admin/profile_photos/" + this.props.user.photo} onError={(e) => e.target.src = avatar } alt="avatar"/> {name}
            </div>
          </div>
          }
          <div className={"menu"} style={{position: "absolute", right: "25px", top: "16px", zIndex: 2, cursor: "pointer"}}>
            <div className="menuDiv" style={{display: this.state.menuVisible?"block":"none", transition: "all 0.5s ease-in-out", position: "absolute", right: this.state.hamburgerOpen?"-25px":"-329px", backgroundColor: "#e14f40", padding: "50px 0px 0px 0px", boxShadow: "0px 0px 5px black", top: "-16px", color: "white"}}>
              {name && <>
                <div style={{padding: "0px 20px"}}>
                  <b><img style={{marginRight: "3px", borderRadius: "50%", width: "26px", height: "26px", objectFit: "cover", backgroundColor: "#e04f40"}} src={axios.defaults.baseURL + "/admin/profile_photos/" + this.props.user.photo} onError={(e) => e.target.src = avatar } alt="avatar"/> {name}</b>
                  <Link to={"/myprofile"} onClick={() => this.Close()} style={{textDecoration: "none", color: "white"}}>
                    <div className={"option"}>My Profile</div>
                  </Link>
                  <div className={"option"} onClick={() => this.logout()}>Logout</div>
                </div>
                <hr style={{color: "white", borderColor: "white"}}/>
              </>
              }
              <div style={{padding: "0px 20px"}}>
                <Link to={"/contentlist/all"} onClick={() => this.Close()} style={{textDecoration: "none", color: "white"}}>
                  Resources
                </Link>
                <Link to={"/favorites/all"} onClick={() => this.Close()} style={{textDecoration: "none", color: "white"}}>
                  <div className={"option"}>Favorites</div>
                </Link>
                <Link to={"/recentlyvisited/all"} onClick={() => this.Close()} style={{textDecoration: "none", color: "white"}}>
                  <div className={"option"}>Recently Visited</div>
                </Link>
                <Link to={"/contentsubmission"} onClick={() => this.Close()} style={{textDecoration: "none", color: "white"}}>
                  <div className={"option"}>Content Submission</div>
                </Link>
              </div>
              <hr style={{color: "white", borderColor: "white"}}/>
              <div style={{padding: "0px 20px"}}>
                <Link to={"/members"} onClick={() => this.Close()} style={{textDecoration: "none", color: "white"}}>
                  <div className={"option"}>Members</div>
                </Link>
              </div>
              <hr style={{color: "white", borderColor: "white"}}/>
              <div style={{padding: "0px 20px"}}>
                <Link to={"/organizations"} onClick={() => this.Close()} style={{textDecoration: "none", color: "white"}}>
                  <div className={"option"}>Organisations</div>
                </Link>
              </div>
              <hr style={{color: "white", borderColor: "white"}}/>
              <div style={{padding: "0px 20px"}}>
                <Link to={"/about"} onClick={() => this.Close()} style={{textDecoration: "none", color: "white"}}>
                  <div className={"option"}>About ANTI-STIGMA</div>
                </Link>
                <Link to={"/contact"} onClick={() => this.Close()} style={{textDecoration: "none", color: "white"}}>
                  <div className={"option"}>Contact</div>
                </Link>
              </div>
              <div style={{padding: "0px 20px", textAlign: "center"}}>
                <img src={gileadLogo} style={{width: "120px", height: "auto", padding: "10px"}}/>
              </div>
            </div>
            <HamburgerMenu
              isOpen={this.state.hamburgerOpen}
              menuClicked={this.handleHamburgerClick.bind(this)}
              width={22}
              height={16}
              strokeWidth={2}
              rotate={0}
              color='white'
              borderRadius={0}
              animationDuration={0.5}
            />
          </div>
          {/*<div className={"mobile"} style={{cursor: "pointer", fontSize: "24px", position: "absolute", right: "40px", top: "50%", transform: "translate(0, -50%)", color: "white"}}>
            <div className={"option"} onClick={() => this.logout()}>
              <FontAwesomeIcon icon={faSignOutAlt}/>
            </div>
            </div>*/}
        </div>
      </div>
    )
  }
}

export default withRouter(Header)

/*
<a onClick={this.userActionMethod} href="/admin/api/logout.php" style={{textDecoration: "none"}}>
            <div className={"desktop"} style={{cursor: "pointer", fontSize: "12px", position: "absolute", right: "40px", top: "50%", transform: "translate(0, -50%)", padding: "7px 18px", color: "white", border: "1px solid white", borderRadius: "5px"}}>
              LOGOUT
            </div>
          </a>

line 66 <div style={{color: 'white', fontSize: "16px", right: '90px', top: '35%'}}>
              Network | 
            </div>
*/
